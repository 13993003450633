<template lang="">
  <div id="retours">
    <Title :titlePage="titlePage" />
    <v-container class="padd_top" fluid>
      <v-row no-gutters>
      <v-col cols="12" lg="12" xl="10">
        <v-toolbar color="#55AACF" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            VOS RETOURS
          </v-col>
          <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0">
            <!-- <v-toolbar-title class="rectangle_blanc"> -->
              <v-text-field
                hide-details
                filled
                dense
                placeholder="Rechercher un retour par client / date / numéro"
                v-model="search"
                v-on:keyup.enter="enterClicked(search)"
                background-color="#fff"
              ></v-text-field>
            <!-- </v-toolbar-title> -->
          </v-col>
        </v-toolbar>
      </v-col>
      </v-row>

      <Popup :dialog="dialogDelete" text="Supprimer ce retour ?" actions="" heightProp="" widthProp="" showClose="false"> 
               <v-card-actions class="mb-10">
                <v-btn class="actions" color="#0B87BA" width="100px"  large dark rounded @click="deleteItemConfirm">Oui</v-btn>
                 <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialogDelete = false">Non</v-btn>
              </v-card-actions> 
      </Popup>
      <v-row align="center">
        <v-col cols="12" lg="12" xl="10">
          <resultList
            @emitDialogRetour="dialogDelete = true, id_retour = $event"
            :header="headerRetours"
            :items="retours"
            :nbResult="displayItem"
            :type="'retours'"
            @sort="sort"
            :totalItems="totalItems"
            :page="page"
            :canModif="canModif"
          />
        </v-col>
        <v-col v-if="nbPage>1" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="nbPage"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>

        </v-col>  
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Popup from "@/components/shared/Popup";
import Title from "@/components/shared/Title";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/user";
import apiRetour from "@/api/retours";

export default {
  components: { Title, ResultList, Popup  },
  data() {
    return {
      titlePage: "Retours en cours",
      search: "",
      column: "date_retour",
      order: "DESC",
      page: 1,
      totalItems: 0,
      displayItem: process.env.VUE_APP_NB_DISPLAY,
      retours: [],
      nbPage: 0,
      canModif:0,
      id_retour: 0,
      dialogDelete: false,

      headerRetours: [
        {
          text: "CLIENT",
          align: "center",
          sortable: false,
          value: "Entreprise.raison_sociale",
        },
        {
          text: "DATE",
          align: "center",

          value: "date_retour",
          name: "date",
        },
        {
          text: "N° RETOUR",
          align: "center",
          sortable: false,
          value: "numero_retour",
        },
        {
          text: "MAIL DU CLIENT",
          align: "center",

          value: "email_client",
          name: "client",
        },
        {
          text: "VALIDATION",
          align: "center",
          sortable: false,
          value: "validation_commercial",
        },
        {
          text: "DATE D'ENVOI AU PLUS TARD",
          align: "center",
          sortable: false,
          value: "date_envoi_limite",
        },
        {
          text: "CODES PRODUITS",
          align: "center",
          sortable: false,
          value: "references",
        },
        {
          text: "TYPE",
          align: "center",
          sortable: false,
          value: "TypeRetour.type_retour",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actionsRetour",
        },
      ],
      usersEntreprise:[]
    };
  },
  watch: {
    search(new_value) {
      console.log(new_value);
      this.getRetours(this.$store.state.AsUser.id_utilisateur, new_value);
    },
  },
  methods: {
    // customSort(){
    //   console.log("items, index, isDescending")
    //     if(this.order=="DESC"){
    //         this.order=="ASC"
    //     }
    //     //  if(this.order=="ASC"){
    //     //     this.order=="DESC"
    //     // }

    //     this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search, this.column,this.order ,this.page);
    // },
    enterClicked(e) {
      this.search = e;
      this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },

    getRetours(id = null, query, column, order, page) {
      console.log(query, "query", id);
      api.getUserRetours(id, query, column, order, page).then((res) => {
        console.log(
          "ceci est la reponse :::::::",
          res.data.retours,
          res.data.retours,
          res.data.retours.totalItems
        );

        this.totalItems = res.data.retours.totalItems;
        // this.page = res.data.retours.currentPage;
        console.log(
          "ceci est la reponse :::::::",
          res.data.retours,
          res.data.retours,
          res.data.retours.totalItems
        );
        res.data.retours.rows.forEach((retour) => {
          retour["client"] = retour.Entreprise.raison_sociale;
        });
        this.retours = res.data.retours.rows;
        this.retours.forEach((retour) => {
          retour["references"] = [];
          retour.RetourAArticles.forEach((res) => {
            res.ArticleRetour.forEach((article) => {
              retour["references"].push(article.reference);
            });
          });

            let usersEntreprise= [];
            retour.Entreprise.Users.forEach(user => {
            usersEntreprise.push(user.id_utilisateur)
          })
          retour.canModif = 0;
          if (
        (this.$store.state.AsUser.id_utilisateur == ""
          ? this.$store.state.user.id_role
          : this.$store.state.AsUser.id_role) == 1  ||
        usersEntreprise.includes(
          this.$store.state.AsUser.id_utilisateur == ""
            ? this.$store.state.user.id_utilisateur
            : this.$store.state.AsUser.id_utilisateur
        )
      ) {
         retour.canModif  = 1;
      }
        });

        this.nbPage = res.data.retours.totalPages;


        








        console.log("ceci est le retour", this.retours, this.totalItems);
      });
    },
    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getRetours(
        this.$store.state.AsUser.id_utilisateur
          ? this.$store.state.AsUser.id_utilisateur
          : "",
        this.search,
        this.column,
        this.order,
        this.page - 1
      );
      // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },
    CanModified() {
      if (
        (this.$store.state.AsUser.id_utilisateur == ""
          ? this.$store.state.user.id_role
          : this.$store.state.AsUser.id_role) == 1 ||
        (this.$store.state.AsUser.id_utilisateur == ""
          ? this.$store.state.user.id_role
          : this.$store.state.AsUser.id_role) == 4 ||
        this.usersEntreprise.includes(
          this.$store.state.AsUser.id_utilisateur == ""
            ? this.$store.state.user.id_utilisateur
            : this.$store.state.AsUser.id_utilisateur
        )
      ) {
        this.canModif = 1;
      }
      console.log(this.canModif, "can modif");
    },
    deleteItemConfirm() {
      apiRetour.deleteRetour(this.id_retour).then((res) => {
          console.log(res)
          this.getRetours(
            this.$store.state.AsUser.id_utilisateur
              ? this.$store.state.AsUser.id_utilisateur
              : "",
            this.search
          );
        this.dialogDelete = false

      });
    },
  },
  created() {
    // console.log("created de devis");
    this.getRetours(
      this.$store.state.AsUser.id_utilisateur
        ? this.$store.state.AsUser.id_utilisateur
        : "",
      this.search
    );
    this.CanModified()
  },
};
</script>
<style lang="scss" scoped>
#retours {
  .v-data-table {
    width: 100%;
    margin-top: 3rem;
  }
  th {
    font-size: 1em;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child {
    padding-bottom: 15px;

    > th {
      border-bottom: none;
      border-right: 1px solid grey;
      height: auto !important;
    }
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th:last-child {
    border-bottom: none;
    border-right: 0px solid grey;
  }
  table > thead > tr > th span {
    width: 100%;
    margin: auto;
    padding-right: 1em;
  }
  .v-data-table-header th.active .v-data-table-header__icon,
  .v-data-table-header th .v-data-table-header__icon {
    opacity: 1;
    color: black;
  }
  .v-data-table-header::after {
    height: 1em;
    display: table-row;
    content: "";
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td {
    font-size: 0.9em;
  }

  .v-data-table-header th.text-center:nth-child(2) {
    width: 14vh;
  }

  .padd_top {
    padding-top: 50px;
  }
  ::v-deep .v-toolbar__content {
    // justify-content: space-between;
    padding-right: 0.3rem;
  }

  .font_color {
    color: white;
    font-weight: bold;
  }
}

::v-deep .v-input input::placeholder {
  color: black;
  font-style: italic;
  opacity: 0.8;
}
</style>
