import axios from 'axios';

const RESSOURCE_NAME = 'devis';

export default {

    getDevisParams(numero_devis){
        return axios.get(`${RESSOURCE_NAME}/params/${numero_devis}`)
    },

    saveDevisParams(data){
        return axios.post(`${RESSOURCE_NAME}/save-params`, data)
    }

}