<template lang="">
  <div id="projets">
    <v-col cols="12" lg="12" xl="12">
      <Popup
        :dialog="dialog"
        text="Ce projet est en cours ?"
        actions=""
        heightProp=""
        widthProp=""
        showClose="false"
      >
        <v-card-actions class="mb-10">
          <v-btn
            class="actions mr-10"
            color="#0B87BA"
            width="100px"
            large
            dark
            rounded
            @click="closeDialog"
            >Non</v-btn
          >
          <v-btn
            class="actions"
            color="#0B87BA"
            width="100px"
            large
            dark
            rounded
            @click="SetProjetEncours"
            >Oui</v-btn
          >
        </v-card-actions>
      </Popup>

      <!-- PROJETS ENTREPRISE EN COURS-->
      <v-container class="padd_top" fluid>
        <v-row no-gutters>
          <v-col cols="12" lg="12" xl="12">
            <v-toolbar color="#0B87BA" flat dense>
              <v-col cols="6" lg="6" xl="2" class="pa-0 font_color text-left">
                PROJETS EN COURS
              </v-col>
              <v-col
                cols="6"
                lg="6"
                xl="4"
                offset-xl="6"
                class="pa-0 text-right"
              >
                <!-- <v-toolbar-title class="rectangle_blanc"> -->
                <v-btn
                  v-if="canModif == 1"
                  class="btnBold"
                  color="#FF9900"
                  dark
                  rounded
                  @click="overlay = true"
                >
                  <v-icon left>
                    fa fa-plus
                  </v-icon>
                  <router-link
                    class="font_color"
                    :to="{
                      name: 'create_projet',
                      params: {
                        id_entreprise: this.$route.params.id_entreprise,
                      },
                    }"
                    >AJOUTER UN PROJET</router-link
                  >
                </v-btn>
                <!-- </v-toolbar-title> -->
              </v-col>
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12" lg="12" xl="12">
            <resultList
              :header="headerProjetsActive"
              :items="projets_active"
              :nbResult="10"
              :type="'projets'"
              @sort="sort"
              :totalItems="totalItems"
              :page="page"
              id="encours"
              :canModif="canModif"
            />
          </v-col>
          <v-col v-if="nbPage > 0" cols="12" lg="12" xl="12">
            <div class="text-center">
              <v-pagination
                v-if="nbPage > 1"
                v-model="page"
                :length="nbPage"
                circle
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- HISTORIQUE DES PROJETS -->
      <v-container class="padd_top" fluid>
        <v-row no-gutters>
          <v-col cols="12" lg="12" xl="12">
            <v-toolbar color="#55ABCF" flat dense>
              <v-col cols="6" lg="6" xl="2" class="pa-0 font_color text-left">
                HISTORIQUE DES PROJETS
              </v-col>
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12" lg="12" xl="12">
            <resultList
              :header="headerProjetsClosed"
              :items="projets_closed"
              :nbResult="10"
              :type="'projets'"
              @sort="sortClosed"
              :totalItems="totalItemsClosed"
              :page="pageClosed"
              id="historique"
              :canModif="canModif"
              :dialog="dialog"
              @emitDialog="openDialog($event)"
            />
          </v-col>
          <v-col v-if="nbPageClosed > 0" cols="12" lg="12" xl="10">
            <div class="text-center">
              <v-pagination
                v-if="nbPageClosed > 1"
                v-model="pageClosed"
                :length="nbPageClosed"
                :total-visible="10"
                circle
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </div>
</template>
<script>
import ResultList from "@/components/shared/ResultList";
import Popup from "@/components/shared/Popup";
import api from "@/api/entreprise";
import apiProjet from "@/api/projet";

export default {
  components: { ResultList, Popup },
  data() {
    return {
      id: this.$route.params.id_entreprise,
      search: "",
      column: "date_projet",
      order: "DESC",
      column_closed: "date_projet",
      order_closed: "DESC",
      page: 1,
      pageClosed: 1,
      totalItems: 0,
      totalItemsClosed: 0,
      displayItem: process.env.VUE_APP_NB_DISPLAY,
      projets_active: [],
      projets_closed: [],
      nbPage: 0,
      nbPageClosed: 0,
      usersEntreprise: [],
      canModif: 0,
      dialog: false,
      id_projet: null,
      headerProjetsActive: [
        {
          text: "NOM PROJET",
          align: "center",

          value: "nom_projet",
          name: "nom_projet",
        },
        {
          text: "DATE",
          align: "center",

          value: "date_projet",
          name: "date",
        },
        {
          text: "PERSONNES RENCONTREES",
          align: "center",
          sortable: false,
          value: "personnes",
        },
        {
          text: "DESCRIPTION",
          align: "center",
          sortable: false,
          value: "description",
          name: "client",
        },
        {
          text: "FICHIERS JOINTS",
          align: "center",
          sortable: false,
          value: "documents",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_projet",
        },
      ],
      headerProjetsClosed: [
        {
          text: "NOM PROJET",
          align: "center",

          value: "nom_projet",
          name: "nom_projet",
        },
        {
          text: "DATE",
          align: "center",

          value: "date_projet",
          name: "date",
        },
        {
          text: "PERSONNES RENCONTREES",
          align: "center",
          sortable: false,
          value: "personnes",
        },
        {
          text: "DESCRIPTION",
          align: "center",
          sortable: false,
          value: "description",
          name: "client",
        },
        {
          text: "PROJET OBTENU",
          align: "center",

          value: "projet_obtenu",
          name: "projet_obtenu",
        },
        {
          text: "FICHIERS JOINTS",
          align: "center",
          sortable: false,
          value: "documents",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_projet_closed",
        },
      ],
    };
  },
  methods: {
    getProjetsActive(id, query, column, order, page) {
      // Récupération des projets
      api
        .getEntrepriseProjetsActive(id, query, column, order, page)
        .then((res) => {
          this.totalItems = res.data.projet.totalItems;

          res.data.projet.rows.forEach((projet_active) => {
            projet_active.Personnes.forEach((personne) => {
              if (projet_active["personnes"] == undefined) {
                projet_active["personnes"] =
                  personne.nom + " " + personne.prenom + " ";
              } else {
                projet_active["personnes"] +=
                  personne.nom + " " + personne.prenom;
              }
            });

            projet_active.Documents.forEach((doc) => {
              doc["chemin"] = process.env.VUE_APP_BASE_URL + doc.chemin;
            });
          });

          this.projets_active = res.data.projet.rows;
          this.nbPage = res.data.projet.totalPages;
          if (this.usersEntreprise.length == 0) {
            if (res.data.entreprise.Users.length > 0) {
              res.data.entreprise.Users.forEach((user) => {
                this.usersEntreprise.push(user.id_utilisateur);
              });
            }
            console.log("hhhh");
            this.CanModified();
          }

          console.log("projet_active", this.projets_active, this.totalItems);
          this.$emit("title", res.data.entreprise.raison_sociale);
          this.$store.commit(
            "upadateCodeFtaEntreprise",
            res.data.entreprise.code_client_fta
          );
        })
        .catch((error) => {
          if (400 === error.response.status) {
            this.$emit("title", error.response.data.entreprise.raison_sociale);
            console.log(error.response.data.entreprise, "error.response");
            if (error.response.data.entreprise.Users.length > 0) {
              error.response.data.entreprise.Users.forEach((user) => {
                this.usersEntreprise.push(user.id_utilisateur);
              });
            }
            console.log("hhhh");
            this.CanModified();
            this.$store.commit(
              "upadateCodeFtaEntreprise",
              error.response.data.entreprise.code_client_fta
            );
          }
        });
    },

    getProjetsClosed(id, query, column, order, pageClosed) {
      // Récupération des projets
      api
        .getEntrepriseProjetsClosed(id, query, column, order, pageClosed)
        .then((res) => {
          this.totalItemsClosed = res.data.projet.totalItems;

          res.data.projet.rows.forEach((projet_closed) => {
            projet_closed.Personnes.forEach((personne) => {
              if (projet_closed["personnes"] == undefined) {
                projet_closed["personnes"] =
                  personne.nom + " " + personne.prenom + " ";
              } else {
                projet_closed["personnes"] +=
                  personne.nom + " " + personne.prenom;
              }
            });

            projet_closed.Documents.forEach((doc) => {
              doc["chemin"] = process.env.VUE_APP_BASE_URL + doc.chemin;
            });
          });

          this.projets_closed = res.data.projet.rows;
          this.nbPageClosed = res.data.projet.totalPages;
          console.log(this.usersEntreprise.length > 0, "usersEntreprise");
          if (this.usersEntreprise.length == 0) {
            if (res.data.entreprise.Users.length > 0) {
              res.data.entreprise.Users.forEach((user) => {
                this.usersEntreprise.push(user.id_utilisateur);
              });
            }
            console.log("hhhh");
            this.CanModified();
          }

          console.log(
            "projet_closed",
            this.projets_closed,
            this.totalItemsClosed
          );
          this.$store.commit(
            "upadateCodeFtaEntreprise",
            res.data.entreprise.code_client_fta
          );
        })
        .catch((error) => {
          if (400 === error.response.status) {
            console.log(error.response.data.entreprise, "error.response");
            if (error.response.data.entreprise.Users.length > 0) {
              error.response.data.entreprise.Users.forEach((user) => {
                this.usersEntreprise.push(user.id_utilisateur);
              });
            }
            console.log("hhhh");
            this.projets_closed = [];
            this.CanModified();
            this.$store.commit(
              "upadateCodeFtaEntreprise",
              error.response.data.entreprise.code_client_fta
            );
          }
        });
    },
    CanModified() {
      if (
        (this.$store.state.AsUser.id_utilisateur == ""
          ? this.$store.state.user.id_role
          : this.$store.state.AsUser.id_role) == 1 ||
        this.usersEntreprise.includes(
          this.$store.state.AsUser.id_utilisateur == ""
            ? this.$store.state.user.id_utilisateur
            : this.$store.state.AsUser.id_utilisateur
        )
      ) {
        this.canModif = 1;
      }
      console.log(this.canModif, "can modif");
    },

    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getProjetsActive(
        this.id,
        this.search,
        this.column,
        this.order,
        this.page - 1
      );
    },

    sortClosed($event) {
      console.log("custom sort closed", $event);
      if ($event.column != undefined) {
        this.column_closed = $event.column;
      }
      if ($event.order != undefined) {
        this.order_closed = $event.order ? "ASC" : "DESC";
      }
      this.pageClosed = $event.page;
      this.getProjetsClosed(
        this.id,
        this.search,
        this.column_closed,
        this.order_closed,
        this.pageClosed - 1
      );
    },

    openDialog($event) {
      // console.log( "mon event", $event)
      this.id_projet = $event.id_projet;
      this.dialog = $event.dialog;
    },

    closeDialog() {
      this.dialog = false;
    },

    SetProjetEncours() {
      // Récupération des projets
      apiProjet
        .setProjetEncours(this.id_projet)
        .then((res) => {
          console.log("response", res);
          this.dialog = false;
          this.getProjetsActive(this.id, this.search);
          this.getProjetsClosed(this.id, this.search);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getProjetsActive(this.id, this.search);
    this.getProjetsClosed(this.id, this.search);
  },
};
</script>

<style lang="scss" scoped>
.btnBold {
  font-weight: bold;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}
#encours ::v-deep .v-data-table-header th.text-center:nth-child(4) {
  width: 45vh;
}

#historique ::v-deep .v-data-table-header th.text-center:nth-child(4) {
  width: 35vh;
}

#historique ::v-deep tbody td.text-center:nth-child(4),
#encours ::v-deep tbody td.text-center:nth-child(4) {
  text-align: justify !important;
}

::v-deep .v-data-table-header th.text-center:nth-child(1) {
  width: 45vh;
}

::v-deep .v-data-table-header th.text-center:nth-child(2) {
  width: 15vh;
}

::v-deep .v-data-table-header th.text-center:nth-child(3) {
  width: 15vh;
}

::v-deep .v-data-table-header th.text-center:nth-child(4) {
  width: 15vh;
}

::v-deep .v-data-table-header th.text-center:nth-child(5) {
  width: 40vh;
}

::v-deep .v-data-table-header th.text-center:nth-child(6) {
  width: 18vh;
}

::v-deep .v-data-table-header th.text-center:last-child {
  width: 17vh;
}

@media screen and (max-width: 1560px) {
  ::v-deep .v-data-table-header {
    font-size: 0.7rem;
  }
}
</style>
