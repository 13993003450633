var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',{ref:"dropzone",staticClass:"pa-2",attrs:{"tabindex":"0","width":"100%","height":"300"}},[(_vm.documents.length == 0)?_c('div',{staticClass:"text-left pt-12"},[_vm._v(" Aucune pièce jointe n'a été ajoutée. ")]):_vm._e(),_c('v-row',_vm._l((_vm.documents),function(document,index){return _c('v-col',{key:document.files.lastModified,attrs:{"cols":"2","name":document.files.name}},[_c('p',[_c('a',{class:{ 'mr-1': index < _vm.documents.length - 1 },attrs:{"href":document.chemin,"target":"_blank"}},[(document.files.name.split('.').pop() == 'pdf')?_c('img',{attrs:{"src":require("@/assets/pdf.png"),"alt":""}}):(
                 document.files.name
                   .split('.')
                   .pop()
                   .substring(0, 3) == 'doc' ||
                   document.files.name
                     .split('.')
                     .pop()
                     .substring(0, 3) == 'txt'
               )?_c('img',{attrs:{"src":require("@/assets/doc.png"),"alt":""}}):(
                 document.files.name
                   .split('.')
                   .pop()
                   .substring(0, 3) == 'xls' ||
                   document.files.name
                     .split('.')
                     .pop()
                     .substring(0, 3) == 'csv'
               )?_c('img',{attrs:{"src":require("@/assets/excel.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img.png"),"alt":""}})])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
               var on = ref.on;
               var attrs = ref.attrs;
return [(document.files.name.length > 15)?_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_vm._v(_vm._s(document.files.name.substring(document.files.name.indexOf('-') + 1).substring(0, 15)))]):_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_vm._v(_vm._s(document.files.name.substring(document.files.name.indexOf('-') + 1)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(document.files.name.substring(document.files.name.indexOf('-') + 1)))])]),_c('p',[_vm._v("ajouté le : "+_vm._s(_vm.$moment(document.createdAt).format('DD/MM/YYYY')))]),_c('p',[(_vm.mode !== 'visualisation')?_c('v-btn',{on:{"click":function($event){return _vm.deleteDocument(document, index, true)}}},[_c('strong',[_vm._v("Supprimer")])]):_vm._e()],1)],1)}),1)],1),_c('Popup',{attrs:{"dialog":_vm.dialog,"text":"Supprimer ce document ?","actions":"","heightProp":"","widthProp":"","showClose":"false"},on:{"emitDialog":function($event){_vm.dialog = $event}}},[_c('v-card-actions',{staticClass:"mb-10"},[_c('v-btn',{staticClass:"actions mr-10",attrs:{"color":"#0B87BA","width":"100px","large":"","dark":"","rounded":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Non")]),_c('v-btn',{staticClass:"actions",attrs:{"color":"#0B87BA","width":"100px","large":"","dark":"","rounded":""},on:{"click":function($event){return _vm.deleteDocument(_vm.document, _vm.index, false)}}},[_vm._v("Oui")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }