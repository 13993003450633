import axios from 'axios';

const RESSOURCE_NAME = 'documents';

export default {
    uploadDocument(id, data, config){
        // var personnes = Object.fromEntries(data.personnes)
        return axios.post(`${RESSOURCE_NAME}/upload/`+ id, data , config)
    },

    deleteDocument(id){
        // var personnes = Object.fromEntries(data.personnes)
        return axios.delete(`${RESSOURCE_NAME}/delete/`+ id)
    },
    createDirectory(data){
        return axios.post(`${RESSOURCE_NAME}/directories/create`, data)

    },
    uploadFile(data,id,config){
        return axios.post(`${RESSOURCE_NAME}/upload/entreprise/`+id, data,config)

    },
    download_Documents(id){
        return axios.get(`${RESSOURCE_NAME}/download/`+id,{responseType: 'arraybuffer'})

    },
    deleteDirectory(id,data){
        return axios.post(`${RESSOURCE_NAME}/delete/directory/${id}`,data)
    }
}