<template lang="">
  <div class="d-flex">
    <form @submit.prevent="submit" class="d-flex flex-column flex-grow-1">
 

      <v-container >

      <!-------------------------------- BLOC INFORMATIONS -------------------------------->
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            INFORMATIONS
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10" class="pb-0  mt-10">

        <div class="text-left">NUMERO DE COMMANDE</div>
        <div class="text-left font-weight-bold">{{pret_client.numero_commande}} </div>

        <div class="text-left mt-8">RAISON SOCIALE</div>
        <div class="text-left font-weight-bold">{{pret_client.raison_sociale}} </div>

        <div class="text-left mt-8">DATE DE CREATION</div>
        <div class="text-left font-weight-bold">{{$moment(pret_client.date_pret).format("L")}} </div>

        <div class="text-left mt-8">DATE ESTIMATIVE DE CLOTURE</div>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" light>
          <template v-slot:activator="{ on, attrs }">

              <span v-if="mode !== 'visualisation'"
              class=" calendar-field">
                  <v-text-field 
                      :value="formatDate"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      light
                      outlined
                      hide-details
                      style="width: 30%"
    
                    >
                  <template slot="append-outer">
                    <img class="ml-2" src="@/assets/calendar.png" />
                  </template>
                    </v-text-field>

              </span>
          <div v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{formatDate != '' ? formatDate : "non renseigné"}} </div>

          </template>                                <!-- message d'erreur -->

          <v-date-picker
            v-model="pret_client.date_cloture"
            no-title
            locale="fr-fr"
            @input="menu = false"
          ></v-date-picker>

        </v-menu>
          <div class="errorMsg" v-if="$v.pret_client.date_cloture.$error">
            Renseignez la date de clôture
          </div>

      </v-col> 

      <!-------------------------------- Bloc Personnes rencontrées -------------------------------->
      <AddContacts :personnes="pret_client.personnes" :mode="mode" @emitPersonnes="pret_client.personnes = $event" ref="AddContacts"/>

        <div class="text-left pl-4" v-if="pret_client.personnes.length == 0">
          Au moins une personne doit être rajoutée*
        </div>
      <!-------------------------------- BLOC PERSONNES RENCONTREES -------------------------------->
      <!-------------------------------- BLOC DESCRIPTION -------------------------------->
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            DESCRIPTION
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10"  class="alignement_input ">
       <div class="text-left">DESCRIPTION*
       </div>
        <v-textarea v-if="mode !== 'visualisation'" outlined v-model="pret_client.description" hide-details> </v-textarea>
         <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{pret_client.description != '' ? pret_client.description : "non renseigné"}} </div>
          <!-- message d'erreur -->
          <div class="errorMsg" v-if="$v.pret_client.description.$error && $v.pret_client.description.required == false">
            Renseignez une description pour le prêt.
          </div>

          <div class="errorMsg" v-if="$v.pret_client.description.$error && ($v.pret_client.description.required == true && $v.pret_client.description.minLength == false) ">
            5 caractères au minimum.
          </div>
          <!-- message d'erreur -->
      </v-col>

      <!-------------------------------- BLOC DESCRIPTION -------------------------------->
            <!-------------------------------- BLOC COMMENTAIRE-------------------------------->
      <v-col  v-if="mode !== 'visualisation'"  cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            COMMENTAIRE
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col  v-if="mode !== 'visualisation'"  cols="12" sm="10"  class="alignement_input ">
       <div class="text-left">COMMENTAIRE</div>
        <v-textarea outlined auto-grow rows="1"  v-model="pret_client.commentaire_"> </v-textarea>
      </v-col>

      <!-------------------------------- BLOC COMMENTAIRE-------------------------------->
      <!-------------------------------- BLOC HISTORIQUE DES COMMENTAIRES-------------------------------->
    <div  v-if="(mode == 'modification' || mode == 'visualisation') && pret_client.commentaires.length > 0">
      <v-col  cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            HISTORIQUE DES COMMENTAIRES
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="10"  class="text-left ">
        <div v-for="commentaire in pret_client.commentaires.slice().reverse()" :key="commentaire.createdAt">
          <div class="date-letter text-uppercase ">{{commentaire.User.nom}} - {{ commentaire.createdAt ? $moment(commentaire.createdAt).format("LL") : "" }}</div>
          <div class="font-italic text-h6 " style="overflow-wrap: break-word;">"{{ commentaire.commentaire }}"</div>
        </div>
      </v-col>
    </div>
      <!-------------------------------- BLOC HISTORIQUE DES COMMENTAIRES-------------------------------->
      <AddRappels  :rappels="pret_client.rappels" :mode="mode"  ref="AddRappels" @closeRappel="closeRappel" />
      <div class="text-left pl-3" v-if="pret_client.rappels.length == 0">
        Aucun rappel n'a été ajouté
      </div>

      <!-------------------------------- BLOC STATUT DU PRET-------------------------------->

      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            STATUT DU PRET
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="12" class="pb-0">
            <v-radio-group
              v-if="mode !== 'visualisation'"
              v-model="pret_client.id_statut_pret_client"
              row
            >
            <v-row class="d-flex flex-row mt-3 pl-4">
            <div  v-for="statut in statuts" :key="statut.statuts" class="d-flex flex-row">
              <span  class="text-left mb-2 mr-5">
                <div class="text-left mb-2">{{statut.statut_pret_client}}</div>
                <v-radio
                  :value="statut.id_statut_pret_client"
                ></v-radio>
              </span>
            </div>
            </v-row>
            </v-radio-group>

            <div v-if="mode == 'visualisation'" class="text-left">STATUT</div>
            <div v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{pret_client.StatutPretClient.statut_pret_client != null ? pret_client.StatutPretClient.statut_pret_client : "non renseigné"}} </div>
            <!-- message d'erreur -->
            <div class="errorMsg" v-if="$v.pret_client.id_statut_pret_client.$error && $v.pret_client.id_statut_pret_client.required == false">
            Renseignez le statut du prêt.
            </div>
            <!-- message d'erreur -->
        </v-col>

        <v-col  cols="12" sm="10"  class=" ">

          <div class="text-left">COMMENTAIRE ( en cas d'enlèvement, merci d'indiquer le poids et les dimensions)</div>
          <v-textarea v-if="mode !== 'visualisation'"  outlined auto-grow rows="1"  v-model="pret_client.commentaire_pret"> </v-textarea>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{pret_client.commentaire_pret != "" ? pret_client.commentaire_pret : "non renseigné"}} </div>
        </v-col>

        <!-------------------------------- BLOC BOUTONS-------------------------------->
        <v-col cols="12" sm="10"  class="alignement_input pl-8">

         <router-link v-if="mode != 'visualisation'" :to="-1">
          <v-btn @click="$router.go(-1)" class="btnBold mr-10"  color="black" plain>
            <div>
              Annuler
            </div>
          </v-btn>
          </router-link>

          <v-btn  v-if="mode !== 'visualisation'" class="btnBold"  color="#FF9900" dark rounded type="submit" >
          <v-icon left> fa fa-plus </v-icon>
              <div >
                ENREGISTRER
              </div>
          </v-btn>

          <v-btn  v-if="mode !== 'visualisation'" class="btnBold ml-10"  color="#55aacf" dark rounded type="submit" @click="pret_client.cloture = 'true'" >
            <v-icon left> fa fa-plus </v-icon>
                <div >
                 ENREGISTRER ET CLÔTURER
                </div>
          </v-btn>

          <router-link id="retourBtn" v-if="mode == 'visualisation'" :to="-1">
            <v-btn @click="$router.go(-1)" class="btnBold"  color="#FF9900" dark rounded type="submit" >
              <div>
                RETOUR
              </div>
            </v-btn>
          </router-link>

        </v-col>
        <!-------------------------------- BLOC BOUTONS-------------------------------->


      </v-container>

      <v-col align-self="center" cols="2">
        <v-row justify="end">
          <v-dialog v-model="dialogConfirm" persistent max-width="615">
            <v-card class="card_border">
              <v-card-text class="pt-10  modal">
                <p class="text-modal">Le prêt client a été modifié.</p>
                   <div class="">
                  <v-btn class="btn_choice " rounded text @click="redirectToFicheEntreprise()" >
                    ok
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </form>


  </div>
</template>
<script>
import AddContacts from "@/components/Clients/AddContacts"
import AddRappels from "@/components/Clients/AddRappels"
import { required, minLength} from 'vuelidate/lib/validators'
import moment from "moment";



export default {
props: {
  pret_client: Object,
  statuts: Array,
  utilisateurs: Array,
  mode: [String]
},
  components: {
    AddContacts,
    AddRappels,
  },
  data() {
    return {
      checkIfPersonne: false,
      dialogConfirm: false,
      menu: false,
      dateFormatted: "",
      docs:[]
    };
  },
    validations: {
    pret_client: {
    id_statut_pret_client: {
      required,
    },
    date_cloture: {
      required,
    },
    description: {
      required,
      minLength: minLength(5)
    },
    }  
    },
  computed: {
    formatDate() {
      moment.locale()
      return this.pret_client.date_cloture
        ? moment(this.pret_client.date_cloture).format("L")
        : "";
    },

  },
  methods: {


    closeRappel(rappel) {
      this.$emit("closeRappel",{id_rappel: rappel.id_rappel})
    },


    submit() {
      // Validation du formulaire
      this.$v.$touch();

      console.log(this.$v, "this v")
      if (!this.$v.$invalid && this.pret_client.personnes.length > 0) {

        // Récupération des data des composant AddContacts, AddRappels
        this.pret_client.personnes = this.$refs.AddContacts.personnes
        this.pret_client.rappels = this.$refs.AddRappels.rappels

        // if (this.pret_client.commentaire_ !== ""  ) {
        //     this.pret_client.commentaires.push({commentaire : this.pret_client.commentaire_})
        // }

        console.log(this.pret_client, "submit pret client")
        // Data du pret_client
          
        // var config = {
        //     header : {
        //     'Content-Type' : 'multipart/form-data'
        //   }
        // }

        this.$emit('emitPret', { pret_client: this.pret_client})

        this.dialogConfirm = true

        // });

      }else {
        this.checkIfPersonne = true
      }

      // Scroll sur la première erreur de vuelidate
      if (this.$v.$invalid) {
         this.$nextTick(() => {
          let domRect = document.querySelector('.errorMsg');
          domRect.scrollIntoView(); 
        });
      }
      
    },
    redirectToFicheEntreprise(){
      this.dialogConfirm = false
      this.$router.go(-1)
      // this.$router.push({ path: "/client/"+this.$route.params.id_client });
    },
  },
  mounted() {
  }
};
</script>
<style scoped>
.calendar-field{
      width: 20%;
}

.date-letter {
  font-size: 15px !important
}

.text-modal{
    color: black;
    font-weight: normal;
    font-size: large;
}

.card_border.v-card.v-sheet.theme--light {
	border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width:29px;
  height: 28px
}

.font_color {
  font-weight: bold;
  color: #FFFFFF 
}

.width-50 {
  width: 30%
}

.addPersonne {
  border-style: dashed ;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}




</style>
