<template lang="">
  <div class="d-flex">
    <form @submit.prevent="submit" class="d-flex flex-column flex-grow-1">
 

      <v-container >
        <!-------------------------------- BLOC RETOUR -------------------------------->
      <v-col cols="12" sm="10" class="pb-0 pl-8 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            RETOUR
          </v-col>
        </v-toolbar>
      </v-col>

      <v-col cols="12" sm="12"  class="pb-0 pl-8 mt-5">
        <div class="text-left mb-3">
          DATE*
        </div>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          light
          
        >
          <template v-slot:activator="{ on, attrs }">

              <span v-if="mode !== 'visualisation'"
              class="d-flex calendar-field">
                  <v-text-field 
                      :value="formatDate"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      light
                      outlined
                      hide-details
    
                    ></v-text-field>

              
                <img  class="ml-5" src="@/assets/calendar.png"/>
              </span>
              <div v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{formatDate}} </div>

          </template>                                <!-- message d'erreur -->

          <v-date-picker
            v-model="retour.date_retour"
            no-title
            locale="fr-fr"
            @input="menu = false"
          ></v-date-picker>

        </v-menu>
                                  <div class="errorMsg" v-if="$v.retour.date_retour.$error">
            Renseignez la date du retour
          </div>
      </v-col>

                  <v-col cols="12" sm="6" class="pb-0 pl-8">
              <div class="text-left mb-3">EMAIL*
              </div>
              <v-text-field v-if="mode !== 'visualisation'"  maxLength=100 single-line outlined v-model="retour.email_client" dense hide-details  ></v-text-field>
              <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{retour.email_client}} </div>
                              <div class="errorMsg" v-if="$v.retour.email_client.$error && $v.retour.email_client.required == false">
                    Renseignez l'email de la personne.
                </div>

                <div class="errorMsg" v-if="$v.retour.email_client.$error && ($v.retour.email_client.required == true && $v.retour.email_client.email == false) ">
                    Renseignez une adresse email valide.
                </div>
            </v-col>

            <v-col cols="12" sm="10" class="pb-0 mt-10 mb-5 pl-8">
              <!-- <div class="text-left" v-if="mode !== 'visualisation'">SELECTIONNEZ UN ARTICLE</div>
              <div class="text-left text-decoration-underline" v-if="mode == 'visualisation'">ARTICLES</div> -->
              <AddArticles :retour_a_articles="retour_a_articles" :mode="mode"/>
          <div class="errorMsg" v-if="retour_a_articles.length == 0 && checkIfArticle == true">
           Au moins un article doit être rajouté
          </div>
            </v-col>
            <v-col class="pb-0 pl-8 ">
              <div class="text-left mb-2">VALIDATION COMMERCIAL</div>
              <div  class="text-left mb-2" v-if="mode == 'visualisation'" :class="trueOrFalse(retour.validation_commercial)">{{retour.validation_commercial == true ? "OUI" : "NON"}}</div>
              <v-checkbox class="mt-n1" 
                v-if="mode !== 'visualisation'"
                v-model="retour.validation_commercial"
                hide-details
              ></v-checkbox>
            </v-col>

          <v-col
            cols="12"
            sm="12"

            class="pb-0 pl-8"
          >
            <v-radio-group
              v-if="mode !== 'visualisation'"
              v-model="retour.id_type_retour"
              row
            >
            <span class="text-left mb-2">
              <div class="text-left mb-2">ERREUR FTA </div>
              <v-radio
                value="1"
              ></v-radio>
            </span>

                        <span class="text-left mb-2 ml-8">
              <div class="text-left mb-2">DEMANDE DE GARANTIE</div>
                <v-radio
                value="3"
              ></v-radio>
            </span>

            <span class="text-left mb-2 ml-8">
              <div class="text-left mb-2">DEMANDE D'AVOIR</div>
              <v-radio
                value="2"
              ></v-radio>
            </span>

                        <span class="text-left mb-2 ml-8">
              <div class="text-left mb-2">DEMANDE DE REPARATION</div>
              <v-radio
                value="4"
              ></v-radio>
            </span>
            </v-radio-group>
            <div class="errorMsg" v-if="$v.retour.id_type_retour.$error && $v.retour.id_type_retour.required == false">
            Renseignez le type de retour.
          </div>
          </v-col>
  
  
      <!-------------------------------- BLOC RETOUR -------------------------------->
      <v-col cols="12" sm="12" class="pb-0 ">
      
      <AddDocuments  :documents="documents" :mode="mode"  ref="AddDocuments"/>
     
      </v-col>

      <!-------------------------------- ERREUR FTA -------------------------------->
      <v-col cols="12" sm="12" class="pb-0 ">
      
      <ErreurFtaForm :retour="retour" :erreurFta="erreurFta" :mode="mode" ref="ErreurFtaForm" />
     
      </v-col>
      <!-------------------------------- ERREUR FTA -------------------------------->

      <!-------------------------------- DEMANDE D'AVOIR' -------------------------------->
      <v-col cols="12" sm="12" class="pb-0 ">
     
      <DemandeDavoirForm :retour="retour" :demandeDavoir="demandeDavoir" :mode="mode" ref="DemandeDavoirForm"/>
     
      </v-col>
      <!-------------------------------- DEMANDE D'AVOIR -------------------------------->

      <!-------------------------------- DEMANDE DE GARANTIE -------------------------------->
      <v-col cols="12" sm="12" class="pb-0 ">
      
      <DemandeDeGarantieForm :retour="retour" :demandeDeGarantie="demandeDeGarantie" :mode="mode" ref="DemandeDeGarantieForm"/>
  
      </v-col>
      <!-------------------------------- DEMANDE DE GARANTIE -------------------------------->

      <!-------------------------------- DEMANDE DE REPARATION -------------------------------->
      <v-col cols="12" sm="12" class="pb-0 ">
   
      <DemandeDeReparationForm :retour="retour" :demandeDeReparation="demandeDeReparation" :mode="mode"  ref="DemandeDeReparationForm"/>
      
      </v-col>
      <!-------------------------------- DEMANDE DE REPARATION -------------------------------->

      <v-col cols="12" sm="10"  class="pb-0 pl-6" v-if="retour.date_cloture != null">
        <div class="text-left mb-3">
           DATE CLOTURE : <span class="text-left font-weight-bold">{{$moment(retour.date_cloture).format("L")}}</span>
        </div>
      </v-col>

      <v-col cols="12" sm="10"  class="alignement_input pl-8">
         <router-link v-if="mode != 'visualisation'" :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}">
        <v-btn @click="$store.state.currentTab = 3" class="btnBold mr-10"  color="black" plain>
          <div>
            Annuler
          </div>
        </v-btn>
      </router-link>
        <v-btn  v-if="mode !== 'visualisation'" class="btnBold"  color="#FF9900" dark rounded type="submit" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              ENREGISTRER
            </div>
      </v-btn>
              <v-btn  v-if="mode == 'modification'" class="btnBold ml-10"  color="#55aacf" dark rounded type="submit" @click="print = 'true'" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              CLÔTURER ET IMPRIMER
            </div>
      </v-btn>
      <router-link id="retourBtn" v-if="mode == 'visualisation'" :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}">
        <v-btn @click="$store.state.currentTab = 3" class="btnBold"  color="#FF9900" dark rounded type="submit" >
          <div>
            RETOUR
          </div>
        </v-btn>
      </router-link>

      </v-col>
      </v-container>
      <v-col align-self="center" cols="2">
        <v-row justify="end">
          <v-dialog v-model="dialogConfirm" persistent max-width="615">
            <v-card class="card_border">
              <v-card-text class="pt-10  modal">
                <p class="text-modal">Un nouveau retour a été créé.</p>
                   <div class="">
                  <v-btn class="btn_choice " rounded text @click="redirectToFicheEntreprise()" >
                    ok
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </form>


  </div>
</template>
<script>
import AddDocuments from "@/components/Clients/AddDocuments";
import AddArticles from "@/components/Clients/AddArticles";
import ErreurFtaForm from "@/components/Forms/ErreurFtaForm";
import DemandeDavoirForm from "@/components/Forms/DemandeDavoirForm";
import DemandeDeGarantieForm from "@/components/Forms/DemandeDeGarantieForm";
import DemandeDeReparationForm from "@/components/Forms/DemandeDeReparationForm";
import { required, email } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  props: {
    retour: Object,
    erreurFta: Object,
    demandeDavoir: Object,
    demandeDeGarantie: Object,
    demandeDeReparation: Object,
    retour_a_articles: {
      type: Array,
      default: () => [],
    },
    retour_types_champs: {
      type: Array,
      default: () => [],
    },
    documents: {
      type: Array,
      default: () => [],
    },
    mode: [String],
  },
  components: {
    AddDocuments,
    AddArticles,
    ErreurFtaForm,
    DemandeDavoirForm,
    DemandeDeGarantieForm,
    DemandeDeReparationForm,
  },
  data() {
    return {
      checkIfArticle: false,
      dialogConfirm: false,
      menu: false,
      dateFormatted: "",
      print: false,
    };
  },
  validations: {
    retour: {
      date_retour: {
        required,
      },
      email_client: {
        required,
        email,
      },
      id_type_retour: {
        required,
      },
    },
  },
  computed: {
    formatDate() {
      return this.retour.date_retour
        ? moment(this.retour.date_retour).format("L")
        : "";
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.retour.id_type_retour == 1) {
        this.$refs.ErreurFtaForm.$v.$touch();
        this.setErreurFta();
      }
      if (this.retour.id_type_retour == 2) {
        this.$refs.DemandeDavoirForm.$v.$touch();
        this.setDemandeDavoir();
      }
      if (this.retour.id_type_retour == 3) {
        this.$refs.DemandeDeGarantieForm.$v.$touch();
        this.setDemandeDeGarantie();
      }
      if (this.retour.id_type_retour == 4) {
        this.$refs.DemandeDeReparationForm.$v.$touch();
        this.setDemandeDeReparation();
      }

      // Scroll sur la première erreur de vuelidate
      if (this.$v.$invalid) {
        this.checkIfArticle = true;
        this.$nextTick(() => {
          let domRect = document.querySelector(".errorMsg");
          domRect.scrollIntoView();
        });
      }
    },
    setErreurFta() {
      console.log(this.mode, 'mode')
      if (
        this.$refs.ErreurFtaForm.$v.$invalid == false &&
        this.$v.$invalid == false &&
        this.retour_a_articles.length > 0 
      ) {
        
        let erreurFtaArray = [];
        erreurFtaArray.push(this.$refs.ErreurFtaForm.erreurFta.produit_complet);
        erreurFtaArray.push(
          this.$refs.ErreurFtaForm.erreurFta.produit_a_demonter
        );
        erreurFtaArray.push(
          this.$refs.ErreurFtaForm.erreurFta.produit_remis_en_stock
        );

        erreurFtaArray.push(
          this.$refs.ErreurFtaForm.erreurFta.commentaire_erreur_fta
        );


        erreurFtaArray.forEach((element) => {
          this.retour_types_champs.push({
            id_type_retour: element.id_type_retour,
            id_champ_type_retour: element.id_champ_type_retour,
            valeur: element.valeur,
          });
        });

        this.retour_a_articles.forEach((element) => {
          delete element.article;
        });

        // Document
        // let document = this.set_document_data()
        let document_data = this.documents;
         let document_config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.$emit("emitRetour", {
          retour: this.retour,
          retour_types_champs: erreurFtaArray,
          retour_a_articles: this.retour_a_articles,
          data: document_data,
          config: document_config,
          print: this.print,
        });

        this.$v.$reset();
      } else {
        this.checkIfArticle = true;
      }
    },
    setDemandeDavoir() {
      if (
        this.$refs.DemandeDavoirForm.$v.$invalid == false &&
        this.$v.$invalid == false &&
        this.retour_a_articles.length > 0
      ) {
        let demandeDavoirArray = [];
        demandeDavoirArray.push(
          this.$refs.DemandeDavoirForm.demandeDavoir.avoir
        );
        if (this.$refs.DemandeDavoirForm.demandeDavoir.avoir.valeur == "true") {
          demandeDavoirArray.push(
            this.$refs.DemandeDavoirForm.demandeDavoir.decote_a_ajouter
          );
          demandeDavoirArray.push(
            this.$refs.DemandeDavoirForm.demandeDavoir.produit_neuf_et_complet
          );
          demandeDavoirArray.push(
            this.$refs.DemandeDavoirForm.demandeDavoir.produit_remis_en_stock
          );
        }

        if (
          this.$refs.DemandeDavoirForm.demandeDavoir.avoir.valeur == "false"
        ) {
          demandeDavoirArray.push(
            this.$refs.DemandeDavoirForm.demandeDavoir.produit_usage
          );
          demandeDavoirArray.push(
            this.$refs.DemandeDavoirForm.demandeDavoir.produit_incomplet
          );
        }

        demandeDavoirArray.forEach((element) => {
          this.retour_types_champs.push({
            id_type_retour: element.id_type_retour,
            id_champ_type_retour: element.id_champ_type_retour,
            valeur: element.valeur,
          });
        });

        this.retour_a_articles.forEach((element) => {
          delete element.article;
        });

        // Document
        // let document = this.set_document_data()
        let document_data = this.documents;
        let document_config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.$emit("emitRetour", {
          retour: this.retour,
          retour_types_champs: demandeDavoirArray,
          retour_a_articles: this.retour_a_articles,
          data: document_data,
          config: document_config,
          print: this.print,
        });

        this.$v.$reset();
      } else {
        this.checkIfArticle = true;
      }
    },
    setDemandeDeGarantie() {
      if (
        this.$refs.DemandeDeGarantieForm.$v.$invalid == false &&
        this.$v.$invalid == false &&
        this.retour_a_articles.length > 0
      ) {
        let demandeDeGarantieArray = [];
        demandeDeGarantieArray.push(
          this.$refs.DemandeDeGarantieForm.demandeDeGarantie.garantie
        );
        if (
          this.$refs.DemandeDeGarantieForm.demandeDeGarantie.garantie.valeur ==
          "true"
        ) {
          demandeDeGarantieArray.push(
            this.$refs.DemandeDeGarantieForm.demandeDeGarantie
              .produit_non_conforme
          );
          demandeDeGarantieArray.push(
            this.$refs.DemandeDeGarantieForm.demandeDeGarantie
              .commentaire_garantie_acceptee
          );

          // Articles
          this.$refs.DemandeDeGarantieForm.demandeDeGarantie.articles.forEach(
            (article) => {
              demandeDeGarantieArray.push({
                valeur: null,
                id_champ_type_retour: null,
                id_type_retour: 3,
                id_article_retour: article.id_article_retour,
                quantite: article.article_quantite,
              });
            }
          );
        }

        if (
          this.$refs.DemandeDeGarantieForm.demandeDeGarantie.garantie.valeur ==
          "false"
        ) {
          demandeDeGarantieArray.push(
            this.$refs.DemandeDeGarantieForm.demandeDeGarantie.date_du_produit
          );
          demandeDeGarantieArray.push(
            this.$refs.DemandeDeGarantieForm.demandeDeGarantie.delai_depasse
          );
          demandeDeGarantieArray.push(
            this.$refs.DemandeDeGarantieForm.demandeDeGarantie
              .utilisation_non_conforme
          );
          demandeDeGarantieArray.push(
            this.$refs.DemandeDeGarantieForm.demandeDeGarantie
              .commentaire_garantie_refusee
          );
        }

        demandeDeGarantieArray.forEach((element) => {
          this.retour_types_champs.push({
            id_type_retour: element.id_type_retour,
            id_champ_type_retour: element.id_champ_type_retour,
            valeur: element.valeur,
            id_article_retour: element.id_article_retour,
            quantite: element.quantite,
          });
        });

        this.retour_a_articles.forEach((element) => {
          delete element.article;
        });

        // Document
        // let document = this.set_document_data()
        let document_data = this.documents;
         let document_config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.$emit("emitRetour", {
          retour: this.retour,
          retour_types_champs: demandeDeGarantieArray,
          retour_a_articles: this.retour_a_articles,
          data: document_data,
          config: document_config,
          print: this.print,
        });

        this.$v.$reset();
      } else {
        this.checkIfArticle = true;
      }
    },
    setDemandeDeReparation() {
      if (
        this.$refs.DemandeDeReparationForm.$v.$invalid == false &&
        this.$v.$invalid == false &&
        this.retour_a_articles.length > 0
      ) {
        let demandeDeReparationArray = [];
        demandeDeReparationArray.push(
          this.$refs.DemandeDeReparationForm.demandeDeReparation
            .reparation_possible
        );
        demandeDeReparationArray.push(
          this.$refs.DemandeDeReparationForm.demandeDeReparation
            .commentaire_reparation
        );
        if (
          this.$refs.DemandeDeReparationForm.demandeDeReparation
            .reparation_possible.valeur == "true"
        ) {
          demandeDeReparationArray.push(
            this.$refs.DemandeDeReparationForm.demandeDeReparation
              .main_doeuvre_estimee
          );
        }

        // Articles
        this.$refs.DemandeDeReparationForm.demandeDeReparation.articles.forEach(
          (article) => {
            demandeDeReparationArray.push({
              valeur: null,
              id_champ_type_retour: null,
              id_type_retour: 4,
              id_article_retour: article.id_article_retour,
              quantite: article.article_quantite,
            });
          }
        );

        demandeDeReparationArray.forEach((element) => {
          this.retour_types_champs.push({
            id_type_retour: element.id_type_retour,
            id_champ_type_retour: element.id_champ_type_retour,
            valeur: element.valeur,
            id_article_retour: element.id_article_retour,
            quantite: element.quantite,
          });
        });

        this.retour_a_articles.forEach((element) => {
          delete element.article;
        });

        // Document
        // let document = this.set_document_data()
        let document_data = this.documents;
        let document_config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };

        this.$emit("emitRetour", {
          retour: this.retour,
          retour_types_champs: demandeDeReparationArray,
          retour_a_articles: this.retour_a_articles,
          data: document_data,
          config: document_config,
          print: this.print,
        });

        this.$v.$reset();
      } else {
        this.checkIfArticle = true;
      }
    },

    // set_document_data() {
    //     console.log("set document", this.documents)
    //     // let data = new FormData();
    //     // data.append('mode', 'retours');
    //     // for ( var i = 0; i < this.documents.length; i++) {
    //     //   if ( this.documents[i].id_document === undefined) {
    //     //     let file = this.documents[i].files;
    //     //     data.append('documents', file);
    //     //   }
    //     // }

    //     var config = {
    //         header : {
    //         'Content-Type' : 'multipart/form-data'
    //       }
    //     }

    //     return {
    //       data,
    //       config
    //     }
    // },

    trueOrFalse(value) {
      if (value == "true") {
        return "text-left mb-2 font-weight-bold success--text";
      }

      if (value == "false") {
        return "text-left mb-2 font-weight-bold error--text";
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.calendar-field {
  width: 20%;
}

.date-letter {
  font-size: 15px !important;
}

.text-modal {
  color: black;
  font-weight: normal;
  font-size: large;
}

.card_border.v-card.v-sheet.theme--light {
  border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

#retourBtn {
  text-decoration: none;
}
</style>
