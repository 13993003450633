<template>
  <v-data-table
    :headers="header"
    :items="items"
    :items-per-page="nbResults"
    :options.sync="options"
    :server-items-length="totalItems"
    :page="page"
    hide-default-footer
    no-data-text="Aucune donnée disponible"
    class="resultList"
    @click:row="$emit('row', { type: type, data: $event })"
  >
    <template v-slot:[`header.civilite`]>
      <span class="">CIVILIT&Eacute; </span>
    </template>
    <template v-slot:[`header.prenom`]>
      <span class="">PR&Eacute;NOM </span>
    </template>
    <template v-slot:[`header.actionsRetour`]>
      <th style="width: 100px;" class="actionsRetour">
        <span >ACTIONS </span>
      </th>
    </template>

    <template v-if="type == 'devis'" v-slot:[`item`]="{ item }">
      <tr :class="customBackground(item)" class="test template">
        <td>
          <span class="sort" @click="$emit('emitPopupDevis', item.numero_devis)">{{item.raison_sociale}}</span> 
        </td>  
        <td>
          <span class="sort">{{item.numero_devis}}</span> 
        </td>  
        <td>
          <span class="sort">{{item.montant}}</span> 
        </td>  
        <td>
          <span class="sort">{{item.date_devis ? $moment(item.date_devis).format("DD/MM/YYYY") : ""}}</span> 
        </td>  
        <td>
          <span class="sort">{{item.typologie}}</span> 
        </td>  
        <td>
          <span class="sort">{{item.statut}}</span> 
        </td>  
      </tr> 
    </template>

    <template v-slot:[`item.createdAt`]="{ item }">
      <span class="sort"
        >{{
          item.createdAt ? $moment(item.createdAt).format("DD/MM/YYYY") : ""
        }}
      </span>
    </template>
    <template v-slot:[`item.date_projet`]="{ item }">
      <span class="sort"
        >{{
          item.date_projet ? $moment(item.date_projet).format("DD/MM/YYYY") : ""
        }}
      </span>
    </template>
    <template v-slot:[`item.date_retour`]="{ item }">
      <span class="sort">
        {{
          item.date_retour ? $moment(item.date_retour).format("DD/MM/YYYY") : ""
        }}
      </span>
    </template>
    <template v-slot:[`item.date_envoi_limite`]="{ item }">
      <span class="sort">
        {{
          item.date_envoi_limite
            ? $moment(item.date_envoi_limite).format("DD/MM/YYYY")
            : ""
        }}
        <span
          v-if="$moment(item.date_envoi_limite).isBefore($moment().toString()) && closed !== true"
        >
          <v-icon class="fa_color_warning"> fa fa-exclamation-triangle </v-icon>
        </span>
      </span>
    </template>
    <template v-slot:[`item.date_rapport`]="{ item }">
      <span class="sort">
        {{
          item.date_rapport
            ? $moment(item.date_rapport).format("DD/MM/YYYY")
            : ""
        }}
      </span>
    </template>
    <template v-slot:[`item.description`]="{ item }">
      <span class="sort">
        {{ item.description.substring(0,100)+"..." }}
      </span>
    </template>
    <template v-slot:[`item.date_devis`]="{ item }">
      <span class="sort">
        {{
          item.date_devis ? $moment(item.date_devis).format("DD/MM/YYYY") : ""
        }}
      </span>
    </template>

    <template v-slot:[`item.date_comptabilisee`]="{ item }">
      <span class="sort">
        {{
          item.date_comptabilisee
            ? $moment(item.date_comptabilisee).format("DD/MM/YYYY")
            : ""
        }}
      </span>
    </template>

    <template v-slot:[`item.date_cloture`]="{ item }">
      <span class="sort">
        {{
          item.date_cloture
            ? $moment(item.date_cloture).format("DD/MM/YYYY")
            : ""
        }}
      </span>
    </template>


        <template v-slot:[`item.date_document`]="{ item }">
      <span class="sort">
        {{
          item.date_document
            ? $moment(item.date_document).format("DD/MM/YYYY")
            : ""
        }}
      </span>
    </template>
    <template v-slot:[`item.date_livraison_demandee`]="{ item }">
      <span class="sort">
        {{
          item.date_livraison_demandee
            ? $moment(item.date_livraison_demandee).format("DD/MM/YYYY")
            : ""
        }}
      </span>
    </template>
    <template v-slot:[`item.date_livraison_confirmee`]="{ item }">
      <span class="sort">
        {{
          item.date_livraison_confirmee
            ? $moment(item.date_livraison_confirmee).format("DD/MM/YYYY")
            : ""
        }}
      </span>
    </template>
    <!-- <template v-slot:[`item.date_envoi_limite`]="{ item }">
      <span class="sort">
        {{
          item.date_envoi_limite ? $moment(item.date_envoi_limite).format("DD/MM/YYYY") : ""
        }}
      </span>
    </template> -->

    <template v-slot:[`item.actions`]="{ item }">
      <router-link
        :to="{
          name: 'view_projet',
          params: {
            id_entreprise: item.id_entreprise,
            id_projet: item.id_projet,
          },
        }"
      >
        <v-icon v-if="item.canModif != 1" small class="mr-2" eye>
          fa-eye
        </v-icon>
      </router-link>
      <router-link
        :to="{
          name: 'update_projet',
          params: {
            id_entreprise: item.id_entreprise,
            id_projet: item.id_projet,
          },
        }"
      >
        <v-icon v-if="item.canModif == 1" small class="mr-2" eye>
          fa fa-edit
        </v-icon>
      </router-link>
    </template>

        <template v-slot:[`item.actions_bloc_fores`]="{ item }">
      <router-link
        :to="{
          name: 'view_projet',
          params: {
            id_entreprise: item.id_entreprise,
            id_projet: item.id_projet,
          },
        }"
      >
        <v-icon small class="mr-2" eye>
          fa-eye
        </v-icon>
      </router-link>
      <router-link
        :to="{
          name: 'update_projet',
          params: {
            id_entreprise: item.id_entreprise,
            id_projet: item.id_projet,
          },
        }"
      >
        <v-icon small class="mr-2" eye>
          fa fa-edit
        </v-icon>
      </router-link>
    </template>

    <template v-slot:[`item.raison_sociale`]="{ item }">
      <span class="bold">{{ item.raison_sociale.toUpperCase() }}</span>
    </template>
    <template v-slot:[`item.Entreprise.raison_sociale`]="{ item }">
      <span class="bold">{{
        item.Entreprise.raison_sociale.toUpperCase()
      }}</span>
    </template>
    <template v-slot:[`item.nom_projet`]="{ item }">
      <span class="bold">{{ item.nom_projet.toUpperCase() }}</span>
    </template>
    <template v-slot:[`item.bloc_fore`]="{ item }">
      <span><img v-if="item.bloc_fore" style="width: 25px; height: 25px;" src="@/assets/bloc_fore.png"/></span>
    </template>
    <template v-slot:[`item.client`]="{ item }">
      <div v-for="(c, i) in item.client" :key="i">
        <span class="">{{ c ? c.toUpperCase() : "" }}</span>
      </div>
    </template>
    <template v-slot:[`item.validation_commercial`]="{ item }">
      <div v-if="item.validation_commercial">
        <v-icon class="fa_color_check"> fas fa-check </v-icon>
      </div>
    </template>
    <template v-slot:[`item.entierement_expedie`]="{ item }">
      <div v-if="item.entierement_expedie">
        <v-icon class="fa_color_check"> fas fa-check </v-icon>
      </div>
    </template>
    <template v-slot:[`item.appel`]="{ item }">
      <div v-if="item.appel">
        <v-icon class="fa_color_check"> fas fa-check </v-icon>
      </div>
    </template>
    <template v-slot:[`item.visite`]="{ item }">
      <div v-if="item.visite">
        <v-icon class="fa_color_check"> fas fa-check </v-icon>
      </div>
    </template>
    <template v-slot:[`item.action_a_realiser`]="{ item }">
      <div v-if="item.action_a_realiser">
        <v-icon class="fa_color_check"> fas fa-check </v-icon>
      </div>
    </template>
    <template v-slot:[`item.personnes`]="{ item }">
      <div v-if="item.personnes">
        <div v-for="personne in item.Personnes" :key="personne.id_personne">
          {{ personne.prenom + " " + personne.nom.toUpperCase() }}
        </div>
      </div>
    </template>
    <template v-slot:[`item.articles`]="{ item }">
      <div v-if="item.articles">
        <div v-for="article in item.articles" :key="article.id_article_retour">
          {{ article.article + " " + article.reference }}
        </div>
      </div>
    </template>
    <template v-slot:[`item.personnes_rapport`]="{ item }">
      <div v-if="item.Personnes">
        <div v-for="personne in item.Personnes" :key="personne.id_personne">
          {{ personne.prenom + " " + personne.nom.toUpperCase() }}
        </div>
      </div>
    </template>

    <template v-slot:[`item.projet_obtenu`]="{ item }">
      <span> {{ item.projet_obtenu == true ? "OUI" : "NON" }} </span>
    </template>

    <template  v-slot:[`item.documents`]="{ item }">
      <div v-if="type !== 'projets'" class="doc">
        <span v-for="(doc, i) in item.Documents" :key="doc.id_document"
          ><a
            :class="{ 'mr-1': i < item.Documents.length - 1 }"
            :href="doc.chemin"
            target="_blanck"
          >
            <img
              v-if="doc.chemin.split('.').pop() == 'pdf'"
              src="@/assets/pdf.png"
              alt=""
            />
            <img
              v-else-if="
                doc.chemin.split('.').pop().substring(0, 3) == 'doc' ||
                doc.chemin.split('.').pop().substring(0, 3) == 'txt'
              "
              src="@/assets/doc.png"
              alt=""
            />
            <img
              v-else-if="
                doc.chemin.split('.').pop().substring(0, 3) == 'xls' ||
                doc.chemin.split('.').pop().substring(0, 3) == 'csv'
              "
              src="@/assets/excel.png"
              alt=""
            />
            <img v-else src="@/assets/img.png" alt="" />
          </a>
        </span>
      </div>
      <span v-if="type == 'projets'"> {{ item.Documents.length > 0 ? "OUI" : "NON" }} </span>
    </template>

    
    <template v-slot:[`item.dev`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.dev"
        disabled
      ></v-simple-checkbox>
    </template>

        <template v-slot:[`item.cahier_des_charges`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.cahier_des_charges"
        disabled
      ></v-simple-checkbox>
    </template>

            <template v-slot:[`item.conception`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.conception"
        disabled
      ></v-simple-checkbox>
    </template>


            <template v-slot:[`item.chiffrage`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.chiffrage"
        disabled
      ></v-simple-checkbox>
    </template>


            <template v-slot:[`item.fao`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.fao"
        disabled
      ></v-simple-checkbox>
    </template>


            <template v-slot:[`item.production_proto`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.production_proto"
        disabled
      ></v-simple-checkbox>
    </template>


            <template v-slot:[`item.production_pre_serie`]="{ item }">
      <v-simple-checkbox
        color="info"
        v-model="item.production_pre_serie"
        disabled
      ></v-simple-checkbox>
    </template>

    <template
      v-if="
        this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
      "
      v-slot:[`item.produit_a_developper`]="{ item }"
    >
      <div class="d-flex align-center">
        <v-switch
          color="primary"
          v-model="item.produit_a_developper"
          @change="
            $emit('dataChange', {
              produit: item.produit,
              value: $event,
              field: 'developer',
              id: item.id_achat_hydraulique,
              item: item,
            })
          "
        ></v-switch>
        <div>{{ item.produit_a_developper ? "OUI" : "NON" }}</div>
      </div>
    </template>
    <template
      v-if="
        this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
      "
      v-slot:[`item.volume`]="{ item }"
    >
      <div class="d-flex row">
        <v-col cols="2"></v-col>
        <v-col cols="8" class="pl-0 pb-0 pt-6 pr-0"
          ><v-text-field
            placeholder="Nombre"
            v-model="item.volume"
            outlined
            @change="
              $emit('dataChange', {
                produit: item.produit,
                value: $event,
                field: 'volume',
                id: item.id_achat_hydraulique,
                item: item,
              })
            "
          ></v-text-field>
        </v-col>
        <v-col cols="2"></v-col>
      </div>
    </template>
    <template
      v-if="
        this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
      "
      v-slot:[`item.marque_concurrence`]="{ item }"
    >
      <div class="d-flex row">
        <v-col cols="12" class="pl-0 pb-0 pt-6 pr-0"
          ><v-text-field
            placeholder="Marque"
            outlined
            v-model="item.marque_concurrence"
            @change="
              $emit('dataChange', {
                produit: item.produit,
                value: $event,
                field: 'marque_concurrence',
                id: item.id_achat_hydraulique,
                item: item,
              })
            "
          ></v-text-field>
        </v-col>
      </div>
    </template>
    <template
      v-if="
        this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
      "
      v-slot:[`item.fta`]="{ item }"
    >
      <div class="d-flex align-center">
        <v-switch
          color="primary"
          v-model="item.fta"
          @change="
            $emit('dataChange', {
              produit: item.produit,
              value: $event,
              field: 'fta',
              id: item.id_achat_hydraulique,
              item: item,
            })
          "
        ></v-switch>
        <div>{{ item.fta ? "OUI" : "NON" }}</div>
      </div>
    </template>
    <template v-slot:[`item.actions_contact`]="{ item }">
      <div class="inline" v-for="(action, i) in item.actions_contact" :key="i">
        <router-link
          v-if="action.type == 'update'"
          :to="{ name: action.params.name, params: action.params.params }"
        >
          <v-icon v-if="canModif == 1" small class="mr-2" eye>
            fa fa-edit
          </v-icon>
        </router-link>

        <div
          
          @click="$emit('delete', action.id_contact)"
          class="pointer"
        >
          <v-icon v-if="action.type == 'delete'" small class="mr-2">
            fa fa-trash
          </v-icon>
        </div>
      </div>
    </template>

    <template v-slot:[`item.actions_projet`]="{ item }">
      <span @click="showProjet(item)">
        <v-icon small class="mr-2" eye> fa-eye </v-icon></span
      >
      <span v-if="canModif == 1" @click="editProjet(item)">
        <v-icon small class="mr-2" eye> fa fa-edit </v-icon></span
      >
    </template>

    <template v-slot:[`item.actions_projet_closed`]="{ item }">

            <span @click="showProjet(item)">
        <v-icon small class="mr-2" eye> fa-eye </v-icon></span
      >
      <span v-if="canModif == 1" @click="editProjet(item)">
        <v-icon small class="mr-2" eye> fa fa-edit </v-icon></span
      >

            <span v-if="canModif == 1" @click="SetProjetEncours(item)">
        <v-icon medium color="orange darken-2" class="mr-2" eye> mdi-close-circle </v-icon></span
      >
    </template>

    <template v-slot:[`item.actionsRapportEntreprise`]="{ item }">
      <span @click="showRapport(item)">
        <v-icon small class="mr-2" eye> fa-eye </v-icon></span
      >
      <span v-if="canModif == 1" @click="editRapport(item)">
        <v-icon small class="mr-2" eye> fa fa-edit </v-icon></span
      >

            <span  @click="$emit('emitDialogRapport', item.id_rapport)">
        <v-icon small class="mr-2" trash> fa-trash </v-icon></span
      >
    </template>

    <template v-slot:[`item.actionsRetour`]="{ item }">
      <span v-if="canModif != 1" @click="showRetour(item)">
        <v-icon small class="mr-2" eye> fa-eye </v-icon></span
      >
       <router-link
          v-if="canModif == 1"
          :to="{ name: 'update_retour',
        params: {
          id_entreprise: item.Entreprise.id_entreprise,
          id_retour: item.id_retour }
          }"
        >
           <span v-if="canModif == 1">
        <v-icon small class="mr-2" eye> fa fa-edit {{item.Entreprise.id_entreprise}} {{item.id_retour}} </v-icon>
        </span>
        
        </router-link>
      <span  v-if="canModif == 1" @click="$emit('emitDialogRetour', item.id_retour)"><v-icon small class="mr-2" trash> fa-trash </v-icon></span>



     
      
    </template>

    <template v-slot:[`item.actions_retour`]="{ item }">
      <span @click="showRetour(item)">
        <v-icon small class="mr-2" eye> fa-eye </v-icon></span
      >
      <span v-if="canModif == 1" @click="editRetour(item)">
        <v-icon small class="mr-2" eye> fa fa-edit </v-icon></span
      >

      <span v-if="canModif == 1" @click="$emit('emitDialogRetour', item.id_retour)">
        <v-icon small class="mr-2" trash> fa-trash </v-icon></span
      >
    </template>

    <template v-slot:[`item.actions_litige`]="{ item }">
      <!-- <span @click="showLitige(item)"> <v-icon small class="mr-2" eye > fa-eye </v-icon></span> -->
      <span @click="editLitige(item)">
        <v-icon small class="mr-2" eye> fa fa-edit </v-icon></span
      >
    </template>

    <template v-slot:[`item.actions_pret`]="{ item }">
      <span @click="showPret(item)"> <v-icon small class="mr-2" eye > fa-eye </v-icon></span>
      <span v-if="canModif == 1" @click="editPret(item)">
        <v-icon small class="mr-2" eye> fa fa-edit </v-icon></span
      >

      <span v-if="canModif == 1"  @click="$emit('emitDialogPret', item.numero_commande)">
        <v-icon small class="mr-2" trash> fa-trash </v-icon></span>
    </template>

    <template v-slot:[`item.actions_pret_closed`]="{ item }">
      <span @click="showPret(item)"> <v-icon small class="mr-2" eye > fa-eye </v-icon></span>

    </template>

  </v-data-table>
</template>


<script>
import moment from "moment";

export default {
  props: [
    "header",
    "items",
    "nbResults",
    "type",
    "totalItems",
    "page",
    "canModif",
    "dialog",
    "closed"
  ],
  data() {
    return {
      options: {},
      valuesAchatshydraulique: [],
    };
  },
  watch: {
    options: {
      handler(sort) {
        this.$emit("sort", {
          column: sort.sortBy[0],
          order: sort.sortDesc[0],
          page: sort.page,
        });
      },
      deep: true,
    },
  },
  created() {
    console.log("resultList", this.header, this.items);
  },
  methods: {
    test($event) {
      console.log($event);
      return true;
    },
    // compareDate(date) {
    //   date.toString()
    //   $moment(date).isAfter('2019-05-11');
    // },
    customBackground(item) {
      if (item.statut == "A relancer" && moment().diff(item.date_devis, 'days') >= 2) {
        return 'custom-bg-1';
      }else if (item.statut == "A relancer") {
        return '';
      }

      if (item.statut == "En attente") {
        return 'custom-bg-2';
      }

      if (item.statut == "Nouvelle relance") {
        return 'custom-bg-3';
      }
    },
    showProjet(item) {
      this.$router.push({
        name: "view_projet",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          id_projet: item.id_projet,
        },
      });
    },
    SetProjetEncours(item) {
      this.dialog = true
      this.$emit("emitDialog", { id_projet: item.id_projet, dialog: this.dialog });
    },
    editProjet(item) {
      this.$router.push({
        name: "update_projet",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          id_projet: item.id_projet,
        },
      });
    },
    showRapport(item) {
      this.$router.push({
        name: "view_rapport",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          id_rapport: item.id_rapport,
        },
      });
    },
    editRapport(item) {
      this.$router.push({
        name: "update_rapport",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          id_rapport: item.id_rapport,
        },
      });
    },
    showLitige(item) {
      this.$router.push({
        name: "view_litige",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          numero_commande: item.numero_commande,
        },
      });
    },
    editLitige(item) {
      this.$router.push({
        name: "update_litige",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          numero_commande: item.numero_commande,
        },
      });
    },
    showPret(item) {
      this.$router.push({
        name: "view_pret",
        params: {
          numero_commande: item.numero_commande,
        },
      });
    },
    editPret(item) {
      this.$router.push({
        name: "update_pret",
        params: {
          id_entreprise: item.id_entreprise,
          numero_commande: item.numero_commande,
        },
      });
    },
    showRetour(item) {
      this.$router.push({
        name: "view_retour",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          id_retour: item.id_retour,
        },
      });
    },
    goRetour(item) {
      this.$router.push({
        name: "view_retour",
        params: {
          id_entreprise: item.id_entreprise,
          id_retour: item.id_retour,
        },
      });
    },
    editRetour(item) {
      this.$router.push({
        name: "update_retour",
        params: {
          id_entreprise: this.$route.params.id_entreprise,
          id_retour: item.id_retour,
        },
      });
    },
    showData($event) {
      console.log("showDataaa", $event);
      this.$emit("dataChange", $event);
    },
    goTo(params) {
      console.log("oui", params);
      this.$router.push({ name: params.name, params: params.params });
    },
  },
};
</script>
<style lang="scss" >
@import "@/assets/scss/variables.scss";

// .v-data-table ::v-deep table tbody tr:nth-child(odd) {
//   background: #fff;
// }
// .v-data-table ::v-deep table tbody tr:nth-child(even) {
//   background: $table-background-secondary-color;
// }
.resultList {
  
  .fa-eye,
  .fa-edit,
  .fa-trash {
    color: $color-eyes;
    font-size: 2em !important;
  }

  .bold {
    font-weight: 900;
  }
  .v-data-table {
    color: $table-font-color;
  }
  .fa_color_check {
    color: #60cb0f;
  }

  .fa_color_warning {
    color: red;
  }

  .v-data-table {
    width: 100%;
    ::v-deep th {
      font-size: 1em !important;
    }
    .doc {
      padding-top: 3%;
    }
  }

  .theme--light.v-data-table
    ::v-deep
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child {
    padding-bottom: 15px;

    > th {
      border-bottom: none;
      border-right: 1px solid grey;
      height: auto;
    }
  }
  .theme--light.v-data-table
    ::v-deep
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th:last-child {
    border-bottom: none;
    border-right: 0px solid grey;
  }
  ::v-deep table > thead > tr > th span {
    width: 100%;
    padding: auto;
    margin: auto;
    font-weight: 500;
  }

   table > tbody > tr:nth-child(2n + 1) {
    background-color: #eeeeee;
  }

  .custom-bg-1 {
    background-color: #ff6666!important;
  }

  .custom-bg-2 {
    background-color: #99ff99 !important;
  }

  .custom-bg-3 {
    background-color: #99ccff !important;
  }



  ::v-deep .v-data-table-header th.active .v-data-table-header__icon,
  ::v-deep .v-data-table-header th .v-data-table-header__icon {
    opacity: 1;
    color: black;
  }
  ::v-deep .v-data-table-header::after {
    height: 1em;
    display: table-row;
    content: "";
  }
  .theme--light.v-data-table
    ::v-deep
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td {
    font-size: 0.9em;
  }

  ::v-deep .v-data-table-header th.text-center:nth-child(2) {
    // width: 20vh;
  }
  .pointer {
    cursor: pointer;
  }
  .inline {
    display: inline-block;
  }
}
</style>
