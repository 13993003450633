<template>
  <div id="formContact">
    <v-row>
      <v-col cols="12">
        <div class="rectangle_bleu">COORDONNÉES</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pb-0 text-left">
        <label>CIVILITE*</label>
        <v-radio-group v-model="contact.civilite" row>
          <v-radio label="MADAME" value="Madame"></v-radio>
          <v-radio label="MONSIEUR" value="Monsieur"></v-radio>
        </v-radio-group>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="$v.contact.civilite.$error"
        >
          Merci d'indiquer la civilité du contact
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="6" class="pb-0 text-left">
        <label>NOM*</label>
        <v-text-field
          label=""
          single-line
          outlined
          v-model="contact.nom"
          hide-details
          class="mb-3"
        ></v-text-field>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="$v.contact.nom.$error"
        >
          Merci d'indiquer le nom du contact
        </div>
      </v-col>
      <v-col cols="6" sm="6" class="pb-0 text-left">
        <label>PR&Eacute;NOM*</label>
        <v-text-field
          label=""
          single-line
          outlined
          v-model="contact.prenom"
          hide-details
          class="mb-3"
        ></v-text-field>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="$v.contact.prenom.$error"
        >
          Merci d'indiquer le prénom du contact
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="6" class="pb-0 text-left">
        <label>FONCTION</label>
        <v-text-field
          label=""
          single-line
          outlined
          v-model="contact.fonction"
          hide-details
          class="mb-3"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="6" class="pb-0 text-left">
        <label>EMAIL*</label>
        <v-text-field
          label=""
          single-line
          outlined
          v-model="contact.email"
          hide-details
          class="mb-3"
        ></v-text-field>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="!$v.contact.email.required && $v.contact.email.$error"
        >
          Merci d'indiquer l'email du contact
        </div>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="!$v.contact.email.email && $v.contact.email.$error"
        >
          Merci d'indiquer un email valide
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="6" class="pb-0 text-left">
        <label>T&Eacute;L&Eacute;PHONE*</label>
        <v-text-field
          label=""
          single-line
          outlined
          maxLength=10
          v-model="contact.telephone"
          hide-details
          class="mb-3"
        ></v-text-field>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="!$v.contact.telephone.required && $v.contact.telephone.$error"
        >
          Merci d'indiquer le téléphone de l'entreprise
        </div>
        <div
          class="errorMsg text-left red--text mb-3"
          v-if="
            (!$v.contact.telephone.maxLength ||
              !$v.contact.telephone.minLength ||
              !$v.contact.telephone.numeric) &&
            $v.contact.telephone.$error
          "
        >
          Merci d'indiquer un téléphone valide
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-col cols="12">
        <router-link :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}" style="text-decoration: none;">
        <v-btn @click="$store.state.currentTab = 5" class="btnBold mr-10" color="black" plain>
          <div>
            Annuler
          </div>
        </v-btn>
      </router-link>
        <v-btn class="btnBold" :elevation="0" color="#FF9900" dark rounded>
          <v-icon left> fa fa-plus </v-icon>
          <div @click="validationForm" class="pl-5 pr-7">ENREGISTRER</div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
export default {
  props: ["contact","mode"],
  validations: {
    contact: {
      civilite:{required},
      nom: { required },
      prenom: { required },
      email: { email, required },
      telephone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      loading: false,
    },
  },
  methods: {
    validationForm() {
    

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("contact", this.contact);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
#formContact {
  .rectangle_bleu {
    background-color: $background-color-blue;
    color: white;
    padding: 1% 0 1% 2%;
    text-align: left;
    font-weight: bold;
    margin-top: 5%;
  }
  
::v-deep .v-input--radio-group.v-input--radio-group--row {
   margin-top:0
  }

  ::v-deep .v-radio > .v-label {
    font-weight: bold;
    font-size: 1.2em;
  }
  .btnBold {
    font-weight: bold;
  }

}
</style>