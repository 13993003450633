<template lang="">
    <div id="documents">

  <v-col cols="12" lg="12" xl="12">
  
    <v-container class="padd_top" fluid>
      <v-row no-gutters>

      <v-col cols="12" lg="12" xl="12">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 white--text font-weight-bold text-left"
          >
            VOS DOCUMENTS / DOSSIERS
          </v-col>
          <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0 text-right">

          
           <v-btn
                class="btnBold mr-5 font-weight-bold "
                color="#FF9900"
                dark
                rounded   
                @click="dialog = true"            
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>               
          AJOUTER UN DOSSIER
            </v-btn>
              
            <v-btn 
                class="btnBold font-weight-bold "
                color="#FF9900"
                dark
                rounded
                @click="onButtonClick"
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>
                AJOUTER UN DOCUMENT
            </v-btn>
                  <input ref="uploader" class="d-none" type="file" accept="*" @change="onFileChanged" >

            
            <!-- </v-toolbar-title> -->
          </v-col>
        </v-toolbar>
      </v-col>
     
      </v-row>
      
      <v-row align="center">
        <v-col cols="12" lg="12" xl="12">
                 <v-toolbar color="#55ABCF" flat dense class="mb-12">
          <v-col
            cols="12"           
            class="pa-0 font_color text-left"
          >
                 
          <v-breadcrumbs
              :items="breadcrumbs"
              divider=">"
              active-class="active-item"
            >
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :href="item.href"
                  
                @click="updateDirectory(item)"
                > <span v-if="!item.home" :class="{ disable: item.disable,active:!item.disable } " 
                class="white--text font-weight-bold "
               
                >
                    {{ item.text}} 
                </span> 
                <v-icon v-if="item.home">fa fa-home</v-icon>         
                </v-breadcrumbs-item>
              </template>
                
            </v-breadcrumbs>
          </v-col>
        </v-toolbar>


  
                                  <v-card
              tabindex="0"
              title="Glisser/déposer un ou plusieurs fichiers"
              width="100%"
              height="500"
              class="pa-2"
              ref="dropzone"
            >
          <v-row v-if="mode =='entreprises'" >

          <v-col  cols="2" v-for="(directory) in directories" :key="directory.name" >
              <div>
                <img 
                  src="@/assets/folder.png"
                  height="100"
                @click="updateCurrentDirectory(directory.value,directory.name)"
                  
                />
                </div>
               <div>
                {{directory.name}}
               </div> 
               <div>
              
              <img v-if="directory.name != 'projets'" class="pointer" @click="directoryToDelete = directory;dialogDeleteDirectory = true" src="@/assets/poubelle.png"/> 
               
              </div>   
                          
            </v-col>
            <v-col cols="2" v-for="file in files" :key="file.id_document">

              <div>
                <img
                  v-if="file.chemin.split('.').pop() == 'pdf'"
                  src="@/assets/img/pdf.png"
                  alt=""
                />
                <img
                  v-else-if="
                    file.chemin.split('.').pop().substring(0, 3) == 'doc' ||
                    file.chemin.split('.').pop().substring(0, 3) == 'txt'
                  "
                  src="@/assets/img/word.png"
                  alt=""
                />
                <img
                  v-else-if="
                    file.chemin.split('.').pop().substring(0, 3) == 'xls' ||
                    file.chemin.split('.').pop().substring(0, 3) == 'csv'
                  "
                  src="@/assets/img/xls.png"
                  alt=""
                />
                <img v-else src="@/assets/img/type_image.png" alt="" />
        
              </div>
              <div>{{file.document.substring(file.document.indexOf('-')+1)}}</div>
              <div>
                <a                          
                @click="download(file)"
                target="-blanck"
                >               
                <v-icon>mdi-download</v-icon>
                <!-- <img class="mr-2" src="@/assets/imprimante.png"/> -->
                 </a>
 
              <img class="pointer" @click="fileToDelete = file;dialogDeleteFile = true" src="@/assets/poubelle.png"/> 
              </div>
            </v-col>

            </v-row>
                                       </v-card>         

        </v-col> 
        <!-- <v-col cols="12" class="text-left" v-if="pathDir.split('/')[1] == 'projets'">
          <ul> 
            <li class="linkProjet" v-for="projet in projets" :key="projet.id_projet">
              <router-link class="text-decoration-none" v-if="($store.state.AsUser.id_utilisateur == ''
                ? $store.state.user.id_role
                : $store.state.AsUser.id_role) == 1 ||
                  commerciaux.includes(
                    $store.state.AsUser.id_utilisateur == ''
                      ? $store.state.user.id_utilisateur
                      : $store.state.AsUser.id_utilisateur
                  )" :to="{ name: 'update_projet', params: { id_entreprise: $route.params.id_entreprise,id_projet:projet.id_projet }}">{{projet.nom_projet}}</router-link>
                  <router-link v-if="($store.state.AsUser.id_utilisateur == ''
                ? $store.state.user.id_role
                : $store.state.AsUser.id_role) != 1 &&
                  !commerciaux.includes(
                    $store.state.AsUser.id_utilisateur == ''
                      ? $store.state.user.id_utilisateur
                      : $store.state.AsUser.id_utilisateur
                  )" :to="{ name: 'view_projet', params: { id_entreprise: $route.params.id_entreprise,id_projet:projet.id_projet }}">{{projet.nom_projet}}</router-link>
            </li>
          </ul>
        </v-col> -->
<template v-if= "mode == 'projets'">
  

 <v-col  cols="2" v-for="(directory) in directories" :key="directory.name" 
         >
         <router-link class="linkProjet" v-if="($store.state.AsUser.id_utilisateur == ''
                ? $store.state.user.id_role
                : $store.state.AsUser.id_role) == 1 ||
                  commerciaux.includes(
                    $store.state.AsUser.id_utilisateur == ''
                      ? $store.state.user.id_utilisateur
                      : $store.state.AsUser.id_utilisateur
                  )" :to="{ name: 'update_projet', params: { id_entreprise: $route.params.id_entreprise,id_projet:directory.value }}">
              <div>
                <img 
                  src="@/assets/folder.png"
                  height="100"
              
                  
                />
                </div>
               <div>
                {{directory.name}}
               </div> 
                 </router-link>
                 <router-link class="linkProjet" v-else :to="{ name: 'view_projet', params: { id_entreprise: $route.params.id_entreprise,id_projet:directory.value }}">
              <div>
                <img 
                  src="@/assets/folder.png"
                  height="100"
              
                  
                />
                </div>
               <div>
                {{directory.name}}
               </div> 
                 </router-link>
            </v-col>
            
         </template>

















        
      </v-row>
    </v-container>
    </v-col>
     <Popup :dialog="dialog" :text="messageDelete" :actions="actions" @delete="createDirectory" @nothing="dialog=false" >
        <template v-slot:form>
          <v-row>
            <v-col cols="6" offset="3">
                <v-text-field
                label="Dossier"
                placeholder="Dossier"
                outlined
                class="mt-5"
                v-model="directory"
            ></v-text-field>
              </v-col>
          </v-row>
         
        </template>
     </Popup>

    <Popup :dialog="dialogDeleteDirectory" :text="messageDeleteDirectory" :actions="actionsDeleteDirectory" @delete="deleteDirectory" @nothing="dialogDeleteDirectory=false" ></Popup>
    <Popup :dialog="dialogDeleteFile" :text="messageDeleteFile" :actions="actionsDeleteFile" @delete="deleteFile" @nothing="dialogDeleteFile=false" ></Popup>

    </div>
</template>

<script>
import api from "@/api/entreprise";
import apiDocument from "@/api/document";
import Popup from "@/components/shared/Popup";

export default {
  components: { Popup },
  data() {
    return {
      id: this.$route.params.id_entreprise,
      documents: [],
      dialog: false,
      files: [],
      directories: [],
      mode: "entreprises",
      pathDir: this.$route.params.id_entreprise.toString(),
      selectedFile: null,
      messageDelete: "Quel est le nom du dossier ?",
      directory: "",
      actions: {
        actions: [
          {
            btn: "Créer",
            event: "delete",
          },
          {
            btn: "Annuler",
            event: "nothing",
          },
        ],
      },
      currentProjetId: null,
      currentProjetName: null,
      breadcrumbs: [],
      indexBreadcrumb: 0,
      modeDebut: false,
      commerciaux:[],
      dialogDeleteDirectory:false,
      messageDeleteDirectory:"Êtes vous sûr de vouloir supprimer le dossier",
      actionsDeleteDirectory: {
        actions: [
          {
            btn: "Oui",
            event: "delete",
          },
          {
            btn: "Non",
            event: "nothing",
          },
        ],
      },
      directoryToDelete:{},
      dialogDeleteFile:false,
      messageDeleteFile:"Êtes vous sûr de vouloir supprimer le fichier",
      actionsDeleteFile: {
        actions: [
          {
            btn: "Oui",
            event: "delete",
          },
          {
            btn: "Non",
            event: "nothing",
          },
        ],
      },
      fileToDelete:{}
    };
  },
  methods: {
    getDocumentsEntreprise(pathDir, id, mode, modeDebut = null ) {
      api.getEntrepriseDocumentsAndDirectory(pathDir,id,mode, modeDebut, this.currentProjetId)
        .then((res) => {
          if (modeDebut) {
            this.breadcrumbs.push({
              text: this.id.toString(),
              link: true,
              disable: true,
              index: this.indexBreadcrumb,
              mode: "entreprises",
              value: this.id.toString(),
              home:true
            });
            this.indexBreadcrumb++;
             this.updatebradbrumbLine();
            res.data.entreprise.Users.forEach(commercial => {
              this.commerciaux.push(commercial.id_utilisateur)
            });
          }

          this.directories = [];
          res.data.dirs.forEach((dir) => {
            this.directories.push({ name: dir, value: dir });
          });
        
          this.files = res.data.documents;
          if (res.data.projets != null && res.data.projets.length > 0) {
            this.projets = res.data.projets;
          }
        });
    },
    async onFileChanged(e, fileIsFromDrag) {
      if (fileIsFromDrag) {
        this.selectedFile = e;
      }else {
        this.selectedFile = e.target.files[0];
      }
      // On enlève le premier dossier de path dir car correspond à l'id de entreprise
      let path = "";
      if (this.mode == "entreprises") {
        path = this.pathWithoutEntreprise();
      } else {
        path = this.pathWithoutEntrepriseProjets();
      }
      console.log(path, "path");

      let data = new FormData();
      data.append("mode", this.mode);
      if (path != "") {
        data.append("directory", path);
      }

      if (this.mode == "projets") {
        console.log("je suis dansle if");
        data.append("id_projet", this.currentProjetId);
      }

      data.append("documents", this.selectedFile);

      var config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      console.log(path);

      await apiDocument.uploadFile(data, this.id, config);
      path = this.pathWithoutEntreprise();
      this.getDocumentsEntreprise(path, this.id, this.mode, null, true);
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    createDirectory() {
      let path = this.pathWithoutEntreprise();
      // let d = this.currentDirectory;
      if (path != "") {
        path = path + "/" + this.directory;
      } else {
        path = this.directory;
      }
      //  this.currentDirectory = this.id+ path?"/" + path:"" + '/' + this.directory

      console.log("path for create directory", path);

      apiDocument
        .createDirectory({ path: path, entreprise: this.id })
        .then((res) => {
          if (res.status == 200) {
            this.dialog = false;
            this.directory = "";
            this.getDocumentsEntreprise(
              this.pathWithoutEntreprise(),
              this.id,
              this.mode
            );
          }
        });
    },
    pathWithoutEntreprise() {
      let path = this.pathDir.split("/");
      path.splice(0, 1);
      path = path.join("/");
      console.log(this.pathDir, path, "path without  entreprise");
      return path;
    },
    pathWithoutEntrepriseProjets() {
      let path = this.pathDir.split("/");
      path.splice(0, 2);
      path = path.join("/");
      console.log(this.pathDir, path, "path without  entreprise");
      return path;
    },
    async updateDirectory(item) {
      if (!item.disable) {
        console.log(item, this.breadcrumbs);
        this.breadcrumbs.splice(item.index);
        console.log(this.breadcrumbs);

        this.pathDir = this.breadcrumbs.map((e) => e.value).join("/");
        if (item.index == 0) {
          this.modeDebut = true;
          this.mode = "entreprises";
          // this.breadcrumbs = [];
        }
        this.indexBreadcrumb = item.index;
        console.log(item.value, item.text, this.pathDir, this.breadcrumbs);
        await this.updateCurrentDirectory(item.value, item.text);

        this.updatebradbrumbLine();
      }
    },
    updatebradbrumbLine(){
      this.breadcrumbs.forEach((breadcrumb,i) =>{
        if(i != this.breadcrumbs.length - 1){
          breadcrumb.disable = false
          console.log('true')
        }
          console.log('foreach updatebread', breadcrumb,i)
      })
    },
    updateCurrentDirectory(name, projetName) {
      
      if (this.pathDir != "") {
        this.pathDir = this.pathDir + "/" + name;
      } else {
        this.pathDir = name;
      }
      console.log(name, projetName, this.pathDir, projetName);
      if (typeof name == "number" && !this.modeDebut) {
        console.log("je rentre icii", this.breadcrumbs);
        this.currentProjetId = name;
        this.breadcrumbs.push({
          text: projetName,
          link: true,
          disable: true,
          index: this.indexBreadcrumb,
          mode: this.mode,
          value: name,
        });
        this.indexBreadcrumb++;
         this.updatebradbrumbLine();
      } else if (typeof name != "number" && !this.modeDebut) {
        console.log("je rentre laa", this.breadcrumbs);
        this.breadcrumbs.push({
          text: name,
          link: true,
          disable: true,
          index: this.indexBreadcrumb,
          mode: this.mode,
          value: name,
        });
        this.indexBreadcrumb++;
         this.updatebradbrumbLine();
      }

      if (name == "projets") {
        this.mode = "projets";
        this.directories = [];
        this.files = []
        this.projets.forEach((projet) => {
          this.directories.push({
            name: projet.nom_projet,
            value: projet.id_projet,
          });
        });
      } else {
        console.log(
          this.pathWithoutEntreprise(),
          this.currentProjetId,
          "before send"
        );
        this.getDocumentsEntreprise(
          this.pathWithoutEntreprise(),
          this.id,
          this.mode,
          this.modeDebut
        );
        this.modeDebut = false;
      }

      // this.getDocumentsEntreprise(this.pathWithoutEntreprise(), this.id, this.mode);
    },
    deleteFile() {
      // console.log(this.fileToDelete);
      apiDocument.deleteDocument(this.fileToDelete.id_document).then(() => {
        this.getDocumentsEntreprise(
          this.mode == "entreprises"
            ? this.pathWithoutEntreprise()
            : this.pathWithoutEntrepriseProjets(),
          this.id,
          this.mode,
          this.modeDebut
        );
        this.dialogDeleteFile = false
      });
    },
    download(file) {
      apiDocument.download_Documents(file.id_document).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", file.document);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    async deleteDirectory(directory) {
      console.log(directory, "directory to delete",this.directoryToDelete,'directory to delete');

      let path =
        this.mode == "entreprises"
          ? this.pathWithoutEntreprise() + "/" + this.directoryToDelete.value
          : "projets/" +
            this.pathWithoutEntrepriseProjets() +
            "/" +
            directory.value;
      await apiDocument.deleteDirectory(this.id, { path: path });
      this.getDocumentsEntreprise(
        this.pathWithoutEntreprise(),
        this.id,
        this.mode,
        this.modeDebut
      );
      this.dialogDeleteDirectory = false
    },
  },
  created() {
    this.getDocumentsEntreprise("", this.id, this.mode, true);
  },
  mounted() {
        const dropzone = this.$refs.dropzone.$el;

        // register listeners on your dropzone / v-sheet
    if(dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", e => { [2]
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener("dragleave", e => { [2]
        e.preventDefault()
        this.dragover = false
      })
      dropzone.addEventListener("dragover", e => { [2]
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener("drop", e => { [3]
        e.preventDefault()
        const dragevent = e
        if(dragevent.dataTransfer) {
          var fileIsFromDrag = true;
          for ( let i=0; i < dragevent.dataTransfer.files.length; i++ ) {
            this.selectedFile = dragevent.dataTransfer.files[i];
            this.onFileChanged(this.selectedFile, fileIsFromDrag);
            // this.documents.push({
            //   files: this.selectedFile,
            // });
          }

        }
      })
    }
  }
};
</script>

<style style="scss" scoped>

.active {
  cursor: pointer;
  font-weight: bold;
}
::v-deep .v-breadcrumbs__divider{
  font-weight: bold;
  color: #fff!important;
}
.disable{
  font-weight:normal!important
}
.v-breadcrumbs li .v-icon {
	font-size: 16px;
	color: white;
}
::v-deep .linkProjet{
  list-style-type:square
 
}
.linkProjet a{
   
    color:#4B4B4B;
 
}
a div:nth-child(2){
  color:#4B4B4B;
  
}
a.linkProjet{
text-decoration:none;
}
</style>
