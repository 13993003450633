<template lang="">

      <!-------------------------------- ERREUR FTA -------------------------------->
      <div v-if="retour.id_type_retour == 1">
            <v-col cols="12" sm="10" class="pb-0   mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            ERREUR FTA
          </v-col>
        </v-toolbar>
          </v-col>

                  <v-col cols="12" sm="6" class="pb-0   mt-5">
              <div class="text-left">PRODUIT COMPLET*
              </div>
                      <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="erreurFta.produit_complet.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div class="errorMsg" v-if="$v.erreurFta.produit_complet.valeur.$error">
            Renseignez une valeur
          </div>
          <div v-if="mode == 'visualisation'" :class="produitcomplet(erreurFta.produit_complet.valeur)">{{erreurFta.produit_complet.text}}</div>
            </v-col>

    <v-col cols="12" sm="6" class="pb-0  ">
      <div class="text-left mt-2">PRODUIT A DEMONTER*</div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="erreurFta.produit_a_demonter.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
                <div class="errorMsg" v-if="$v.erreurFta.produit_a_demonter.valeur.$error">
            Renseignez une valeur
          </div>
          <div v-if="mode == 'visualisation'" :class="produitademonter(erreurFta.produit_a_demonter.valeur)">{{erreurFta.produit_a_demonter.text}}</div>
    </v-col>

        <v-col cols="12" sm="6" class="pb-0  ">
      <div class="text-left mt-2">PRODUIT REMIS EN STOCK*</div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="erreurFta.produit_remis_en_stock.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div class="errorMsg" v-if="$v.erreurFta.produit_remis_en_stock.valeur.$error">
            Renseignez une valeur
          </div>
           <div v-if="mode == 'visualisation'" :class="produitremisenstock(erreurFta.produit_remis_en_stock.valeur)">{{erreurFta.produit_remis_en_stock.text}}</div>
    </v-col>

    <v-col cols="12" sm="6" class="pb-0  ">
      <div class="text-left mt-2">COMMENTAIRE*</div>
        <v-textarea
          v-if="mode !== 'visualisation'"
          v-model="erreurFta.commentaire_erreur_fta.valeur"
          outlined auto-grow rows="1"
          hide-details
          class="mt-2"
        >
        </v-textarea>
          <div class="errorMsg" v-if="$v.erreurFta.commentaire_erreur_fta.valeur.$error">
            Renseignez un commentaire
          </div>

      <div
      v-if="mode == 'visualisation'"
      class="mt-2 font-italic text-h6 text-left"
      style="overflow-wrap: break-word;"
      >
        {{ erreurFta.commentaire_erreur_fta.valeur }}
      </div>
    </v-col>


      </div>
      <!-------------------------------- ERREUR FTA -------------------------------->
</template>
<script>
import { requiredIf } from 'vuelidate/lib/validators'
import moment from "moment";



export default {
props: {
  retour: Object,
  erreurFta: Object,
  mode: [String]
},
  components: {
  },
  data() {
    return {

    };
  },
    validations: {
      erreurFta: {
      produit_complet: {
        valeur: {
          required: requiredIf(function () {
          return this.mode == "modification";
        }),
        }
      },
      produit_a_demonter: {
        valeur: {
          required: requiredIf(function () {
          return this.mode == "modification";
        }),
        }
      },
      produit_remis_en_stock: {
        valeur: {
          required: requiredIf(function () {
          return this.mode == "modification";
        }),
        }
      },
      commentaire_erreur_fta: {
        valeur: {
          required: requiredIf(function () {
          return this.mode == "modification";
        }),
        }
      }
    }
  },
  computed: {
    formatDate() {
      return this.erreurFta.date_retour
        ? moment(this.erreurFta.date_retour).format("L")
        : "";

    }
  },
  methods: {
    produitcomplet(value) {
      if ( value == "true" ) {
        this.erreurFta.produit_complet.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.erreurFta.produit_complet.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.erreurFta.produit_complet.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },
    produitademonter(value) {
      if ( value == "true" ) {
        this.erreurFta.produit_a_demonter.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.erreurFta.produit_a_demonter.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.erreurFta.produit_a_demonter.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },
    produitremisenstock(value) {
      if ( value == "true" ) {
        this.erreurFta.produit_remis_en_stock.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.erreurFta.produit_remis_en_stock.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.erreurFta.produit_remis_en_stock.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    }

  },
  mounted() {
  }
};
</script>
<style scoped>
.calendar-field{
      width: 20%;
}

.date-letter {
  font-size: 15px !important
}

.text-modal{
    color: black;
    font-weight: normal;
    font-size: large;
}

.card_border.v-card.v-sheet.theme--light {
	border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width:29px;
  height: 28px
}

.font_color {
  font-weight: bold;
  color: #FFFFFF 
}

.width-50 {
  width: 30%
}

.addPersonne {
  border-style: dashed ;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}




</style>
