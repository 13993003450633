import axios from 'axios';

const RESSOURCE_NAME = 'rapports';

export default {
    createRapport(rapport){
         console.log('rapport api', rapport)
        return axios.post(`${RESSOURCE_NAME}/create`, {rapport_data:rapport})
    },
    getRapport(id_rapport){
        return axios.get(`${RESSOURCE_NAME}/${id_rapport}`)
    },
    updateRapport(rapport){
        console.log('rapport api', rapport)
       return axios.post(`${RESSOURCE_NAME}/update/${rapport.rapport.id_rapport}`, {rapport_data:rapport})
    },
    deleteRapport(id_rapport){
       console.log('rapport api', id_rapport)
       return axios.delete(`${RESSOURCE_NAME}/delete/${id_rapport}`)
   },

    getRappelsRapport(id_entreprise, id_rapport){
        return axios.get(`${RESSOURCE_NAME}/${id_entreprise}/rappels/${id_rapport}`)
    }
  
  
}