<template lang="">

      <!-------------------------------- DEMANDE D'AVOIR -------------------------------->
      <div v-if="retour.id_type_retour == 3">
            <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            DEMANDE DE GARANTIE
          </v-col>
        </v-toolbar>
          </v-col>

                  <v-col cols="12" sm="4" class="pb-0  mt-5">
              <div class="text-left">GARANTIE
              </div>

        <v-select
          v-if="mode !== 'visualisation'"
          :items="valeurSelect"
          :item-text="valeurSelect.text"
          :item-value="valeurSelect.value"
          v-model="demandeDeGarantie.garantie.valeur"
          item-color='primary'
          class="text-color mt-3"
          hide-details
          solo
        ></v-select>

          <div class="errorMsg" v-if="$v.demandeDeGarantie.garantie.valeur.$error">
            Renseignez produit complet
          </div>
          <div v-if="mode == 'visualisation'" :class="garantieValue(demandeDeGarantie.garantie.valeur)">{{demandeDeGarantie.garantie.text}}</div>
            </v-col>

   <!-------------------------------------- GARANTIE ACCEPTE ------------------------------------->
  <div  v-if="demandeDeGarantie.garantie.valeur == 'true'">

    <v-col cols="12" sm="6" class="pb-0 ">
      <div class="text-left mt-5 mb-3">PRODUIT NON CONFORME<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="demandeDeGarantie.produit_non_conforme.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="produitnonconforme(demandeDeGarantie.produit_non_conforme.valeur)">{{demandeDeGarantie.produit_non_conforme.text}}</div>
                <div class="errorMsg" v-if="$v.demandeDeGarantie.produit_non_conforme.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>

      <v-col  cols="12" sm="6" class="pb-0  mt-5">
       <div class="text-left mb-3">COMMENTAIRE<span v-if="mode !='creation'">*</span></div>
        <v-textarea  v-if="mode !== 'visualisation'" outlined auto-grow rows="1"  v-model="demandeDeGarantie.commentaire_garantie_acceptee.valeur" hide-details> </v-textarea>
          <div  v-if="mode == 'visualisation'" class="font-italic text-h6 text-left" style="overflow-wrap: break-word;">{{ demandeDeGarantie.commentaire_garantie_acceptee.valeur }}</div>
          <div class="errorMsg" v-if="$v.demandeDeGarantie.commentaire_garantie_acceptee.valeur.$error && ($v.demandeDeGarantie.commentaire_garantie_acceptee.valeur.required == false)">
            Renseignez un commentaire
          </div>

          <div class="errorMsg" v-if="$v.demandeDeGarantie.commentaire_garantie_acceptee.valeur.$error && ($v.demandeDeGarantie.commentaire_garantie_acceptee.valeur.required == true && $v.demandeDeGarantie.commentaire_garantie_acceptee.valeur.maxLength == false) ">
            2000 caractères au maximum.
          </div>
      </v-col>

      <v-col  cols="12" sm="10" class="pb-0  mt-10 mb-12">
        <!-- <div class="text-left">ARTICLES A MODIFIER OU REMPLACER</div> -->
        <AddArticles :retour_a_articles="demandeDeGarantie.articles" :mode="mode" widthProp="70vW" :title="'ARTICLES A MODIFIER OU REMPLACER'" />
      </v-col>
  </div>
   <!-------------------------------------- GARANTIE ACCEPTE ------------------------------------->
      <!-------------------------------------- GARANTIE REFUSE ------------------------------------->

  <div  v-if="demandeDeGarantie.garantie.valeur == 'false'">

            <v-col cols="12" sm="12"  class="pb-0  mt-5">
        <div class="text-left">
          DATE DU PRODUIT<span v-if="mode !='creation'">*</span>
        </div>
        <v-menu
          ref="menuGarantie"
          v-model="menuGarantie"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          light
        >
          <template v-slot:activator="{ on, attrs }">

              <span v-if="mode !== 'visualisation'"
              class="d-flex calendar-field">
                  <v-text-field 
                      :value="formatDateGarantie"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      light
                      hide-details
                      outlined

    
                    ></v-text-field>

              
                <img  class="ml-5" src="@/assets/calendar.png"/>
              </span>
              <div v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{formatDateGarantie}} </div>

          </template>                                <!-- message d'erreur -->

          <v-date-picker
            v-model="demandeDeGarantie.date_du_produit.valeur"
            no-title
            @input="menuGarantie = false"
          ></v-date-picker>

        </v-menu>
          <div class="errorMsg" v-if="$v.demandeDeGarantie.date_du_produit.$error">
            Renseignez la date du produit
          </div>
      </v-col>

    <v-col cols="12" sm="6" class="pb-0 ">
      <div class="text-left mt-2">DELAI DEPASSE<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="demandeDeGarantie.delai_depasse.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="trueOrFalse(demandeDeGarantie.delai_depasse.valeur)">{{demandeDeGarantie.delai_depasse.valeur == "true" ? "OUI" : "NON"}}</div>
          <div class="errorMsg" v-if="$v.demandeDeGarantie.delai_depasse.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>

        <v-col cols="12" sm="6" class="pb-0 ">
      <div class="text-left mt-2">UTILISATION NON CONFORME <span v-if="mode !='creation'">*</span> </div>
        <v-radio-group
          v-if="mode !== 'visualisation'" 
          v-model="demandeDeGarantie.utilisation_non_conforme.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="trueOrFalse(demandeDeGarantie.utilisation_non_conforme.valeur)">{{demandeDeGarantie.utilisation_non_conforme.valeur == "true" ? "OUI" : "NON"}}</div>
          <div class="errorMsg" v-if="$v.demandeDeGarantie.utilisation_non_conforme.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>

          <v-col  cols="12" sm="6" class="pb-0  mb-12">
       <div class="text-left">COMMENTAIRE<span v-if="mode !='creation'">*</span></div>
        <v-textarea v-if="mode !== 'visualisation'" outlined auto-grow rows="1"  v-model="demandeDeGarantie.commentaire_garantie_refusee.valeur" hide-details> </v-textarea>
          <div  v-if="mode == 'visualisation'" class="font-italic text-h6 text-left" style="overflow-wrap: break-word;">{{ demandeDeGarantie.commentaire_garantie_refusee.valeur }}</div>
          <div class="errorMsg" v-if="$v.demandeDeGarantie.commentaire_garantie_refusee.valeur.$error && ($v.demandeDeGarantie.commentaire_garantie_refusee.valeur.required == false)">
            Renseignez un commentaire
          </div>

          <div class="errorMsg" v-if="$v.demandeDeGarantie.commentaire_garantie_refusee.valeur.$error && ($v.demandeDeGarantie.commentaire_garantie_refusee.valeur.required == true && $v.demandeDeGarantie.commentaire_garantie_refusee.valeur.maxLength == false) ">
            2000 caractères au maximum.
          </div>
      </v-col>
  </div>
   <!-------------------------------------- GARANTIE REFUSE ------------------------------------->


      </div>

</template>
<script>
import { required, requiredIf, maxLength} from 'vuelidate/lib/validators'
import AddArticles from "@/components/Clients/AddArticles"
import moment from "moment";




export default {
props: {
  retour: Object,
  demandeDeGarantie: Object,
  mode: [String]
},
  components: {
    AddArticles,
  },
  data() {
    return {
      menuGarantie: false,
      dateTest: "",
      valeurSelect: [
        {
          text: "acceptée",
          value: "true"
        },
        {
          text: "refusée",
          value: "false"
        },
        {
          text: "en attente",
          value: "pending"
        },
      ]      
    };
  },
    validations: {
      demandeDeGarantie: {
      garantie: {
        valeur: {
          required
        }
      },
      produit_non_conforme: {
        valeur: {
         required: requiredIf(function(){ return this.conditionValidation("true") && this.mode != "creation" })
        }
      },
      commentaire_garantie_acceptee: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("true") && this.mode != "creation" }),
          maxLength: maxLength(2000)
        }
      },
      delai_depasse: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("false") && this.mode != "creation" })
        }
      },
      utilisation_non_conforme: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("false") && this.mode != "creation"})
        }
      },
      date_du_produit: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("false") && this.mode != "creation" })
        }
      },
      commentaire_garantie_refusee: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("false") && this.mode != "creation" }),
          maxLength: maxLength(2000)
        }
      }
    }
  },
  computed: {
    formatDateGarantie() {
      return this.demandeDeGarantie.date_du_produit.valeur
        ? moment(this.demandeDeGarantie.date_du_produit.valeur).format("L")
        : "";

    }
  },
  methods: {
    conditionValidation(valeur){
      console.log(this.demandeDeGarantie.garantie.valeur,valeur,'condition validation')
      if ( this.demandeDeGarantie.garantie.valeur == valeur) {
        return true
      }

    },
    trueOrFalse(value) {
      if ( value == "true" ) {
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        return "text-left mb-2 font-weight-bold error--text"
      }
    },
    produitnonconforme(value) {
      if ( value == "true" ) {
        this.demandeDeGarantie.produit_non_conforme.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value === "false" ) {
        this.demandeDeGarantie.produit_non_conforme.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.demandeDeGarantie.produit_non_conforme.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },
    garantieValue(value) {
      if ( value == "true" ) {
        this.demandeDeGarantie.garantie.text = "acceptée"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.demandeDeGarantie.garantie.text = "refusée"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == "pending" ) {
        this.demandeDeGarantie.garantie.text = "en attente"
        return "text-left mb-2 font-weight-bold"
      }
    }
  },
  mounted() {
    console.log(this.mode,'moddde')
  }
};
</script>
<style scoped>
.calendar-field{
      width: 20%;
}

.date-letter {
  font-size: 15px !important
}

.text-modal{
    color: black;
    font-weight: normal;
    font-size: large;
}

::v-deep .v-select__selection.v-select__selection--comma {
	color: #0B87BA;
  font-style: italic;
}

::v-deep .v-icon.notranslate.mdi.mdi-menu-down {
	color: #0B87BA !important;
	font-size: 2.5em;
}



.card_border.v-card.v-sheet.theme--light {
	border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width:29px;
  height: 28px
}

.font_color {
  font-weight: bold;
  color: #FFFFFF 
}

.width-50 {
  width: 30%
}

.addPersonne {
  border-style: dashed ;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}




</style>
