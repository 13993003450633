<template>
  <v-row>
    <v-col cols="12">
      <Title :titlePage="titlePage" />
    </v-col>

    <v-container>
      <PretClientForm
        :pret_client="pret_client"
        :statuts="statuts"
        :mode="mode"
        @closeRappel="closeRappel"
        @emitPret="createOrUpdatePret"
      />
              <!-- @emitProjet="createOrUpdateProjet"
        @closeRappel="closeRappel" -->
      <!-- <Popup :dialog="dialog" :text="messageCreation" :actions="actions" />  -->
    </v-container>
  </v-row>
</template>
<script>
import Title from "@/components/shared/Title";
import PretClientForm from "@/components/Forms/PretClientForm";
// import Popup from "@/components/shared/Popup";

import api from "@/api/litiges";
// import api_doc from "@/api/document";
import api_rappels from "@/api/rappel";
// import api_user from "@/api/user";

export default {
  components: {
    Title,
    PretClientForm
    // Popup,
  },
  data() {
    return {
      pret_client: {
        id_pret_client: 0,
        numero_commande: "",
        raison_sociale: "",
        date_pret: "",
        date_cloture: "",
        personnes: [],
        description: "",
        commentaires: [],
        commentaire_: "",
        rappels: [],
        commentaire_pret: "",
        id_statut_pret_client: null,
        cloture: false,
        StatutPretClient: {},
        isExisting: false,
        id_entreprise: 0
      },
      rappel: {
        urgent: false,
        date_rappel: "",
        commentaire: "",
      },
      statuts: [],
      utilisateurs: [],
      titlePage: "MODIFIER UN PRET",
      dialog: false,
      messageCreation: "",
      actions: {
        route: [
          {
            btn: "OK",
            routeName: "client",
            params: { id_entreprise: this.$route.params.id_entreprise },
          },
        ],
      },
      mode: "creation",
    };
  },

  methods: {


    closeRappel(rappel) {
      console.log(rappel, "projetEntreprise rappel")
      api_rappels.closeRappel(rappel.id_rappel).then((res) => {
          api.getRappelsPret(this.id_entreprise, this.pret_client.id_pret_client).then((res) => {
            this.pret_client.rappels = res.data.rappels
          })
          console.log(res, 'res')
      });
    },

    createOrUpdatePret(pret) {
      // contact.id_entreprise = this.$route.params.id_entreprise;
      // this.projet = projet;
      console.log(pret, "pret createOrUpdate")
      api.createOrUpdatePret(pret.pret_client).then((res) => {
        console.log(res, "res createOrUpdateProjet")
      })

    },

    getPretClient(numero_commande) {
      api.getPretClient(numero_commande).then((res) => {
        // this.projet = res.data.projet[0];
        console.log(res, "res pret")
        this.pret_client.id_pret_client = res.data.pret != null ? res.data.pret.PretClient.id_pret_client : 0 ;
        this.pret_client.numero_commande = res.data.pret != null ? res.data.pret.numero_commande : res.data.commande.numero_commande ;
        this.pret_client.raison_sociale = res.data.pret != null ? res.data.pret.raison_sociale : res.data.commande.raison_sociale;
        this.pret_client.date_pret = res.data.pret != null ? res.data.pret.date_comptabilisee : res.data.commande.date_comptabilisee;
        this.pret_client.date_cloture = res.data.pret != null ? res.data.pret.PretClient.date_cloture : "";
        this.pret_client.personnes = res.data.pret != null ? res.data.pret.PretClient.Personnes : [];
        this.pret_client.description = res.data.pret != null ? res.data.pret.PretClient.description : "";
        this.pret_client.commentaires = res.data.pret != null ? res.data.pret.PretClient.Commentaires: [];
        this.pret_client.rappels = res.data.pret != null ? res.data.pret.PretClient.Rappels : [];
        this.pret_client.commentaire_pret= res.data.pret != null ? res.data.pret.PretClient.commentaire_pret : "";
        this.pret_client.id_statut_pret_client = res.data.pret != null ? res.data.pret.PretClient.id_statut_pret_client : null;
        this.pret_client.StatutPretClient = res.data.pret != null ? res.data.pret.PretClient.StatutPretClient : {};
        this.pret_client.isExisting = res.data.pret != null ? true : false;
        this.pret_client.id_entreprise = this.$route.params.id_entreprise;
        this.statuts = res.data.statutsPretsClients;



        console.log("pret_client", this.pret_client);
        // this.projet.documents = res.data.projet[0].Documents;
        this.pret_client.commentaires = res.data.pret.PretClient.commentaires.sort(function (a, b) {
          return a.id_commentaire - b.id_commentaire;
        });
      });
    },
  },
  created() {
    if (this.$route.name == "update_pret") {
      this.titlePage = "MODIFIER UN PRET";
      this.mode = "modification";
      this.getPretClient(this.$route.params.numero_commande);
    }

    if (this.$route.name == "view_pret") {
      this.titlePage = "VOIR UN PRET";
      this.mode = "visualisation";
      this.getPretClient(this.$route.params.numero_commande);
    }
  },
};
</script>
<style>
</style>