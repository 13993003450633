<template lang="">
  <div class="d-flex">
   
      <!-------------------------------- BLOC PIECES JOINTES-------------------------------->
      <v-col cols="12" sm="10" class="pb-0 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            PIECES JOINTES
          </v-col>

           <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0 text-right">
            <v-btn v-if="mode !== 'visualisation'"
                class="btnBold"
                color="#FF9900"
                dark
                rounded
                @click="onButtonClick"
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>
                Ajouter une piece jointe
            </v-btn>
                  <input ref="uploader" class="d-none" type="file" accept="*" @change="onFileChanged" >



          </v-col>

        </v-toolbar>
 <div class="text-left pt-12">
        
            <v-sheet
      tabindex="0"
      width="100%"
      class="pa-2"
      ref="dropzone"
    >
        <p v-if="documents.length == 0"> Aucune pièce jointe n'a été ajoutée</p>
          <v-list-item two-line v-if="documents.length>0">
        <v-list-item-content             
              v-for="(document, index) in documents"
              v-bind:key="document.files.lastModified"
              v-bind:name="document.files.name"
              v-on:remove="document.splice(index, 1)">
          <v-list-item-title class="d-flex justify-center">
            <a
              :class="{ 'mr-1': index < documents.length - 1 }"
              :href="document.chemin"
              target="_blank"
            >
              <img
                v-if="document.files.name.split('.').pop() == 'pdf'"
                src="@/assets/pdf.png"
                alt=""
              />
              <img
                v-else-if="
                  document.files.name
                    .split('.')
                    .pop()
                    .substring(0, 3) == 'doc' ||
                    document.files.name
                      .split('.')
                      .pop()
                      .substring(0, 3) == 'txt'
                "
                src="@/assets/doc.png"
                alt=""
              />
              <img
                v-else-if="
                  document.files.name
                    .split('.')
                    .pop()
                    .substring(0, 3) == 'xls' ||
                    document.files.name
                      .split('.')
                      .pop()
                      .substring(0, 3) == 'csv'
                "
                src="@/assets/excel.png"
                alt=""
              />
              <img v-else src="@/assets/img.png" alt="" />
            </a>
            </v-list-item-title>
          <v-list-item-subtitle class="d-flex justify-center">
          {{document.files.name.substring(document.files.name.indexOf('-') + 1)}}
          </v-list-item-subtitle>
                    <p class="d-flex justify-center">ajouté le : {{$moment(document.createdAt).format('DD/MM/YYYY')}}</p>

          <v-list-item-subtitle class="d-flex justify-center"><v-btn v-if="mode !== 'visualisation'"  @click="deleteDocument(document, index, true)"><strong>Supprimer</strong></v-btn></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>

    </v-sheet>

      </div>

      </v-col>
       <!-------------------------------- BLOC PIECES JOINTES-------------------------------->

              <!-- Popup  -->
              <!-- <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Supprimer ce document ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">Non</v-btn>
              <v-btn color="blue darken-1" text @click="deleteDocument(document, index, false)">Oui</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

         <Popup :dialog="dialog" text="Supprimer ce document ?" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="false"> 
                     <v-card-actions class="mb-10">
          
              <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialog = false">Non</v-btn>
              <v-btn class="actions" color="#0B87BA" width="100px" large dark rounded @click="deleteDocument(document, index, false)">Oui</v-btn>
              
            </v-card-actions> 
        </Popup>

  </div>
</template>
<script>
import api_doc from "@/api/document";
 import Popup from "@/components/shared/Popup";
export default {
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    mode: [String],
  },
  components: {Popup},
  data() {
    return {
      documentToDelete: null,
      indexToDelete: null,
      dialog: false,
      selectedFile: null,
      isSelecting: false,
      // documents: []
    };
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.documents.push({
        files: this.selectedFile,
      });
      console.log("documents add_documents", this.documents);
    },
    deleteDocument(document, index, open) {
      if (open == true) {
        this.documentToDelete = document;
        this.indexToDelete = index;
        this.dialog = true;
      }
      if (open == false) {
        this.documents.splice(this.indexToDelete, 1);
        if (this.documentToDelete.id_document !== undefined) {
          api_doc
            .deleteDocument(this.documentToDelete.id_document)
            .then((res) => {
              console.log(res);
            });
        }
        this.dialog = false;
      }
    },
  },
  mounted() {
    const dropzone = this.$refs.dropzone.$el;

        // register listeners on your dropzone / v-sheet
    if(dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener("dragenter", e => { [2]
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener("dragleave", e => { [2]
        e.preventDefault()
        this.dragover = false
      })
      dropzone.addEventListener("dragover", e => { [2]
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener("drop", e => { [3]
        e.preventDefault()
        const dragevent = e
        if(dragevent.dataTransfer) {
          console.log(dragevent.dataTransfer, 'datatransfer')
          for ( let i=0; i < dragevent.dataTransfer.files.length; i++ ) {
            this.selectedFile = dragevent.dataTransfer.files[i];
            this.documents.push({
              files: this.selectedFile,
            });
          }

        }
      })
    }

  },
};
</script>
<style scoped>
.calendar-field {
  width: 20%;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}
</style>
