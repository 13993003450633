import axios from 'axios';

const RESSOURCE_NAME = 'commandes';

export default {

    getLitiges(column="numero_commande",order="DESC",page=0){
        return axios.get(`${RESSOURCE_NAME}/litiges`+`?page=`+page+`&size=`+process.env.VUE_APP_NB_DISPLAY+`&orderBy=`+column+`&sortBy=`+order+``)
    },

    getPretsClients(column="numero_commande",order="DESC",page=0, closed=0){
        return axios.get(`${RESSOURCE_NAME}/prets`+`?page=`+page+`&size=`+5+`&orderBy=`+column+`&sortBy=`+order+`&closed=`+closed+``)
    },

    getPretClient(numero_commande){
        return axios.get(`${RESSOURCE_NAME}/pret/${numero_commande}`)
    },

    createOrUpdatePret(data){
        return axios.post(`${RESSOURCE_NAME}/upsert`,data)
    },

    deletePretClient(numero_commande){
        return axios.delete(`${RESSOURCE_NAME}/pret/delete/${numero_commande}`)
    },

    getRappelsPret(id_entreprise, id_pret_client){
        return axios.get(`${RESSOURCE_NAME}/${id_entreprise}/rappels/${id_pret_client}`)
    },

    getLitige(numero_commande){
        return axios.get(`${RESSOURCE_NAME}/litige/${numero_commande}`)
    },

    addDescription(numero_commande, description_litige,service){
        return axios.post(`${RESSOURCE_NAME}/description/${numero_commande}`, {description_litige: description_litige,service:service})
    }

}