<template lang="">

<div>
        <!-------------------------------- CHRONOLOGIE DES ETAPES -------------------------------->
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            CHRONOLOGIE DES ETAPES
          </v-col>
        </v-toolbar>
      </v-col>


      <v-col cols="12" offset-sm="7" sm="2" class="d-flex align-center">
        <b>Commentaires</b>
      </v-col>
      <v-container v-for="etape in projet.etapes" :key="etape.id" >
        <v-row>
          <v-col cols="12" sm="2" class="d-flex align-center text-left">
            {{etape.libelle}}
          </v-col>

          <v-col cols="12" sm="1" class="d-flex align-center">
            <input v-if="mode !== 'visualisation'" type="checkbox" v-model="etape.isChecked" @click="getCurrentDate(etape)" style="width: 30px; height:20px;" /> 
            <span v-else class="text-left font-weight-bold"> {{ etape.isChecked ? "OUI" : "NON"}} </span>
          </v-col>

          <v-col cols="12" sm="2" class="d-flex align-center">
            <v-text-field  v-if="mode !== 'visualisation'" single-line outlined v-model="etape.date" hide-details dense ></v-text-field>
            <span v-else class="text-left font-weight-bold"> {{etape.date}}</span>
          </v-col>

          <v-col cols="12" sm="6" class="d-flex align-center">
            <!-- <v-text-field single-line outlined v-model="etape.commentaire" hide-details dense ></v-text-field> -->
            <v-textarea v-if="etape.id !== 6 && etape.id !== 7 && mode !== 'visualisation'" single-line outlined v-model="etape.commentaire" hide-details dense auto-grow row-height="5"></v-textarea>
            <span v-else class="font-italic  " style="word-wrap: anywhere; text-align: left;"><b v-if="etape.commentaire && etape.commentaire.length > 0"> "{{etape.commentaire}}"</b></span>
          </v-col>

        </v-row>  

        <!-- Outils specifiques -->
        <div v-if="etape.id == 6 && projet.showOutilsForm"  class="bloc_sub_item mt-10 mb-10 w-90">
          <div height="50px"  v-for="outil in projet.outils_specifiques" :key="outil.id">
              <v-row style="padding-left: 0.75rem;">
                  <v-col cols="12" sm="4" class="ml-10 text-left">
                    <div class="text-left">{{outil["description_outil"].libelle}}</div>
                    <v-textarea v-if="mode !== 'visualisation'" single-line outlined v-model="outil.description_outil.value" hide-details dense auto-grow row-height="5"></v-textarea>
                    <span v-else class="font-weight-bold" style="word-wrap: anywhere; text-align: left;">{{outil.description_outil.value}}</span>
                  </v-col>

                  <v-col cols="12" sm="2" class="d-flex align-center" v-for="label in projet.outils_specifiques_keys" :key="label">
                    <v-checkbox
                      v-model="outil[label].value"
                      :label="outil[label].libelle"
                      :disabled="mode == 'visualisation'"
                      light
                    >
                    
                    </v-checkbox>
                  </v-col>
              </v-row>
          </div>
                  <v-row  v-if="etape.id == 6 && projet.showOutilsForm">
          <v-col cols="12" sm="12" class="pl-0">
           <v-btn @click="addRow(type = 'outils_specifiques')" v-if="mode !== 'visualisation'" class="btnBold"  color="#FF9900" dark rounded> <v-icon left> fa fa-plus </v-icon> Ajouter une ligne</v-btn>
          </v-col>
        </v-row>
        </div>



        

        <!-- Outils specifiques -->


        <!-- Cartouches non stockées -->
        <div v-if="etape.id == 7 && projet.showCartouchesForm" class="bloc_sub_item mt-10 mb-10">
          <div height="50px"  v-for="cartouche in projet.cartouches_non_stockees" :key="cartouche.id">
                <v-row  style="padding-left: 0.75rem;">
                    <v-col cols="12" sm="4" class="ml-10  text-left">
                      <div class="text-left">{{cartouche["designation_code_walvoil"].libelle}}</div>
                      <v-textarea v-if="mode !== 'visualisation'"  single-line outlined v-model="cartouche.designation_code_walvoil.value" hide-details dense auto-grow row-height="5"></v-textarea>
                      <span v-else class="font-weight-bold" style="word-wrap: anywhere; text-align: left;">{{cartouche.designation_code_walvoil.value}}</span>
                    </v-col>

                    <v-col cols="12" sm="2" class="d-flex align-center" v-for="label in projet.cartouches_non_stockees_keys" :key="label">
                      <v-checkbox
                        v-model="cartouche[label].value"
                        :label="cartouche[label].libelle"
                        :disabled="mode == 'visualisation'"
                        light
                      >
                      
                      </v-checkbox>
                    </v-col>
                </v-row>
          </div>

          <v-row  v-if="etape.id == 7 && projet.showCartouchesForm">
            <v-col cols="12" sm="12" class="pl-0">
            <v-btn @click="addRow(type = 'cartouches_non_stockees')" v-if="mode !== 'visualisation'" class="btnBold"  color="#FF9900" dark rounded> <v-icon left> fa fa-plus </v-icon> Ajouter une ligne</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- Cartouches non stockées -->


      </v-container >

  

</div>

</template>
<script>
import moment from "moment";

export default {
  props: {
    projet: {
      type: Object,
      required: true,
    },
    mode: [String],
  },
  components: {},
  data() {
    return {
      rowOutilSpecifique: {},
      rowCartouche: {},
      // showOutilsForm: false,
      // showCartouchesForm: false,
    };
  },

  computed: {},
  watch: {
  },
  methods: {
    getCurrentDate(etape) {
      // alert(etape.isChecked)
      if (etape.isChecked) {
        etape.date = "";
      }else {
        etape.date = moment().format("L")
      }

      if ( etape.id == 6 ) {
        this.projet.showOutilsForm = !this.projet.showOutilsForm;
      }

      if ( etape.id == 7 ) {
        this.projet.showCartouchesForm = !this.projet.showCartouchesForm ;
      }
    },
    // setRowOutilSpecifique() {
    //   this.rowOutilSpecifique = {...JSON.parse(JSON.stringify(this.projet.outils_specifiques[0]))}
    //   for (const champs in this.rowOutilSpecifique){ 
    //     if ( champs !== "id") {
    //       this.rowOutilSpecifique[champs].value = typeof(this.rowOutilSpecifique[champs].value) === 'boolean'? false: "";
    //     }else {
    //       this.rowOutilSpecifique[champs] = this.projet.outils_specifiques.length + 1
    //     }
    //   }
    //   console.log( this.rowOutilSpecifique, "outil spe" )
    //   this.projet.outils_specifiques.push(this.rowOutilSpecifique)
    // },
    // setRowCartouche() {
    //   this.rowCartouche = {...JSON.parse(JSON.stringify(this.projet.cartouches_non_stockees[0]))}
    //   for (const champs in this.rowCartouche){ 
    //     if ( champs !== "id") {
    //       this.rowCartouche[champs].value = typeof(this.rowCartouche[champs].value) === 'boolean'? false: "";
    //     }else {
    //       this.rowCartouche[champs] = this.projet.cartouches_non_stockees.length + 1
    //     }
    //   }
    //   console.log( this.rowCartouche, "outil spe" )
    //   this.projet.cartouches_non_stockees.push(this.rowCartouche)
    // },

    setRow(rowType, arrayType) {
      for (const champs in rowType){ 
        if ( champs !== "id") {
          rowType[champs].value = typeof(rowType[champs].value) === 'boolean'? false: "";
        }else {
          rowType[champs] = arrayType.length + 1
        }
      }
      console.log( rowType, "row" )
      arrayType.push(rowType)
    },
    addRow(type) {
      let rowType = {};
      let arrayType = [];
      if ( type == "outils_specifiques") {
        rowType = {...JSON.parse(JSON.stringify(this.projet.outils_specifiques[0]))}
        arrayType = this.projet.outils_specifiques;
      }
      if ( type == "cartouches_non_stockees") {
        rowType = {...JSON.parse(JSON.stringify(this.projet.cartouches_non_stockees[0]))}
        arrayType = this.projet.cartouches_non_stockees;

      }
      
      this.setRow(rowType, arrayType);
    }
  },
  async created() {
    // this.showOutilsForm = this.projet.etapes.find(obj => obj.id === 6 && obj.isChecked == true) ? true : false;
    // this.showCartouchesForm = this.projet.etapes.find(obj => obj.id === 7 && obj.isChecked == true) ? true : false;
    // montrer la liste si case déjà coché 
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.font_color {
  font-weight: bold;
  color: #ffffff;
}

.bloc_sub_item {
  background-color: #cfeffc;
  border-radius: 9px;
  width: 92%;

}
</style>
