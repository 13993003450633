<template>
  <div>
    <!-- <img src="https://img.icons8.com/doodle/48/000000/building--v1.png" class="cluster-img"/> -->
    <p class="cluster-text">{{ getNumber() }}</p>
  </div>
</template>

<script>
export default {
  props: {
    clusterUsers: {
      type: Array,
      required: true
    },
  },
  methods: {
    getNumber () {
      return this.$props.clusterUsers.length
    },
  }
}
</script>

<style>
.mycluster {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #3498db;
    color: white;
    text-align: center;
    font-size: 20px;    
    line-height: 40px;
    margin-top: -20px;
    margin-left: -20px;
} 
</style>