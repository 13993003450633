<template>
  <div>
    <v-card v-if="renderComponent" class="box">
      <img :class="{ mdpForget: display }" src="@/assets/logoFta.png" alt="" />
      <div v-if="display == false">
        <form>
          <v-row no-gutters class="input_login">
            <v-col cols="12" id="email">
              <v-text-field
                v-model="user.email"
                outlined
                :rules="[rules.email]"
                label="identifiant"
                type="email"
                class="input color_input v-label--active v-label--is-focused"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="input_login">
            <v-col cols="12" id="password">
              <v-text-field
                v-model="user.password"
                outlined
                hide-details="auto"
                :rules="[rules.required]"
                label="mot de passe"
                type="password"
                class="input color_input"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            :class="{ 'mt-0': err.length > 0 }"
            class="mt-3 mb-6"
          >
            <v-col cols="12" class="text-right">
              <v-btn class="forget" :elevation="0" @click="display = true"
                >mot de passe oublié ?</v-btn
              >
            </v-col>
          </v-row>

          <p v-if="err.length > 0" class="error_back">
            {{
              this.err[0] != "" &&
              this.err[0] != "mauvais identifiant / mot de passe"
                ? this.err[0]
                : ""
            }}
            {{
              this.err[0] == "mauvais identifiant / mot de passe"
                ? "L'identifiant ou le mot de passe est incorrect"
                : ""
            }}
          </p>
          <v-row class="text-center">
            <v-col class="text-center">
              <v-btn
                :elevation="0"
                rounded
                class="button button_orange font-weight-bold"
                dark
                @click="login"
              >
                Se connecter
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </div>
      <div v-else>
        <form>
          <v-row class="input_forgetPassword d-flex row justify-center">
            <v-col cols="12">
              <div class="color_input text_align">
                Vous avez oublié votre mot de passe ?
              </div>
              <div class="text_align mb-2 mb-xl-5 mb-lg-3 mb-md-5 mb-xs-2">
                Saisissez votre adresse email ci-dessous et cliquez sur
                continuer pour obtenir un nouveau mot de passe.
              </div>
            </v-col>
            <v-col cols="12" sm="12" no-gutters id="password">
              <v-text-field
                v-model="user.email"
                outlined
                :rules="[rules.email]"
                label="adresse email"
                type="email"
                id="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <a               
                class="mr-4 btnCancel"
                dark
                @click="display= false,err=[]"
              >
                Annuler
              </a>
              <v-btn
                :elevation="0"
                rounded
                class="button button_orange font-weight-bold"
                dark
                @click="lostPassword()"
              >
                Continuer
              </v-btn>
              <p class="error--text mt-4" v-if="err.length > 0">{{ err[0] }}</p>
            </v-col>
          </v-row>

          <v-row>
            <div class="green">
              {{ hasErrorEmailforgetPassword }}
            </div>
          </v-row>
        </form>
      </div>
    </v-card>
    <div class="barre_bleue"></div>
  </div>
</template>
<script>
import api from "../../api/user";

export default {
  name: "FormAuth",
  props: ["user", "err"],
  data() {
    return {
      labelPosition: "on-border",
      hasErrorEmail: false,
      hasErrorPassword: false,
      display: false,
      hasErrorEmailforgetPassword: "",
      renderComponent: true,
      rules: {
        email: (v) =>
          !!(v || "").match(/@/) || "Merci d'indiquer votre mail",
        length: (len) => (v) =>
          (v || "").length >= len ||
          `Invalid character length, required ${len}`,
        password: (v) =>
          !!(v || "").match(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
          ) ||
          "Password must contain an upper case letter, a numeric character, and a special character",
        required: (v) => !!v || "Merci d'indiquer votre mot de passe",
      },
    };
  },
  methods: {
    login: function () {
      console.log(this.user.email);
      this.$emit("login", { user: this.user });
      // console.log("erreur", this.err);
      // this.hasErrorEmail = true;
      // if (
      //   this.err[0] == "L'identifiant ne peut pas être vide" ||
      //   this.err[0] == "Les champs ne peuvent pas être vides"
      // ) {
      //   this.hasErrorEmail = true;
      // } else {
      //   this.hasErrorEmail = false;
      // }
      // if (
      //   this.err[0] == "Le mot de passe ne peut pas être vide" ||
      //   this.err[0] == "Les champs ne peuvent pas être vides"
      // ) {
      //   this.hasErrorPassword = true;
      // } else {
      //   this.hasErrorPassword = false;
      // }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    lostPassword() {
      console.log(this.user.email, "id user");
      api.lostPassword(this.user).then((res) => {
        this.hasErrorEmailforgetPassword = res.data.message + " par email";
        if (res.data.message == "Un mail avec votre nouveau mot de passse vous a été envoyé") {
          setTimeout(() => {
            this.display = false;
            this.hasErrorEmailforgetPassword = "";
          }, 3000);
        }
      }).catch(error => {
        if (
              401 === error.response.status ||
              400 === error.response.status ||
              500 === error.response.status
            ) {
              // erreur sur le login depuis le back
              this.err.splice(0, 1);
              this.err.push(error.response.data.message);
              console.log(this.err);
            }
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row {
  margin: 0;
}

.error_back {
  color: red;
  font-size: 0.75rem;
}

.box {
  padding: 25px;
  position: relative;
  margin-bottom: 0;
  border-radius: 25px 25px 0px 0px !important;
}

.v-text-field__slot label {
  color: #0b87ba !important;
  font-weight: bold;
}
::v-deep .v-label.theme--light.error--text {
  color: #004196 !important;
}

.box {
  padding: 25px;
  position: relative;
  margin-bottom: 0;
  border-radius: 25px 25px 0px 0px !important;
}
.text_align {
  text-align: left;
  font-size: 0.75rem;
}

@media screen and (max-width: 1024px) {
  .col-6 {
    flex: 0 0 80%;
    max-width: 100%;
  }
  .box {
    height: 105%;
  }
  .input_login {
    justify-content: center;
  }
}
.barre_bleue {
  background-color: #2887b9;
  height: 9px;
  box-shadow: 0px 8px 0px #c2d8df;
}
.color_input {
  color: #004196;
}

img {
  width: 80%;
  padding-top: 5%;
  padding-bottom: 15%;
}
.button_orange {
  background-color: #ff9900 !important;
  color: aliceblue;
  padding: 0 30px !important;
}

.button:hover,
.button.is-hovered {
  border-color: aliceblue;
  color: aliceblue;
}

.forget {
  color: #004196;
  text-decoration: underline;
  font-size: 0.8em;
  text-transform: none;
  letter-spacing: normal;
}
.btnCancel{
  color: #004196;
}
button.button.is-rounded.is-hovered {
  margin-top: 5%;
}
.input_forget {
  padding-top: 2%;
}

::v-deep .label {
  color: #004196;
  font-weight: bold;
}
::v-deep .v-input__control {
  border-color: #004196 !important;
  color: #004196;
}
::v-deep label.v-label.v-label--active.theme--light,::v-deep label.v-label{
  color: #004196!important;
  filter: brightness(1); 
  font-size: 1.3em;
  line-height: 16px;
}

::v-deep label.v-label{
  transform-origin: top left!important;

	transform: translateY(-24px) scale(.75);

}
/* ::v-deep .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
	border-color: #004196!important;
} */
#email ::v-deep .v-input--is-focused.v-text-field--outlined fieldset legend,#email ::v-deep .v-text-field--outlined fieldset legend{
  width: 85px !important;
}
#password ::v-deep .v-input--is-focused.v-text-field--outlined fieldset legend,#password ::v-deep .v-text-field--outlined fieldset legend  {
  width: 105px !important;
}

/* ::v-deep .v-text-field--outlined fieldset {
  top: -6px;
} */
::v-deep
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border-color: #dcdcdc;
}
::v-deep.theme--light.v-text-field--filled
  > .v-input__control
  > .v-input__slot {
  background: white;
}
::v-deep.theme--light.v-btn.v-btn--has-bg {
  background-color: transparent;
}

.green {
  padding-top: 5%;
  color: #3ec487;
  background-color: white !important;
}
.error_back {
  padding-top: 4%;
  padding-bottom: 4%;
}
.mdpForget {
  padding-bottom: 9%;
}
@media screen and (max-width: 375px) {
  .box {
    padding: 5px;
  }
}

@media screen and (max-width: 320px) {
  .text_align {
    font-size: 0.65rem;
  }
}
</style>
