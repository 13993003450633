<template lang="">
    <div id="rapports">
  <v-col cols="12" lg="12" xl="12">
    <v-container class="padd_top" fluid>
      <v-row no-gutters>
        
      <v-col cols="12" lg="12" xl="12">
        <v-toolbar color="#55ABCF" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            RAPPORTS
          </v-col>
          <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0 text-right">


            <!-- <v-toolbar-title class="rectangle_blanc"> -->



              <router-link v-if="canModif == 1" id="addBtn"  :to="{ name: 'add_rapport', params: { id_entreprise: $route.params.id_entreprise }}">
            <v-btn
                class="btnBold"
                color="#FF9900"
                dark
                rounded               
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>               
          AJOUTER UN RAPPORT
            </v-btn>
            </router-link>
            <!-- </v-toolbar-title> -->
          </v-col>
        </v-toolbar>
      </v-col>
      
      </v-row>
              <Popup :dialog="dialogDelete" text="Supprimer ce rapport ?" actions="" heightProp="" widthProp="" showClose="false"> 
                <v-card-actions class="mb-10">
                  <v-btn class="actions" color="#0B87BA" width="100px"  large dark rounded @click="deleteItemConfirm">Oui</v-btn>
                  <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialogDelete = false">Non</v-btn>
                </v-card-actions> 
              </Popup>
      <v-row align="center">
        <v-col cols="12" lg="12" xl="12">
          <resultList
            @emitDialogRapport="dialogDelete = true, id_rapport = $event"
            :header="header"
            :items="rapports"
            :nbResult="displayItem"
            :type="'rapports'"
            @sort="sort"
            :totalItems="totalItems"
            :page="page"
            id="encours"
            :canModif="canModif"
          />
        </v-col>
        <v-col v-if="nbPage>0" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-if="nbPage > 1"
              v-model="page"
              :length="nbPage"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>

        </v-col>  
      </v-row>
    </v-container>
    </v-col>
    </div>
</template>
<script>
import Popup from "@/components/shared/Popup";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/entreprise";
import apiRapport from "@/api/rapport";

export default {
  components: { ResultList, Popup },
  data() {
    return {
      id: this.$route.params.id_entreprise,
      displayItem: process.env.VUE_APP_NB_DISPLAY,
      rapports: [],
      id_rapport: 0,
      dialogDelete: false,
      page: 1,
      column: "date_rapport",
      order: "DESC",
      nbPage: 0,
      query: "",
      totalItems: 0,
      usersEntreprise:[],
      canModif:0,
      header: [
        {
          text: "DATE",
          align: "center",
          sortable: true,
          value: "date_rapport",
          name: "date_rapport",
        },
        {
          text: "APPEL",
          align: "center",
          value: "appel",
          name: "check",
        },
        {
          text: "VISITE",
          align: "center",
          value: "visite",
          name:"check"
        },
        {
          text: "ACTION À RÉALISER",
          align: "center",
          sortable: false,
          value: "action_a_realiser",
          name: "action_a_realiser",
        },
        {
          text: "PERSONNE RENCONTRÉES",
          align: "center",
          sortable: false,
          value: "personnes_rapport",
        },
        {
          text: "DESCRIPTION",
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: "FICHIERS JOINTS",
          align: "center",
          sortable: false,
          value: "documents",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actionsRapportEntreprise",
        },
      ],
    };
  },
  methods: {

    getEntrepriseRapports(query,id_client, page, column, order) {
      api
        .getEntrepriseRapports(query,id_client, page, column, order)
        .then((res) => {
          // console.log("1er  consolle ",res.data.rapports.rows["0"]["Rapports"])
          // for (let index = 0; index <res.data.rapports.rows["0"]["Rapports"].length; index++) {
          //   const element = res.data.rapports.rows["0"]["Rapports"][index];
          //   console.log("ellooo",element)
          //   this.rapports.push({date_rapport : element.description}) 
          // }
          this.rapports = res.data.rapports.rows;
           this.nbPage = res.data.rapports.totalPages;
          this.totalItems = res.data.rapports.totalItems;
          console.log("this rapport", this.rapports)
     
            res.data.entreprise.Users.forEach(user => {
              this.usersEntreprise.push(user.id_utilisateur)
            })
            console.log('usersEntreprise remplo')
        this.CanModified()

          this.rapports.forEach(rapport => {
            rapport.Documents.forEach((doc) => {
            doc["chemin"] = process.env.VUE_APP_BASE_URL + doc.chemin;
            console.log(doc)
            })
          })
          
          
          // res.data.rapports.rows["0"]["Rapports"].forEach(element => {
          //   console.log("blablabl",element.date_rapport)
          //   this.rapports.push({date_rapport : element.description})
          // });
        }).catch((error) => {console.log(error)
        if ( error.response.status == 500||error.response.status == 400 ){
                console.log(error.response.data,'error.response')
                  if(error.response.data.entreprise.Users.length > 0){
                error.response.data.entreprise.Users.forEach(user => {
                  this.usersEntreprise.push(user.id_utilisateur)
                })
            }
            console.log('hhhh')
          this.CanModified()
              }
      });
    },
    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getEntrepriseRapports(
        this.query,
        this.id,
        this.page - 1,
        this.column,
        this.order
      );
    },
    CanModified(){
      if(
      (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role)== 1 || this.usersEntreprise.includes(this.$store.state.AsUser.id_utilisateur == '' ?this.$store.state.user.id_utilisateur : this.$store.state.AsUser.id_utilisateur) )
      {
        this.canModif = 1
      }
      console.log(this.canModif,'can modif');
    },
    deleteItemConfirm() {
      apiRapport.deleteRapport(this.id_rapport).then((res) => {
        console.log(res)
        this.getEntrepriseRapports(
          this.query,
          this.id,
          this.page - 1,
          this.column,
          this.order
        );
        this.dialogDelete = false
      });
    }
  },
  created() {
    this.getEntrepriseRapports(
      this.query,
      this.id,
      this.page - 1,
      this.column,
      this.order
    );
  },
};
</script>
<style lang="scss" scoped>
.btnBold {
  font-weight: bold;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}
#addBtn{
  text-decoration: none;
}
</style>