<template>
  <div>
    <v-row id="title" align="center">
      <v-col cols="1" xl="1" class="retour">
        <v-btn @click="$router.go(-1)">
          <v-icon left>fa-caret-left</v-icon>RETOUR
        </v-btn>
      </v-col>
      <v-col align-self="center" cols="5">
        <h1>{{ titlePage.toUpperCase() }}</h1>
      </v-col>
      <v-col v-if="false" class="pt-0 pb-0" cols="5">
        <v-container class="d-flex pt-0 pb-0">
          <v-btn
            class="btnBold transforClient"
            :elevation="0"
            color="#FF9900"
            dark
            rounded
          >
            <div class="">TRANSFORMER EN CLIENT</div>
          </v-btn>
        </v-container>
      </v-col>

      <v-col
        v-if="this.$route.name == 'client'"
        align-self="center"
        class="desactiver_client"
        cols="5"
      >
        <v-row justify="end">
          <v-btn
            v-if="
              currentTab == 0 &&
              (this.$store.state.AsUser.id_utilisateur == ''
                ? this.$store.state.user.id_role
                : this.$store.state.AsUser.id_role == 1 ||
                  users.includes(
                    this.$store.state.AsUser.id_utilisateur == ''
                      ? this.$store.state.user.id_utilisateur
                      : this.$store.state.AsUser.id_utilisateur
                  ))
            "
            class="modifier_client"
            :elevation="0"
            @click="toModifier()"
          >
            <i class="fas fa-user-edit"></i>
            <div class="font_btn pl-2">Modifier ce client</div>
          </v-btn>
          <v-btn
            v-if="
              currentTab == 0 &&
              this.id_statut_entreprise == 2 &&
              (this.$store.state.AsUser.id_utilisateur == ''
                ? this.$store.state.user.id_role
                : this.$store.state.AsUser.id_role == 1 ||
                  users.includes(
                    this.$store.state.AsUser.id_utilisateur == ''
                      ? this.$store.state.user.id_utilisateur
                      : this.$store.state.AsUser.id_utilisateur
                  ))
            "
            class="transformation_client"
            :elevation="0"
            @click="dialogPopup = true"
          >
            <div class="font_btn pl-2">Transformer en client</div>
          </v-btn>
          <v-btn
            v-if="
              currentTab == 0 &&
              $store.state.entreprise.actif &&
              (this.$store.state.AsUser.id_utilisateur == ''
                ? this.$store.state.user.id_role
                : this.$store.state.AsUser.id_role == 1 ||
                  users.includes(
                    this.$store.state.AsUser.id_utilisateur == ''
                      ? this.$store.state.user.id_utilisateur
                      : this.$store.state.AsUser.id_utilisateur
                  ))
            "
            class="disable_client"
            :elevation="0"
            @click="dialog = true"
          >
            <i class="fas fa-times"></i>
            <div class="font_btn">Désactiver ce client</div>
          </v-btn>
          <v-btn
            v-if="
              currentTab == 0 &&
              !$store.state.entreprise.actif &&
              (this.$store.state.AsUser.id_utilisateur == ''
                ? this.$store.state.user.id_role
                : this.$store.state.AsUser.id_role == 1 ||
                  users.includes(
                    this.$store.state.AsUser.id_utilisateur == ''
                      ? this.$store.state.user.id_utilisateur
                      : this.$store.state.AsUser.id_utilisateur
                  ))
            "
            class="active_client"
            :elevation="0"
            @click="dialogActive = true"
          >
            <i class="fas fa-times"></i>
            <div class="font_btn">Activer ce client</div>
          </v-btn>
        </v-row>

        <!-- <v-dialog v-model="dialog" persistent max-width="615">
          <v-card class="card_border">
            <v-card-text class="color_blue padd_modal font_modal">
              <p>Êtes-vous sûr de vouloir désactiver ce client ?</p>
            </v-card-text>
            <v-card-actions
              class="d-flex justify-center flex-column align-center"
            >
              <div class="">
                <v-btn
                  class="btn_choice mr-4"
                  rounded
                  text
                  @click="archiver(idClientEnCours)"
                  dark
                >
                  OUI
                </v-btn>
                <v-btn class="btn_choice" rounded text @click="dialog = false">
                  NON
                </v-btn>
              </div>
              <div class="green">
                {{ message }}
              </div>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <Popup
          :dialog="dialog"
          :text="desactiveEntreprise"
          :actions="actionsActive"
          @transform="archiver(idClientEnCours)"
          @nothing="dialog = false"
          class="popup"
        ></Popup>

        <Popup
          :dialog="dialogPopup"
          :text="messageCreation"
          :actions="actions"
          @transform="transform"
          @nothing="dialogPopup = false"
          class="popup"
        >
          <div class="popup-content">
            <div class="text-left">CODE FTA* :</div>
            <v-text-field
              type="text"
              v-model="code_client_fta"
              hide-details
            ></v-text-field>
            <div
              class="errorMsg text-left red--text mt-3 mb-3"
              v-if="$v.code_client_fta.$error"
            >
              Renseignez le code client FTA
            </div>
          </div>
        </Popup>

        <Popup
          :dialog="dialogActive"
          :text="ActiveEntreprise"
          :actions="actionsActive"
          @transform="archiver(idClientEnCours)"
          @nothing="dialogActive = false"
          class="popup"
        ></Popup>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from "../../api/entreprise";
import Popup from "./../shared/Popup";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Popup,
  },
  name: "titlePage",
  props: [
    "titlePage",
    "idClientEnCours",
    "currentTab",
    "users",
    "id_statut_entreprise",
  ],
  data() {
    return {
      code_client_fta: "",
      dialog: false,
      message: "",
      dialogPopup: false,
      dialogDesactiveClient:false,
      messageCreation: "Avez-vous créer le client sur Navision ?",
      actions: {
        actions: [
          {
            btn: "OUI",
            event: "transform",
          },
          {
            btn: "NON",
            event: "nothing",
          },
        ],
      },
      dialogActive: false,
      ActiveEntreprise: "Souhaitez vous activer ce client ?",
      actionsActive: {
        actions: [
          {
            btn: "OUI",
            event: "transform",
          },
          {
            btn: "NON",
            event: "nothing",
          },
        ],
      },
      desactiveEntreprise: "Ëtes vous sur de vouloir désactiver ce client"
    };
  },

  validations: {
    code_client_fta: { required },
  },

  created() {
    console.log(this.$route.name, "route name");
  },
  methods: {
    transform() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        api
          .getProspectToClient(
            this.$route.params.id_entreprise,
            this.code_client_fta
          )
          .then((res) => {
            this.$emit("transformClient", res.data.entreprise);
            this.dialogPopup = false;
          });
      }
      console.log(this.code_client_fta, "oui");
    },
    toModifier() {
      this.$router.push({
        name: "modifier_client",
        params: { id_entreprise: this.$route.params.id_entreprise },
      });
    },
    archiver(id) {
      api.archiverEntreprise(id).then((res) => {
        this.dialogActive = false;
        this.dialog = false;
        this.$store.commit(
          "upadateActifEntreprise",
          !this.$store.state.entreprise.actif
        );
        this.message = res.data.message;
        // setTimeout(() => {
        //   (this.dialog = false), (this.message = "");
        // }, 2000);
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
#title {
  background-color: $background-color-blue;
  text-align: left;
  margin-right: 0;
}

.popup {
  display: flex;
  flex-direction: column;
}

.popup-content {
  order: -1;
  line-height: 5px;
  width: 50%;
  padding-top: 10px;
}

.retour {
  padding-left: 0;
  .v-btn.v-btn--has-bg {
    background-color: #55aacf;
    color: #fff;
    font-size: 1.1em;
    font-weight: bold;
    border-radius: 0 40px 40px 0;
  }
}
h1 {
  padding-left: 80px;
  color: #fff;
  font-size: 2em;
  font-weight: 900;
}
.color_red {
  color: #ec0403;
  background-color: #fff;
  border-radius: 10px;
  border-color: #ec0403;
}
.disable_client {
  background-color: #ec0403 !important;
  border-radius: 60px;
  font-weight: bold;
}

.active_client {
  background-color: orange !important;
  border-radius: 60px;
  font-weight: bold;
}

.modifier_client {
  background-color: #61bb45 !important;
  border-radius: 60px;
  font-weight: bold;
}
.transformation_client {
  background-color: #fe9a00 !important;
  border-radius: 60px;
  font-weight: bold;
  margin-right: 10px;
}

button.modifier_client.v-btn.v-btn--has-bg.theme--light.elevation-0.v-size--default {
  margin-right: 10px;
}
button.disable_client.v-btn.v-btn--has-bg.theme--light.elevation-0.v-size--default {
  margin-right: 10px;
}

.theme--light.v-btn.v-btn--has-bg {
  color: white;
  font-size: 1rem;
}
.font_btn {
  font-size: 0.64vw;
  text-transform: none;
}
.color_blue {
  color: #0b87ba;
}
::v-deep .card_border.v-card.v-sheet.theme--light {
  border-radius: 22px !important;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
  ::v-deep span {
    letter-spacing: 0;
  }
}
.green {
  color: #3ec487;
  background-color: white !important;
}
.padd_modal {
  padding: 60px 0 0 0 !important;
}

.font_modal {
  font-size: initial;
}
.v-dialog > .v-card > .v-card__actions {
  padding: 0px 0px 50px 0 !important;
}
.btnBold {
  font-weight: bold;
}
::v-deep
  button.btnBold.transforClient.v-btn.v-btn--has-bg.v-btn--rounded.theme--dark.elevation-0.v-size--default {
  height: 46px;
}
.desactiver_client.col.col-5.align-self-center {
  max-width: 100%;
  flex: 1;
}
img.logo {
  padding-right: 10px;
}
svg.svg-inline--fa.fa-user-edit.fa-w-20 {
  width: 2em;
}
svg.svg-inline--fa.fa-times.fa-w-11 {
  padding-right: 10px;
  height: 20px;
  width: 26px;
}
</style>
