<template>
    <div id="retards">
        
        <Title :titlePage="titlePage" />
    <v-container fluid>
    <v-row align="center mt-10">
      <v-col cols="12" lg="12" xl="10">
        <resultList
      :header="headerRetards"
      :items="retards"
      :nbResult="10"
      :type="'retards'"
      @sort="sort"
      :totalItems="totalItems"
      :page="page"
    />
    </v-col>
    <v-col v-if="totalPages>1" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>

        </v-col>  
        </v-row>
        </v-container>
    </div>
</template>

<script>

import Title from "@/components/shared/Title";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/retards.js";

export default {
    name: "retards",
    components: { Title, ResultList },
    data() {
        return {
        titlePage: "Retards de Commandes",
        retards: [],
        totalItems: 0,
        totalPages:0,
        page: 1,
        headerRetards: [

            {
            text: "RAISON SOCIALE",
            align: "center",
            sortable: true,
            value: "raison_sociale",
            },
            {
            text: "NUMERO DE COMMANDE",
            align: "center",
            sortable: true,
            value: "numero_commande",
            },
            {
            text: "MONTANT",
            align: "center",
            sortable: false,
            value: "montant",
            name: "montant",
            },
        ],
        };
    },
    methods: {
        getRetards(id = null, column, order, page) {
            api.getRetards(id, column, order, page).then((res) => {
                this.retards = res.data.retards.rows;
                this.totalItems = res.data.retards.totalItems;
                this.totalPages = res.data.retards.totalPages;
                console.log(this.retards)
            })
        },
        sort($event) {
        if ($event.column != undefined) {
            console.log($event.column)
            this.column = $event.column;
        }
        if ($event.order != undefined) {
            this.order = $event.order ? "ASC" : "DESC";
        }
        this.page = $event.page;
        this.getRetards(
            this.$store.state.AsUser.id_utilisateur ? this.$store.state.AsUser.id_utilisateur : "",
            this.column,
            this.order,
            this.page - 1
            
        );
        // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
        },
    },
    created() {
        this.getRetards( this.$store.state.AsUser.id_utilisateur ? this.$store.state.AsUser.id_utilisateur : "");
    }
}
</script>
