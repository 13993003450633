<template lang="">
  <div>

          
            <v-sheet
      tabindex="0"
      width="100%"
      class="pa-2"
      height="300"
      ref="dropzone"
    > 
 <div class="text-left pt-12" v-if="documents.length == 0">
        Aucune pièce jointe n'a été ajoutée.
      </div>
      <v-row> 
        <v-col cols="2"  v-for="(document, index) in documents" v-bind:key="document.files.lastModified"  v-bind:name="document.files.name">
                    <p> <a
              :class="{ 'mr-1': index < documents.length - 1 }"
              :href="document.chemin"
              target="_blank"
            >
              <img
                v-if="document.files.name.split('.').pop() == 'pdf'"
                src="@/assets/pdf.png"
                alt=""
              />
              <img
                v-else-if="
                  document.files.name
                    .split('.')
                    .pop()
                    .substring(0, 3) == 'doc' ||
                    document.files.name
                      .split('.')
                      .pop()
                      .substring(0, 3) == 'txt'
                "
                src="@/assets/doc.png"
                alt=""
              />
              <img
                v-else-if="
                  document.files.name
                    .split('.')
                    .pop()
                    .substring(0, 3) == 'xls' ||
                    document.files.name
                      .split('.')
                      .pop()
                      .substring(0, 3) == 'csv'
                "
                src="@/assets/excel.png"
                alt=""
              />
              <img v-else src="@/assets/img.png" alt="" />
            </a></p>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <p v-if="document.files.name.length > 15" v-bind="attrs" v-on="on">{{document.files.name.substring(document.files.name.indexOf('-') + 1).substring(0, 15)}}</p>
                            <p v-else v-bind="attrs" v-on="on">{{document.files.name.substring(document.files.name.indexOf('-') + 1)}}</p>
                          </template>
                          <span>{{document.files.name.substring(document.files.name.indexOf('-') + 1)}}</span>

                        </v-tooltip>

                      
                                          <p>ajouté le : {{$moment(document.createdAt).format('DD/MM/YYYY')}}</p>
                    <p><v-btn v-if="mode !== 'visualisation'"  @click="deleteDocument(document, index, true)"><strong>Supprimer</strong></v-btn></p>
        </v-col>
      </v-row>
      </v-sheet>

      <!-- <v-list-item two-line v-if="documents.length>0">
        <v-list-item-content             
              v-for="(document, index) in documents"
              v-bind:key="document.files.lastModified"
              v-bind:name="document.files.name"
              v-on:remove="document.splice(index, 1)" cols="2">
          <v-list-item-title>
            <a
              :class="{ 'mr-1': index < documents.length - 1 }"
              :href="document.chemin"
              target="_blank"
            >
              <img
                v-if="document.files.name.split('.').pop() == 'pdf'"
                src="@/assets/pdf.png"
                alt=""
              />
              <img
                v-else-if="
                  document.files.name
                    .split('.')
                    .pop()
                    .substring(0, 3) == 'doc' ||
                    document.files.name
                      .split('.')
                      .pop()
                      .substring(0, 3) == 'txt'
                "
                src="@/assets/doc.png"
                alt=""
              />
              <img
                v-else-if="
                  document.files.name
                    .split('.')
                    .pop()
                    .substring(0, 3) == 'xls' ||
                    document.files.name
                      .split('.')
                      .pop()
                      .substring(0, 3) == 'csv'
                "
                src="@/assets/excel.png"
                alt=""
              />
              <img v-else src="@/assets/img.png" alt="" />
            </a>
            </v-list-item-title>
          <v-list-item-subtitle>
          {{document.files.name.substring(document.files.name.indexOf('-') + 1)}} 
          </v-list-item-subtitle>
          <v-list-item-subtitle><v-btn v-if="mode !== 'visualisation'"  @click="deleteDocument(document, index, true)"><strong>Supprimer</strong></v-btn></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item> -->


         <Popup :dialog="dialog" text="Supprimer ce document ?" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="false"> 
                     <v-card-actions class="mb-10">
          
              <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialog = false">Non</v-btn>
              <v-btn class="actions" color="#0B87BA" width="100px" large dark rounded @click="deleteDocument(document, index, false)">Oui</v-btn>
              
            </v-card-actions> 
        </Popup>
  

  </div>
</template>
<script>
import api_doc from "@/api/document";
import Popup from "@/components/shared/Popup";
export default {
  props: {
    documents: {
      type: Object,
      default: function () {
        return { message: 'hello' }
      }
    },
    mode: [String],
    currentDirectory: [String],
  },
  components: {Popup},
  data() {
    return {
      documentToDelete: null,
      indexToDelete: null,
      dialog: false,
      selectedFile: null,
      isSelecting: false,
    };
  },
  methods: {
    deleteDocument(document, index, open) {
      if (open == true) {
        this.documentToDelete = document;
        this.indexToDelete = index;
        this.dialog = true;
      }
      if (open == false) {
        this.documents.splice(this.indexToDelete, 1);
        if (this.documentToDelete.id_document !== undefined) {
          api_doc
            .deleteDocument(this.documentToDelete.id_document)
            .then((res) => {
              console.log(res);
            });
        }
        this.dialog = false;
      }
    },
  },
  mounted() {
      console.log("currentDirectory depuis uploaderFiles", this.currentDirectory)
      if ( this.currentDirectory == "mails_importants") {
        this.documents = this.documents.mails_importants
      }

      if ( this.currentDirectory == "schemas_hydrauliques") {
        this.documents = this.documents.schemas_hydrauliques
      }

      if ( this.currentDirectory == "photos") {
        this.documents = this.documents.photos
      }

      if ( this.currentDirectory == "offres_fournisseur") {
        this.documents = this.documents.offres_fournisseur
      }

      if ( this.currentDirectory == "_3d_2d") {
        this.documents = this.documents._3d_2d
      }

      if ( this.currentDirectory == "autres") {
        this.documents = this.documents.autres
      }

      this.$nextTick(function () {
        const dropzone = this.$refs.dropzone.$el;

        // register listeners on your dropzone / v-sheet
        if(dropzone) {
          // register all drag & drop event listeners
        
          dropzone.addEventListener("dragenter", e => { [2]
            e.preventDefault()
            this.dragover = true
          })
          dropzone.addEventListener("dragleave", e => { [2]
            e.preventDefault()
            this.dragover = false
          })
          dropzone.addEventListener("dragover", e => { [2]
            e.preventDefault()
            this.dragover = true
          })
          dropzone.addEventListener("drop", e => { [3]
            e.preventDefault()
            const dragevent = e
            if(dragevent.dataTransfer) {
              console.log(dragevent.dataTransfer, 'datatransfer')
              for ( let i=0; i < dragevent.dataTransfer.files.length; i++ ) {
                this.selectedFile = dragevent.dataTransfer.files[i];
                this.$emit('emitFile', this.selectedFile)
                // this.documents.push({
                //   files: this.selectedFile,
                // });
              }

            }
          })
        }
      })
      
  },
};
</script>
<style scoped>
.calendar-field {
  width: 20%;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

::v-deep .v-breadcrumbs__item {
	align-items: center;
	display: inline-flex;
	text-decoration: none;
	transition: .3s cubic-bezier(.25,.8,.5,1);
	color: blue;
}

.active {
  cursor: pointer;
  font-weight: bold;
}
::v-deep .v-breadcrumbs__divider{
  font-weight: bold;
  color: blue!important;
}
.disable{
  font-weight:normal!important
}
.v-breadcrumbs li .v-icon {
	font-size: 16px;
	color: blue;
}
::v-deep .linkProjet{
  list-style-type:square
 
}
.linkProjet a{
   
    color:#4B4B4B;
 
}
a div:nth-child(2){
  color:#4B4B4B;
  
}
a.linkProjet{
text-decoration:none;
}

/* .test {
  background-color: burlywood;
  width: 100%;
  display: inline !important;
}

.test2 {
  background-color: blue;
  width: 140px;
  height: 70px;
  display: block;

} */

.test3 {
  /* background-color: red; */
  font-size: x-small;
  /* width: 70px;
  height: 70px;
  display: block; */
}
</style>
