<template lang="">
<div>
  <div class="d-flex">
    <form @submit.prevent="submit" class="d-flex flex-column flex-grow-1">
      <Title :titlePage="titlePage" />
      <v-container>
        <v-row>
          <v-col cols="12" sm="10">
            <div class="rectangle_bleu">COORDONNÉES</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="10" class="pb-0">
            <div class="text-left">RAISON SOCIALE</div>
            <v-text-field label="" single-line outlined value="hello" v-model="raisonSociale" hide-details class="mb-3" ></v-text-field>
            <div class="errorMsg text-left red--text mb-3" v-if="$v.raisonSociale.$error">Merci d'indiquer la raison sociale</div>
          </v-col>
        </v-row>
        <v-row>
           <v-col cols="12" sm="10" class="pb-0 pt-8">
            <div class="text-left">Adresse</div>
              <v-autocomplete
                v-model="autocomplete"
                :loading="loadingSearch"
                :items="adresses"
                :search-input.sync="search"
                @keydown="isAddrModified = true"
                 outlined 
                hide-details
                item-text="label"
                 no-filter
                 cache-items
                return-object
                ref="auto"
                placeholder="27 Avenue Maurice Chevalier, 77330 Ozoir-la-Ferrière"
                @change="isAddrModified = true"
              ></v-autocomplete>
             
              <div class="errorMsg text-left red--text mb-3" v-if="approximate == true">L'adresse exacte n'a pas été trouvée.</div>
          </v-col>        
        </v-row>
                        <v-row v-if="approximate == true">
          <v-col cols="12" sm="4" class=" pt-8">
            <div class="text-left">ADRESSE*</div>
            <v-text-field single-line outlined v-model="adresseApproximate" hide-details class="mb-3"></v-text-field>
          </v-col>

          <v-col cols="12" sm="3" class="pt-8">
            <div class="text-left">VILLE </div>
            <v-text-field label="" single-line outlined filled readonly   type="email" v-model="villeApproximate" hide-details class="mb-3"></v-text-field>
          </v-col>

          <v-col cols="12" sm="3" class="pt-8">
            <div class="text-left">CODE POSTAL </div>
            <v-text-field label="" single-line outlined filled readonly  type="email" v-model="postalApproximate" hide-details class="mb-3"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class=" pt-8">
            <div class="text-left">TÉLÉPHONE</div>
            <v-text-field maxLength="10" label="+33" single-line outlined v-model="telephone" hide-details class="mb-3"></v-text-field>
            <div class="errorMsg text-left red--text mb-3" v-if="!$v.telephone.required && $v.telephone.$error">Merci d'indiquer le téléphone  de l'entreprise</div>
            <div class="errorMsg text-left red--text mb-3" v-if="(!$v.telephone.maxLength || !$v.telephone.minLength || !$v.telephone.numeric)  && $v.telephone.$error">Merci d'indiquer un téléphone valide</div>
          </v-col>
          <v-col cols="12" sm="4" class="pt-8">
            <div class="text-left">EMAIL</div>
            <v-text-field label="" single-line outlined  type="email" v-model="email" hide-details class="mb-3"></v-text-field>
            <div class="errorMsg text-left red--text mb-3" v-if="!$v.email.required && $v.email.$error">Merci d'indiquer l'email de l'entreprise</div>
            <div class="errorMsg text-left red--text mb-3" v-if="!$v.email.email && $v.email.$error">Merci d'indiquer un email valide</div>
          </v-col>
        </v-row>
          <v-row>
          <v-col cols="12" sm="3" class=" pt-8" id="prospect">
            <div class="text-left">PROSPECT VISITE</div>
            <v-radio-group v-model="prospect_visite" row >
            <!-- <v-col cols="12" sm="12" class=""> -->
              <v-radio label="OUI" :value="1"></v-radio>
              <v-radio label="NON" :value="0"></v-radio>
              <!-- <div></div> -->
            <!-- </v-col> -->
          </v-radio-group>
           
          </v-col>

                    <v-col cols="12" sm="4" class=" pt-8" id="nb_visites_prevues">
            <div class="text-left">NOMBRE DE VISITES PREVUES</div>
            <v-text-field label="" single-line outlined v-model="nb_visites_prevues" hide-details class="mb-3"></v-text-field>
            <div class="errorMsg text-left red--text mb-3" v-if="!$v.nb_visites_prevues.numeric && $v.nb_visites_prevues.$error">Merci de renseigner une valeur numérique</div>
           
          </v-col>
        </v-row>


        <v-row>
          <v-col cols="12" sm="10" class="pt-8">
            <div class="text-left">DESCRIPTION</div>
                       <v-textarea outlined class="rectangle_box mt-3" v-model="description_activite" placeholder="Commentaire"> </v-textarea>

          </v-col>
        </v-row>








        <v-row>
          <v-col cols="10" sm="10">
            <div class="rectangle_bleu">TYPE DE PROSPECT</div>
          </v-col>
        </v-row>
        <v-container class="pl-7" fluid>
          <v-radio-group v-model="typeProspect">
            <v-col cols="12" sm="12" class="pt-0 pb-0 d-flex row">
              <v-radio label="REVENDEUR" value="REVENDEUR"></v-radio>
              <v-radio label="CONSTRUCTEUR" value="CONSTRUCTEUR"></v-radio>
              <v-radio label="UTILISATEUR" value="UTILISATEUR"></v-radio>
              <div></div>
            </v-col>
          </v-radio-group>
          <div class="errorMsg text-left red--text mb-3" v-if="$v.typeProspect.$error">Merci d'indiquer un type de prospect</div>
        </v-container>
        <v-row>
          <v-col cols="12" sm="10">
            <div class="rectangle_bleu">POTENTIEL D'ACHAT</div>
          </v-col>
        </v-row>
        <v-container class="pl-7" fluid>
          <v-radio-group v-model="potentielAchat">
            <v-col cols="12" sm="12" class="pt-0 d-flex row">
              <v-radio label="0€ (INACTIF)" value="0 (inactif)"></v-radio>
              <v-radio label="MOINS DE 10 K €" value="10K"></v-radio>
              <v-radio label="ENTRE 10 ET 30 K €" value="10-30K"></v-radio>
              <v-radio label="PLUS DE 30 K €" value="30K"></v-radio>
              <div></div>
            </v-col>
          </v-radio-group>
          <div class="errorMsg text-left red--text mb-3" v-if="$v.potentielAchat.$error">Merci d'indiquer un potentiel d'achat</div>
        </v-container>
        <v-row>
          <v-col cols="12" sm="10">
            <div class="rectangle_bleu">CRITÈRE D'ACHAT</div>
          </v-col>
        </v-row>
        <v-row class="alignement_input px-0">
          <v-col cols="10" class="pa-0">
            <div v-for="i in criteresDachat" :key="i.id" class="d-flex">
              <v-col cols="12" sm="6" md="12" class="pa-2 pl-4 pr-6">
                <div class="pl-16">{{ i.txt.toUpperCase()  }}</div>
                <v-text-field
                  v-model="valuesCritereAchats[i.id]"
                  placeholder="Commentaire"
                ></v-text-field>
              </v-col>
            </div>
          </v-col>
          <div class="errorMsg text-left red--text mb-3" v-if="critereAchatError">Merci d'indiquer au moins un critère d'achat</div>
        </v-row>
        <v-row>
          <v-col cols="10" sm="10">
            <div class="rectangle_bleu">
              FREINS ET ATOUTS DE FTA POUR FIDÉLISER OU ACQUÉRIR CE CLIENT
            </div>
          </v-col>
        </v-row>
        <v-container class="" fluid>
          <v-row>
            <v-col cols="10" class="pl-0 pr-1 alignement_input pt-6">
              <v-textarea outlined class="rectangle_box" v-model="freins_et_atouts" placeholder="Commentaire"> {{ freins_et_atouts }}</v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col cols="10" sm="10" class="pb-0"> <div class="rectangle_bleu"> ACHATS HYDRAULIQUES </div> </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="align-center pt-0">
            <resultList 
              :header="headerAchats" 
              :items.sync="achatshydraulique" 
              :nbResult="1" @dataChange="updateAchatHydraulique"
            />
          </v-col>
          <div class="errorMsg text-left red--text mb-3" v-if="achatHydrauliqueError">Merci d'indiquer au moins un achat hydraulique</div>
        </v-row>
        <v-row>
          <v-col cols="10" sm="10"> <div class="rectangle_bleu"> COMMERCIAUX </div> </v-col>
        </v-row>
        <v-row class="px-0 pl-16">
          <v-col cols="10" class="pa-0  flex-container flex-column wrap align_commerciau" >
            <v-row>

              
            <v-col v-for="i in commerciaux" :key="i.commerciaux" class="" cols="2">
              <!-- <v-col cols="2" class="pa-0" > -->
                <v-checkbox @change="nbcommerciaux" color="info" v-model="i.value" :label="i.nom" hide-details dense />
                <!-- <div>{{ i.nom}}</div> -->
              <!-- </v-col> -->
            </v-col>
          </v-row>
          </v-col>
            <div class="errorMsg text-left red--text mt-5 mb-3" v-if="nbcommerciauxSelected == 0" >Merci d'indiquer au moins un commercial</div>
        </v-row>

        <v-row class="text-center pt-12">
          <v-col class="text-center">
            <router-link :to="{ name: 'client', params: { id_entreprise: $route.params.id_entreprise }}" style="text-decoration: none;">
        <v-btn @click="$store.state.currentTab = 0" class="btnBold mr-10"  color="black" plain>
          <div>
            Annuler
          </div>
        </v-btn>
      </router-link>
          <v-btn :disabled="loading" class="btnBold" :elevation="0" color="#FF9900" dark rounded type="submit" >
            <v-icon left> fa fa-plus </v-icon>
            <div class="pl-5 pr-7">
              ENREGISTRER
            </div>
          </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </div>
    <v-col v-if="this.$route.name == 'modifier_client'" align-self="center" cols="2">
        <v-row justify="end">
          <v-dialog v-model="dialog" persistent max-width="615">
            <v-card class="card_border">
              <v-card-text class="pt-10 modal">
                <p>La modification a bien été effectuée.</p>
                   <div class="">
                  <v-btn class="btn_choice " rounded text @click="sendToclient()" >
                    ok
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      </div>
</template>
<script>
import Title from "@/components/shared/Title";
import api from "@/api/entreprise";
import api_user from "@/api/user";
import ResultList from "@/components/shared/ResultList";
import { GoogleProvider } from "leaflet-geosearch";
// valideur vuelidate
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  // minValue,
} from "vuelidate/lib/validators";

export default {
  components: {
    Title,
    ResultList,
  },
  data() {
    return {
      dialog: false,
      titlePage: "MODIFIER UN CLIENT",
      typeProspect: "",
      potentielAchat: "",
      raisonSociale: "",
      approximate: false,
      adresseApproximate: "",
      villeApproximate: "",
      postalApproximate: "",
      adresse: "",
      postal: "",
      telephone: "",
      email: "",
      ville: "",
      commerciaux: [],
      criteresDachat: [],
      achatshydraulique: [],
      freins_et_atouts: "",
      valuesCritereAchats: [],
      arrayCritereAchats: [],
      valuesAchatshydraulique: {},
      valuesCommerciaux: [],
      commerciauxError: false,
      critereAchatError: false,
      achatHydrauliqueError: false,
      loading: false,
      prospect_visite: 0,
      nb_visites_prevues: 0,
      description_activite: "",
      loadingSearch: false,
      autocomplete: null,
      adresses: [],
      search: null,
      lattidude: null,
      longitude: null,
      region: null,
      departement: null,
      nbcommerciauxSelected: 0,
      isAddrModified: false,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      rules: {
        email: (v) =>
          !!(v || "").match(/@/) || "Merci d'entrer un email valide",
        champ: (v) => !!v || "Ce champ ne peut pas être vide",
      },
      headerAchats: [
        { text: "PRODUIT", align: "left", sortable: false, value: "produit" },
        { text: "FTA", align: "center", sortable: false, value: "fta" },
        {
          text: "CONCURRENCE",
          align: "center",
          sortable: false,
          value: "marque_concurrence",
        },
        {
          text: "VOLUME ANNUEL",
          align: "center",
          sortable: false,
          value: "volume",
        },
        {
          text: "PRODUIT À DÉVELOPPER",
          align: "center",
          sortable: false,
          value: "produit_a_developper",
        },
      ],
    };
  },
  validations: {
    raisonSociale: { required },
    email: { required, email },
    commerciaux: { required },
    criteresDachat: { required },
    achatshydraulique: { required },
    telephone: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    nb_visites_prevues: { numeric},
    typeProspect: { required },
    potentielAchat: { required },
  },
  async mounted() {
    await this.criteresAchat();
    await this.achatsHydrauliqueFc();
    await this.getCommerciaux();

    this.getUserEntreprise(this.$route.params.id_entreprise);
  },
  watch: {
    search(newval, val) {
      // if ( val == null) {
      //   val = this.autocomplete
      // }
      console.log(val)
      console.log(newval)

      if (( newval != null || newval.length > 8 ) && this.isAddrModified) {
        if ( this.autocomplete != null ) {
          if ( this.autocomplete.raw != undefined && this.autocomplete.raw.geometry.location_type ==  "APPROXIMATE" ) {
            console.log("approximate")
            this.adresseApproximate = val.substring(0, val.indexOf(","));
            this.approximate = true;
          }else {
            console.log("not approximate")
            this.approximate = false;
          }
        }

        newval && this.findAdress(newval);
        
      }
    },
  },
  methods: {
    nbcommerciaux() {
      let c = this.commerciaux.filter((res) => {
        return res.value == true;
      });
      console.log("au chage", c);
      this.nbcommerciauxSelected = c.length;
    },
    sendToclient() {
      this.dialog = false;
      // this.$router.push({ path: "/client/"+this.$route.params.id_entreprise});
      this.$router.push({
        name: "client",
        params: { id_entreprise: this.$route.params.id_entreprise },
      });
    },
    findAdress(query) {
      let arr = [];

      console.log("array vide", arr);
      var ad_length = 0;
      // let provider = new OpenStreetMapProvider();
      const provider = new GoogleProvider({
        params: {
          key: process.env.VUE_APP_APIKEY_GOOGLE, // clé api google ftareferencement@gmail.com 
          language: "fr", // render results in french
          region: "fr", // limit search results to the france
          // addressdetails: 1, // include additional address detail parts
        },
      });
      this.loadingSearch = true;
      console.log(query, "autocmplete");
      provider.search({ query: query }).then((addresses) => {
        if (addresses.length > 10) {
          ad_length = 10;
        } else {
          ad_length = addresses.length;
        }
        for (var i = 0; i < ad_length; i++) {
          arr.push(addresses[i]);
        }

        this.adresses = arr;
        console.log(this.$refs);
        this.$refs["auto"].cachedItems = arr;
        console.log("variable = arr", this.adresses);
        this.loadingSearch = false;
        if ( this.approximate == true) {
            let addr_obj = this.autocomplete.raw.address_components;
            addr_obj.forEach((details) => {
              if (details.types[0] == "locality") {
                this.villeApproximate = details.long_name;
              }
              if (details.types[0] == "postal_code") {
                this.postalApproximate = details.long_name;
              }
            });
        }
        console.log(this.adresses, this.autocomplete);
      });
    },
    async getCommerciaux() {
      const users = await api_user.getCommerciaux();
      console.log(users, "users apres await");
      users.data.users.forEach((commercial) => {
        commercial["value"] = false;
      });
      this.commerciaux = users.data.users;
    },
    getUserEntreprise(id) {
      api.getUserEntreprise(id).then((res) => {
        this.$emit("title", res.data.entreprise.raison_sociale);
        // console.log("ceci est la réponse", res.data.entreprise);
        this.typeProspect = res.data.entreprise.type_client.toUpperCase();
        this.potentielAchat = res.data.entreprise.potentiel_achat;
        this.raisonSociale = res.data.entreprise.raison_sociale;
        this.autocomplete =
          res.data.entreprise.adresse +
          ", " +
          res.data.entreprise.code_postal +
          " " +
          res.data.entreprise.ville;
        this.adresses.push(
          res.data.entreprise.adresse +
            ", " +
            res.data.entreprise.code_postal +
            " " +
            res.data.entreprise.ville
        );
        this.adresse = res.data.entreprise.adresse;
        this.postal = res.data.entreprise.code_postal;
        this.telephone = res.data.entreprise.telephone_fixe;
        this.email = res.data.entreprise.email_entreprise;
        this.ville = res.data.entreprise.ville;
        this.freins_et_atouts = res.data.entreprise.freins_et_atouts;
        this.description_activite = res.data.entreprise.description_activite;
        this.prospect_visite = res.data.entreprise.prospect_visite ? 1 : 0;
        this.latitude = res.data.entreprise.latitude;
        this.longitude = res.data.entreprise.longitude;
        this.nb_visites_prevues = res.data.entreprise.nb_visites_prevues;
        res.data.entreprise.CritereAchats.forEach((critere) => {
          this.valuesCritereAchats[critere.id_critere_achat] =
            critere.EntrepriseCritereAchat.commentaire;
        });
        res.data.entreprise.AchatHydrauliques.forEach((achat) => {
          this.achatshydraulique.forEach((ac) => {
            if (ac.id_achat_hydraulique == achat.id_achat_hydraulique) {
              ac.fta = achat.EntrepriseAchatHydraulique.fta;
              ac.marque_concurrence =
                achat.EntrepriseAchatHydraulique.marque_concurrence;
              ac.volume = achat.EntrepriseAchatHydraulique.volume;
              ac.produit_a_developper =
                achat.EntrepriseAchatHydraulique.produit_a_developper;
            }
          });
          //  this.achatshydraulique.push({produit:e.libelle,fta:e.EntrepriseAchatHydraulique.fta,marque_concurrence:e.EntrepriseAchatHydraulique.marque_concurrence,produit_a_developper:e.EntrepriseAchatHydraulique.produit_a_developper,volume:e.EntrepriseAchatHydraulique.volume})
        });
        res.data.entreprise.Users.forEach((e) => {
          console.log("boucle des users de entreprise", this.commerciaux);
          this.commerciaux.forEach((com) => {
            
            if (com.id_utilisateur == e.id_utilisateur) {
              com["value"] = true;
            }
          });
        });
        this.nbcommerciaux();
        console.log(this.commerciaux, "commerciaux after");
      });
    },

    updateAchatHydraulique($event) {
      this.valuesAchatshydraulique[$event.id] = $event.item;
    },

    validate() {
      this.$refs.form.validate();
    },
    async criteresAchat() {
      await api.criteresAchat().then((res) => {
        res.data.criteresAchat.map((e) => {
          this.criteresDachat.push({ txt: e.critere, id: e.id_critere_achat });
        });
      });
    },
    async achatsHydrauliqueFc() {
      await api.achatsHydraulique().then((res) => {
        res.data.achatsHydraulique.map((e) =>
          this.achatshydraulique.push({
            produit: e.libelle,
            fta: false,
            marque_concurrence: "",
            volume: "",
            produit_a_developper: "",
            id_achat_hydraulique: e.id_achat_hydraulique,
          })
        );
      });
    },
    submit() {
      console.log(this.isAddrModified, this.adresse)
      let achatMod = this.achatshydraulique.filter((ac) => {
        return (
          ac.fta != "" ||
          ac.produit_a_developper != "" ||
          ac.volume != "" ||
          ac.marque_concurrence != ""
        );
      });

      this.critereAchatError = false;
      this.achatHydrauliqueError = false;
      this.commerciauxError = false;
      let ah = [];
      let c = [];
      let id_utilisateur = [];
      this.$v.$touch();
      let valueCommerciaux = this.commerciaux.filter(
        (commercial) => commercial.value === true
      );

      valueCommerciaux.forEach((c) => {
        id_utilisateur.push(c.id_utilisateur);
      });

      if (valueCommerciaux.length == 0) {
        this.commerciauxError = true;
      }
      if (this.valuesCritereAchats.length == 0) {
        this.critereAchatError = true;
      }

      if (achatMod.length == 0) {
        this.achatHydrauliqueError = true;
      }
      console.log(this.$v.$invalid, this.$v);
      if (
        !this.commerciauxError &&
        !this.$v.$invalid &&
        !this.achatHydrauliqueError
      ) {
        this.loading = true;

        // traitement des criteres achat
        if (this.valuesCritereAchats.length > 0) {
          for (let critere in this.valuesCritereAchats) {
            c.push({
              id_critere_achat: critere,
              commentaire: this.valuesCritereAchats[critere],
            });
          }
        }

        // traitement des achats hydraulique

        for (let achat in this.valuesAchatshydraulique) {
          this.valuesAchatshydraulique[achat].fta =
            this.valuesAchatshydraulique[achat].fta == true ? 1 : 0;
          this.valuesAchatshydraulique[achat].produit_a_developper =
            this.valuesAchatshydraulique[achat].produit_a_developper == true
              ? 1
              : 0;
          ah.push(this.valuesAchatshydraulique[achat]);
        }

        // traiement de l'adresse

        // let add = "";
        let addr_obj = this.autocomplete.raw? this.autocomplete.raw.address_components:null
        if (this.isAddrModified) {
          this.adresse = ""
          console.log('addr modifiée',addr_obj)
          addr_obj.forEach((details) => {
            console.log(details.types[0]);
            if (details.types[0] == "street_number") {
              this.adresse += details.long_name + " ";
            }
            if (details.types[0] == "route") {
              this.adresse += details.long_name;
            }
            if (details.types[0] == "locality") {
              this.ville = details.long_name;
            }
            if (details.types[0] == "postal_code") {
              this.postal = details.long_name;
            }
            if (details.types[0] == "administrative_area_level_1") {
              this.region = details.long_name;
            }
            if (details.types[0] == "administrative_area_level_2") {
              this.departement = details.long_name;
            }
          });
          this.latitude = this.autocomplete.y;
          this.longitude = this.autocomplete.x;
          
        }

        // this.adresse = add;
        if ( this.approximate == true) {
          this.adresse = this.adresseApproximate
        }
        // obj entreprise

        var modification = {
          raison_sociale: this.raisonSociale,
          adresse: this.adresse,
          code_postal: this.postal,
          ville: this.ville,
          email_entreprise: this.email,
          telephone_fixe: this.telephone,
          type_client: this.typeProspect,
          potentiel_achat: this.potentielAchat,
          id_utilisateur: id_utilisateur,
          commerciaux: valueCommerciaux,
          EntrepriseCritereAchat: c,
          EntrepriseAchatHydraulique: achatMod,
          freins_et_atouts: this.freins_et_atouts,
          prospect_visite: this.prospect_visite,
          description_activite: this.description_activite,
          latitude: this.latitude,
          longitude: this.longitude,
          region: this.region,
          nb_visites_prevues: this.nb_visites_prevues,
          departement: this.departement,
        };
      console.log("c'est la modification", modification)

        api
          .updateProspect(this.$route.params.id_entreprise, modification)
          .then(() => {
            this.loading = false;
            this.dialog = true;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: 17px;
}
.modal {
  font-size: large;
}
.align_commerciau {
  display: flex;
  flex-flow: wrap column;
  max-height: 172px;
}
.error_form {
  color: red;
  text-align: left;
}
.btnBold {
  font-weight: bold;
}
::v-deep
  button.btnBold.v-btn.v-btn--has-bg.v-btn--rounded.theme--dark.elevation-0.v-size--default {
  height: 46px;
}
::v-deep span.v-btn__content {
  font-size: normal;
  letter-spacing: initial;
}
.alignement_input {
  text-align: left;
}
.rectangle_bleu {
  background-color: #0b87ba;
  color: white;
  padding-top: 8px;
  text-align: left;
  padding-bottom: 8px;
  padding-left: 15px;
  font-weight: bold;
  margin-top: 32px;
}
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px;
  align-items: center;
}
::v-deep .v-text-field--outlined .v-label {
  top: 6px;
  font-weight: bold;
}
::v-deep .v-radio.theme--light {
  margin-left: 32px;
  font-weight: bold;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px;
}

::v-deep label.v-label.theme--light {
  color: #4b4b4b;
}
.crit_achat {
  color: white;
  background-color: #0b87ba;
}
.commentaires {
  color: white;
  background-color: #085a7c;
}
#prospect .v-radio.theme--light:first-child {
  margin-left: 0;
  margin-top: 0;
}
#prospect ::v-deep .v-input--selection-controls {
  margin-top: 0;
}
::v-deep .v-input__slot {
  display: flex;
  justify-content: center;
}
::v-deep .v-application .align-center {
  align-items: center !important;
  justify-content: center !important;
}
::v-deep .d-flex.align-center {
  justify-content: center;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px;
}
::v-deep thead.v-data-table-header {
  background-color: #e6e6e6;
}
::v-deep
  .theme--light.v-data-table[data-v-37715c05]
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-right: none;
  height: 45px;
}
::v-deep .v-data-table-header::after {
  height: 0;
}
::v-deep
  .v-input.rectangle_box.v-textarea.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.v-text-field--placeholder {
  border-radius: 15px;
}
.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}
::v-deep .v-text-field .v-input__append-inner {
  margin-top: 4px;
}

@media (max-width: 1366px) {
  ::v-deep .col-sm-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  ::v-deep .col-sm-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  ::v-deep .col-sm-7 {
    flex: 0 0 100%;
    max-width: 67%;
  }
  ::v-deep .col-sm-3 {
    flex: 0 0 100%;
    max-width: 33%;
  }
  ::v-deep .col-10 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
