<template lang="">
    <div id="projets">
        
        <Title :titlePage="titlePage" />
    <v-container fluid>
    <v-row align="center">
      <v-col cols="12" lg="12" xl="10">
        <resultList
      :header="headerProjets"
      :items="projets"
      :nbResult="10"
      :type="'projets'"
      @sort="sort"
      :totalItems="totalItems"
      :page="page"
      :canModif="canModif"
    />
    </v-col>
    <v-col v-if="totalPages>1" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>

        </v-col>  
        </v-row>
        </v-container>
    </div>
</template>

<script>
import Title from "@/components/shared/Title";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/user";

export default {
  components: { Title, ResultList },
  data() {
    return {
      titlePage: "Projets en cours",
      projets: [],
      totalItems: 0,
      totalPages:0,
      column: "date_projet",
      order: "DESC",
      page: 1,
      headerProjets: [
        {
          text: "CLIENT",
          align: "center",
          sortable: true,
          value: "Entreprise.raison_sociale",
        },
        {
          text: "DATE",
          align: "center",
          sortable: true,
          value: "date_projet",
          name: "date_projet",
        },
        {
          text: "NOM DU PROJET",
          align: "center",
          sortable: true,
          value: "nom_projet",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "bloc_fore",
        },
        {
          text: "PERSONNE RENCONTREE",
          align: "center",
          sortable: true,
          value: "client",
          name: "Client",
        },
        {
          text: "DESCRIPTION",
          align: "center",
          sortable: false,
          value: "description",
        },
        {
          text: "FICHIER JOINT",
          align: "center",
          sortable: false,
          value: "documents",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      canModif:0,
      usersEntreprise:[]
    };
  },
  methods: {
    getProjets(id = null,nb_display,page,column,order) {
      console.log(id, "id user");
      api.getProjets(id,nb_display,page,column,order).then((res) => {
        res.data.projets.rows.forEach((projet) => {
            projet["client"]=[];
          // contact sur le projet
          projet.Personnes.forEach(personne =>{
            projet["client"].push(personne.civilite + ' ' + personne.nom + ' ' + personne.prenom)    
          })
          console.log(projet["client"]);
          // projet["client"]projet["client"] =
          //   projet.Entreprise.nom_contact +
          //   " " +
          //   projet.Entreprise.prenom_contact;
          // calcul url
          projet.Documents.forEach((doc) => {
            console.log("chemin", doc.chemin);
            doc["chemin"] = process.env.VUE_APP_BASE_URL + doc.chemin;
          });
          let usersEntreprise = []
          projet.Entreprise.Users.forEach(user => {
            usersEntreprise.push(user.id_utilisateur)
          })
          projet.canModif = 0;
          if (
        (this.$store.state.AsUser.id_utilisateur == ""
          ? this.$store.state.user.id_role
          : this.$store.state.AsUser.id_role) == 1  ||
        usersEntreprise.includes(
          this.$store.state.AsUser.id_utilisateur == ""
            ? this.$store.state.user.id_utilisateur
            : this.$store.state.AsUser.id_utilisateur
        )
      ) {
         projet.canModif  = 1;
      }

        });

        this.projets = res.data.projets.rows;
        this.totalItems = res.data.projets.totalItems;
        this.totalPages = res.data.projets.totalPages;
        console.log(this.projets);
      });
      
    },
    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        console.log($event.column)
        if($event.column=='Entreprise.raison_sociale'){
           this.column = $event.column;
        }else{
            this.column = $event.column;
        }
     
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getProjets(
        this.$store.state.AsUser.id_utilisateur
          ? this.$store.state.AsUser.id_utilisateur
          : "",
          process.env.VUE_APP_NB_DISPLAY,
          this.page - 1,
          this.column,
          this.order  
        
      );
      // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },
    CanModified() {
      if (
        (this.$store.state.AsUser.id_utilisateur == ""
          ? this.$store.state.user.id_role
          : this.$store.state.AsUser.id_role) == 1  ||
        this.usersEntreprise.includes(
          this.$store.state.AsUser.id_utilisateur == ""
            ? this.$store.state.user.id_utilisateur
            : this.$store.state.AsUser.id_utilisateur
        )
      ) {
        this.canModif = 1;
      }
      console.log(this.canModif, "can modif");
    },
  },
  created() {
    console.log("created de projet en cours");
    this.getProjets(
      this.$store.state.AsUser.id_utilisateur
        ? this.$store.state.AsUser.id_utilisateur
        : ""
    );
    
  },
};
</script>
<style scoped lang="scss">
#projets {
  .v-data-table {
    width: 100%;
    margin-top: 5rem;
  }
  th {
    font-size: 1em;
  }
  th:nth-child(5){
    width: 25vw;
  }

  // th:nth-child(2){
  //   width: 20vw;
  // }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child {
    padding-bottom: 15px;

    > th {
      border-bottom: none;
      border-right: 1px solid grey;
      height: auto !important;
    }
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th:last-child {
    border-bottom: none;
    border-right: 0px solid grey;
  }
  table > thead > tr > th span {
    width: 100%;
    padding: auto;
    margin: auto;
    padding-right: 1em;
  }


  .v-data-table-header th.active .v-data-table-header__icon,
  .v-data-table-header th .v-data-table-header__icon {
    opacity: 1;
    color: black;
  }
  .v-data-table-header::after {
    height: 1em;
    display: table-row;
    content: "";
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td {
    font-size: 0.9em;
  }

  .v-data-table-header th.text-center:nth-child(2) {
    width: 17vh;
  }
}
</style>