<template>
  <div>
    <Title :titlePage="titlePage" />
    <v-container>
      <v-col cols="12" sm="10" class="pb-0 pl-8 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left white--text"
          >
            INFORMATIONS
          </v-col>
        </v-toolbar>

        <v-col cols="12" sm="6" class="pb-0 pl-0">
          <div class="text-left my-3">NUMERO DE COMMANDE*</div>
          <div class="text-left font-weight-bold my-5">
            {{ litige.numero_commande }}
          </div>

          <div class="text-left my-3">RAISON SOCIALE*</div>
          <div class="text-left font-weight-bold my-5">
            {{ litige.raison_sociale }}
          </div>

          <div class="text-left my-3">DATE*</div>
          <div class="text-left font-weight-bold my-5">
            {{ litige.date_comptabilisee }}
          </div>
          <div class="text-left mb-3">MONTANT*</div>
          <div class="text-left font-weight-bold">{{ litige.montant }}€</div>
        </v-col>
      </v-col>
      <v-col cols="12" sm="10" class="pb-0 pl-8 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left white--text"
          >
            DESCRIPTION
          </v-col>
        </v-toolbar>
        <v-col cols="12" sm="6" class="pb-0 pl-0">
          <div class="text-left mb-3">DESCRIPTION*</div>
          <v-textarea
            v-if="mode != 'visualisation'"
            outlined
            auto-grow
            rows="1"
            v-model="litige.description_litige"
          >
          </v-textarea>
          <div
            v-if="mode == 'visualisation'"
            class="text-left font-weight-bold"
          >
            {{ litige.description_litige }}
          </div>
        </v-col>
      </v-col>

      <v-col cols="12" sm="10" class="pb-0 pl-8 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left white--text"
          >
            SERVICE
          </v-col>
        </v-toolbar>
        <v-col cols="12" sm="6" class="pb-0 pl-0">
          <div class="text-left mb-3">SERVICE *</div>
          <v-select
            v-model="litige.Litige.service"
            :items="services"
            label="Service concerné"
            persistent-hint
            single-line
          ></v-select>
          <div
            v-if="mode == 'visualisation'"
            class="text-left font-weight-bold"
          >
            {{ litige.Litige.service }}
          </div>
        </v-col>
      </v-col>
      <v-col cols="12" sm="10" class="alignement_input pl-8">
        <router-link
          v-if="mode != 'visualisation'"
          :to="{
            name: 'litiges',
            params: { numero_commande: $route.params.numero_commande },
          }"
          style="text-decoration: none;"
        >
          <v-btn
            @click="$store.state.currentTab = 3"
            class="btnBold mr-10"
            color="black"
            plain
          >
            <div>
              Annuler
            </div>
          </v-btn>
        </router-link>
        <v-btn
          v-if="mode !== 'visualisation'"
          class="btnBold"
          color="#FF9900"
          dark
          rounded
          @click="submit"
        >
          <v-icon left> fa fa-plus </v-icon>
          <div>
            ENREGISTRER
          </div>
        </v-btn>
        <router-link
          id="retourBtn"
          v-if="mode == 'visualisation'"
          :to="{
            name: 'litiges',
            params: { numero_commande: $route.params.numero_commande },
          }"
          style="text-decoration: none;"
        >
          <v-btn
            @click="$store.state.currentTab = 3"
            class="btnBold"
            color="#FF9900"
            dark
            rounded
            type="submit"
          >
            <div>
              RETOUR
            </div>
          </v-btn>
        </router-link>
        <Popup :dialog="dialog" :text="messageCreation" :actions="actions" />
      </v-col>
    </v-container>
  </div>
</template>
<script>
import api from "@/api/litiges.js";
import Title from "@/components/shared/Title";
import Popup from "@/components/shared/Popup";

export default {
  components: { Title, Popup },
  data() {
    return {
      titlePage: "",
      litige: {},
      dialog: false,
      messageCreation: "La description du litige a bien été modifiée",
      services: ["Atelier", "Commercial", "Magasin"],
      actions: {
        route: [
          {
            btn: "OK",
            routeName: "litiges",
          },
        ],
      },
    };
  },
  methods: {
    async submit() {
      let description = await api.addDescription(
        this.$route.params.numero_commande,
        this.litige.description_litige,
        this.litige.Litige.service
      );
      console.log(description);

      this.dialog = true;
    },

    getLitige(numero_commande) {
      api.getLitige(numero_commande).then((res) => {
        this.litige = res.data.litige;
        console.log(this.litige);
      });
    },
  },
  created() {
    this.getLitige(this.$route.params.numero_commande);

    if (this.$route.name == "view_litige") {
      this.mode = "visualisation";
      this.titlePage = "VOIR UN LITIGE";
    } else {
      this.mode = "modification";
      this.titlePage = "MODIFIER UN LITIGE";
    }
  },
};
</script>

<style scoped>
::v-deep .v-select__selections {
  line-height: 25px !important;
}
</style>
