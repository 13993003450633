<template>
  <div>
    <iframe
      :src="url"
      frameborder="0"
      id="myiframe"
      width="100%"
      height="800"
      allowtransparency
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url:
        process.env.VUE_APP_REPORTING_URL + "public/dashboard/c0cdfaeb-2b02-4cf5-a56b-6ac34e9e574f"
    };
  },
  created() {
  },
};
</script>
