<template>
  <div id="login" class="section">
    <v-container fluid>
      <v-row style="height: 100vh; max-height: 100%">
        <v-col
          cols="10"
          offset="1"
          xl="4"
          lg="4"
          offset-lg="4"
          md="6"
          offset-md="3"
          sm="8"
          offset-sm="2"
          class="login_content"
        >
          <v-col
            cols="12"
            xl="8"
            offset-xl="2"
            md="10"
            offset-md="1"
            class="pb-0 form"
          >
            <FormAuth class="fond_bi_color" :user="user" @login="login($event)" :err="err" />
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "@/api/user";
import FormAuth from "../components/Authentification/FormAuth";
export default {
  name: "Authentification",
  components: {
    FormAuth,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      err: [],
    };
  },
  methods: {
    login(props) {
      console.log(props, "page");
      if (this.user.email == "" && this.user.password != "") {
        this.err.splice(0, 1);
        this.err.push("L'identifiant ne peut pas être vide");
      }
      if (this.user.password == "" && this.user.email != "") {
        this.err.splice(0, 1);
        this.err.push("Le mot de passe ne peut pas être vide");
      }
      if (this.user.email == "" && this.user.password == "") {
        this.err.splice(0, 1);
        this.err.push("Les champs ne peuvent pas être vides");
      }
      if (this.user.email != "" && this.user.password != "") {
        api
          .login(this.user) // appel à la route
          .then((res) => {
            // if login ok
            // stocker les infos user dans le state
            this.$store.commit("infosUser", res.data.data);
            // redirect vers home
            this.$router.push({ name: "home" });
          })
          .catch((error) => {
            if (
              401 === error.response.status ||
              400 === error.response.status ||
              500 === error.response.status
            ) {
              // erreur sur le login depuis le back
              this.err.splice(0, 1);
              this.err.push(error.response.data.message);
              console.log(this.err);
            }else{
              console.log('error not definet',error)
            }
          });
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

#login.section {
  background: linear-gradient(
    180deg,
    rgba(243, 242, 240, 1) 60%,
    rgba(228, 228, 226, 1) 40%
  );
}
.columns {
  height: 100%;
}

label.v-label.v-label--active.theme--light {
  background-color: #ffff;
  padding: 0px 6px 0px 0;
}

.login_content {
  width: 100%;
  height: 100%;
  padding-top:24vh;
  align-items: center;
}

.fond_bi_color {
  box-shadow: 0px 80px 25px rgba(255, 255, 255, 0.6);
}
// @media screen and (min-width: 1025px) {
//   .login_content {
//     width: 450px;
//     height: 100%;
//     padding-top: 15vh;
//      align-items: center;
//   }
//   .fond_bi_color {
//     background: #ffff;
//     height: 47vh;
//     width: 100%;
//     opacity: 0.15;
//   }

// }

@media screen and (max-width: 1366px) {
  .login_content {
    padding-top: 16vh;
  }
  .fond_bi_color {
    height: 22vh;
  }
}
@media screen and (max-width: 1280px) {
  .login_content {
    padding-top: 12vh;
  }
  .fond_bi_color {
    height: 24vh;
  }
}
@media screen and (max-width: 1024px) {
  .login_content {
    padding-top: 13vh;
  }
  .fond_bi_color {
    height: 23vh;
  }
}

@media screen and (max-width: 768px) {
  .fond_bi_color {
    height: 35vh;
  }
}

@media screen and (max-width: 414px) {
  .login_content {
    padding-top: 11vh;
  }
  .fond_bi_color {
    height: 27vh;
  }
  // .margin_top {
  //   margin-top: 35%;
  // }
  .box {
    box-shadow: none;
  }
  .form {
    height: 100%;
  }
}
@media screen and (max-width: 375px) {
  .login_content {
    padding-top: 21vh;
  }
}

@media screen and (max-width: 360px) {
  .login_content {
    padding-top: 14vh;
  }
  .text_align {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 320px) {
  .login_content {
    padding-top: 7vh;
  }
 
}
</style>

<!--  gestion des erreurs par via des toast  
vuelidate pour récuperer les erreurs par exemple
-->
