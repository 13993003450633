<template lang="">

      <!-------------------------------- DEMANDE D'AVOIR -------------------------------->
      <div v-if="retour.id_type_retour == 4">
            <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            DEMANDE DE REPARATION
          </v-col>
        </v-toolbar>
          </v-col>


    <v-col cols="12" sm="6" class="pb-0 ">
      <div class="text-left mt-2">REPARATION POSSIBLE<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="demandeDeReparation.reparation_possible.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="trueOrFalse(demandeDeReparation.reparation_possible.valeur)">{{demandeDeReparation.reparation_possible.text}}</div>
                <div class="errorMsg" v-if="$v.demandeDeReparation.reparation_possible.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>

      <v-col  cols="12" sm="6" class="pb-0  mt-5">
       <div class="text-left">COMMENTAIRE<span v-if="mode !='creation'">*</span></div>
        <v-textarea  class="mt-3" v-if="mode !== 'visualisation'" outlined auto-grow rows="1"  v-model="demandeDeReparation.commentaire_reparation.valeur" hide-details> </v-textarea>
          <div  v-if="mode == 'visualisation'" class="font-italic text-h6 text-left" style="overflow-wrap: break-word;">{{ demandeDeReparation.commentaire_reparation.valeur }}</div>
          <div class="errorMsg" v-if="$v.demandeDeReparation.commentaire_reparation.valeur.$error && ($v.demandeDeReparation.commentaire_reparation.valeur.required == false)">
            Renseignez un commentaire
          </div>

          <div class="errorMsg" v-if="$v.demandeDeReparation.commentaire_reparation.valeur.$error && ($v.demandeDeReparation.commentaire_reparation.valeur.required == true && $v.demandeDeReparation.commentaire_reparation.valeur.maxLength == false) ">
            2000 caractères au maximum.
          </div>
      </v-col>

      <v-col v-if="demandeDeReparation.reparation_possible.valeur == 'true'"  cols="12" sm="6" class="pb-0 ">
        <div class="text-left">MAIN D'OEUVRE ESTIMEE<span v-if="mode !='creation'">*</span></div>
        <v-text-field
          v-if="mode !== 'visualisation'"
          v-model="demandeDeReparation.main_doeuvre_estimee.valeur"
          class="mt-0 pt-0"
          type="number"
          style="width: 60px"
          hide-details
        ></v-text-field>
          <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{demandeDeReparation.main_doeuvre_estimee.valeur}} PERSONNE(S)</div>
          <div class="errorMsg" v-if="$v.demandeDeReparation.main_doeuvre_estimee.valeur.$error">
            Renseignez la main d'oeuvre estimée
          </div>
      </v-col>

      <v-col  cols="12" sm="10" class="pb-0  mt-10" >
        <!-- <div class="text-left">ARTICLES A FACTURER</div> -->
        <AddArticles :retour_a_articles="demandeDeReparation.articles" widthProp="70vW" :mode="mode" :title="'ARTICLES A FACTURER'"/>
      </v-col>

      </div>

</template>
<script>
import {  requiredIf, maxLength} from 'vuelidate/lib/validators'
import AddArticles from "@/components/Clients/AddArticles"
import moment from "moment";




export default {
props: {
  retour: Object,
  demandeDeReparation: Object,
  mode: [String]
},
  components: {
    AddArticles,
  },
  data() {
    return {
      menuGarantie: false,
      dateTest: "",
      valeurSelect: [
        {
          text: "acceptée",
          value: "true"
        },
        {
          text: "refusée",
          value: "false"
        },
      ]      
    };
  },
    validations: {
      demandeDeReparation: {
      reparation_possible: {
        valeur: {
          required : requiredIf(function(){return this.mode != "creation" }),

        }
      },
      main_doeuvre_estimee: {
        valeur: {
         required: requiredIf(function(){ return this.conditionValidation("true") && this.mode != "creation"  })
        }
      },
      commentaire_reparation: {
        valeur: {
          required : requiredIf(function(){return this.mode != "creation" }),
          maxLength: maxLength(2000)
        }
      },
    }
  },
  computed: {
    formatDateGarantie() {
      return this.demandeDeReparation.date_du_produit.valeur
        ? moment(this.demandeDeReparation.date_du_produit.valeur).format("L")
        : "";

    }
  },
  methods: {
    conditionValidation(valeur){
      if ( this.demandeDeReparation.reparation_possible.valeur == valeur) {
        return true
      }

    },
    trueOrFalse(value) {
      if ( value == "true" ) {
        this.demandeDeReparation.reparation_possible.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.demandeDeReparation.reparation_possible.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.demandeDeReparation.reparation_possible.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }

    }

  },
  mounted() {
  }
};
</script>
<style scoped>
.calendar-field{
      width: 20%;
}

.date-letter {
  font-size: 15px !important
}

.text-modal{
    color: black;
    font-weight: normal;
    font-size: large;
}

::v-deep .v-select__selection.v-select__selection--comma {
	color: #0B87BA;
  font-style: italic;
}

::v-deep .v-icon.notranslate.mdi.mdi-menu-down {
	color: #0B87BA !important;
	font-size: 2.5em;
}



.card_border.v-card.v-sheet.theme--light {
	border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width:29px;
  height: 28px
}

.font_color {
  font-weight: bold;
  color: #FFFFFF 
}

.width-50 {
  width: 30%
}

.addPersonne {
  border-style: dashed ;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}




</style>
