var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h2',[_vm._v(_vm._s(_vm.entreprise.raison_sociale)+" ("+_vm._s(_vm.entreprise.code_client_fta)+")")]),_vm._v(" "),(_vm.entreprise.actif == 0)?_c('span',{staticStyle:{"line-height":"1.2em","font-weight":"bold","color":"red","font-size":"24px"}},[_vm._v("(désactivé) ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"4"}},[_c('router-link',{staticClass:"bold btn",attrs:{"to":{
          name: 'client',
          params: { id_entreprise: _vm.entreprise.id_entreprise },
        }}},[_c('span',{on:{"click":function($event){_vm.$store.state.currentTab = 0}}},[_vm._v(" VOIR LA FICHE ")])])],1)],1),_c('v-row',{attrs:{"no-gutters":"","align-self":"center"}},[_c('v-col',[_c('p',{staticClass:"bold details mt-2"},[_vm._v(" "+_vm._s(_vm.entreprise.adresse)),_c('br'),_vm._v(" "+_vm._s(_vm.entreprise.code_postal)+" "+_vm._s(_vm.entreprise.ville)),(
            (_vm.entreprise.email_entreprise &&
              _vm.entreprise.email_entreprise.length > 0) ||
              _vm.entreprise.telephone_fixe.length
          )?_c('br'):_vm._e(),(_vm.entreprise.email_entreprise)?_c('a',{staticClass:"mt-1",attrs:{"href":("mailto:" + (_vm.entreprise.email_entreprise ? _vm.entreprise.email_entreprise : ''))}},[_vm._v(_vm._s(_vm.entreprise.email_entreprise ? _vm.entreprise.email_entreprise : ""))]):_vm._e(),(_vm.entreprise.telephone_fixe.length)?_c('br'):_vm._e(),_c('a',{attrs:{"href":("tel:" + (_vm.entreprise.telephone_fixe))}},[_vm._v(_vm._s(_vm.entreprise.telephone_fixe.length == 10 ? _vm.entreprise.telephone_fixe .split( /([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})/ ) .join(" ") : _vm.entreprise.telephone_fixe))])])]),(_vm.i + 1 < _vm.max)?_c('v-col',{attrs:{"cols":"12"}},[_c('hr')]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }