<template>
    <div id="devis">
        
        <Title :titlePage="titlePage" />
    <v-container fluid>
    <v-row align="center">
      <v-col cols="12" lg="12" xl="10">
        <resultList
      :header="headerDevis"
      :items="devis"
      :nbResult="10"
      :type="'devis'"
      @sort="sort"
      :totalItems="totalItems"
      :page="page"
      @emitPopupDevis="setDevisDevisOverlay($event)"
    />
    </v-col>
        <v-col v-if="totalPages>1" cols="12" lg="12" xl="10">
            <div class="text-center">
              <v-pagination
                v-model="page"
                :length="totalPages"
                :total-visible="10"
                circle
              ></v-pagination>
            </div>
        </v-col>  
    </v-row>
    </v-container>

    <DevisOverlay @emitDialog="closeAndRefreshDevis($event)" :numero_devis="numero_devis" :dialog="dialog"  text="Configuration devis" actions="" heightProp="" widthProp="70vW" showClose="true"> 

    </DevisOverlay>
    </div>
</template>

<script>
import api from "@/api/user.js";
import ResultList from "@/components/shared/ResultList";
import Title from "@/components/shared/Title";
import DevisOverlay from "@/components/Devis/devisOverlay";
export default {
  name: 'devis',
  components: { Title, ResultList,  DevisOverlay},

data() {
    return {
      titlePage: "Devis en cours",
      dialog: false,
      devis: [],
      totalItems: 0,
      totalPages:0,
      page: 1,
      numero_devis: 0,
      headerDevis: [
        {
          text: "CLIENT",
          align: "center",
          value: "raison_sociale",
        },
        {
          text: "NUMERO DEVIS",
          align: "center",
          sortable: false,
          value: "numero_devis",
          name: "numero_devis",
        },
        {
          text: "MONTANT",
          align: "center",
          sortable: false,
          value: "montant",
        },
        {
          text: "DATE DEVIS",
          align: "center",
          sortable: true,
          value: "date_devis",
          name: "date_devis",
        },
        {
          text: "TYPOLOGIE",
          align: "center",
          sortable: true,
          value: "typologie",
          name: "typologie",
        },
        {
          text: "STATUT",
          align: "center",
          sortable: true,
          value: "statut",
          name: "statut",
        }
      ],
    }
  },

  methods: {
    getDevis(id = null,nb_display,page,column,order) {
      api.getDevis(id,page,nb_display,order,column).then((res) => {
        this.devis = res.data.devis.rows;
        this.totalItems = res.data.devis.totalItems;
        this.totalPages = res.data.devis.totalPages;
        console.log(this.devis, 'oui', res.data);
      });
    },
    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        this.column = $event.column;
        if ( this.column == "Entreprise.raison_sociale"){
          this.column = "raison_sociale"
        }
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getDevis(
        this.$store.state.AsUser.id_utilisateur
          ? this.$store.state.AsUser.id_utilisateur
          : "",
          this.page - 1,
          process.env.VUE_APP_NB_DISPLAY,
          this.order,
          this.column
 
        
      );
      // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },

    setDevisDevisOverlay($event) {
      this.dialog = true;
      // Appel Api 
      this.numero_devis = $event;

      console.log($event, "event devis")
    },

    closeAndRefreshDevis($event) {
      this.dialog = $event;
      this.getDevis(
      this.$store.state.AsUser.id_utilisateur
        ? this.$store.state.AsUser.id_utilisateur
        : "", this.page -1, process.env.VUE_APP_NB_DISPLAY
      );
    }
  },
  created() {
    console.log("created de devis");
    this.getDevis(
      this.$store.state.AsUser.id_utilisateur
        ? this.$store.state.AsUser.id_utilisateur
        : "", this.page -1, process.env.VUE_APP_NB_DISPLAY
    );
  },
}
</script>

<style lang="scss">
#devis {
  .v-data-table {
    width: 100%;
    margin-top: 5rem;
  }
  th {
    font-size: 1em;
  }

  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child {
    padding-bottom: 15px;

    > th {
      border-bottom: none;
      border-right: 1px solid grey;
      height: auto !important;
    }
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th:last-child {
    border-bottom: none;
    border-right: 0px solid grey;
  }
  table > thead > tr > th span {
    width: 100%;
    padding: auto;
    margin: auto;
    padding-right: 1em;
  }
  .v-data-table-header th.active .v-data-table-header__icon,
  .v-data-table-header th .v-data-table-header__icon {
    opacity: 1;
    color: black;
  }
  .v-data-table-header::after {
    height: 1em;
    display: table-row;
    content: "";
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td {
    font-size: 0.9em;
  }
}

th:nth-child(2) {
  width: auto !important;
}
</style>
