import axios from 'axios';

const RESSOURCE_NAME = 'commandes';

export default {

    getRetards(idUser, column="date_livraison_demandee",order="DESC",page=0){
        return axios.get(`${RESSOURCE_NAME}/retards`+(idUser?'/'+idUser:'')+`?page=`+page+`&size=`+process.env.VUE_APP_NB_DISPLAY+`&orderBy=`+column+`&sortBy=`+order+``)
    }

}