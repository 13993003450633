<template>
  <v-container id="home" fluid class="py-0">
    <v-row no-gutter>
      <v-col cols="3" class="background_color" md="5" lg="2" sm="5">
        <Rappel @addRappel="overlay = true" />
      </v-col>
      <v-col cols="4" class="background_color_search pa-0" md="7" lg="4" sm="7">
        <!-- <v-row> -->
        <v-col cols="12">
          <Search />
        </v-col>
        <!-- <v-col cols="12"> -->
        <!-- </v-row> -->
        <Carte :mode="'home'" :query="''" />
        <!-- </v-col> -->
      </v-col>
      <v-col cols="3" class="" lg="3" md="6" sm="12">
        <!-- <div class="container border" cols="3"> -->
        <Devis />
        <!-- </div> -->
      </v-col>
      <v-col cols="2" class="background_color chiffre" md="6" lg="3" sm="12">
        <Chiffres />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <iframe
          src="http://localhost:1234/public/dashboard/9289ee61-862b-47f0-aed0-ef64da1980e3"
          onload="iFrameResize({}, this)"
          allowtransparency=""
          id="iFrameResizer0"
          style="overflow: hidden; height: 1708px;"
          scrolling="no"
          width="100%"
          frameborder="0"
        ></iframe> -->

       
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Rappel from "./../components/Home/Rappel";
import Chiffres from "./../components/Home/Chiffres";
import Devis from "./../components/Home/Devis";
import Search from "./../components/Home/Search";
import Carte from "@/components/Home/Carte";

export default {
  name: "home",
  components: {
    Rappel,
    Chiffres,
    Devis,
    Search,
    Carte,
  },

  data() {
    return {
      overlay: false,
    };
  },
  methods: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
#home,
#home + .row {
  min-height: 87vh;
  height: 100%;
}
.background_color {
  background: linear-gradient(215deg, #0b87ba, #06445d);
}
.background_color_search {
  background-color: #0b87ba;
}

.color_white {
  color: white;
}
.color_orange {
  color: #f89903;
}

::v-deep h1,
h2 {
  font-size: 1.2em;
}
.background_color.col-sm-6.col-md-3.col-12 {
  padding-top: 0;
}
</style>
