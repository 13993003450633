<template lang="">
  <div>
    <iframe
      :src="url"
      frameborder="0"
      id="myiframe"
      width="100%"
      allowtransparency
      v-resize="{ log: true }"
    ></iframe>
    <!-- http://localhost:1234/public/dashboard/3330ba05-8503-494f-a839-01e46c541c87 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      url:
        process.env.VUE_APP_REPORTING_URL +
        "public/dashboard/3330ba05-8503-494f-a839-01e46c541c87?code_client=" +
        this.$store.state.entreprise.code_fta +
        "#hide_parameters=code_client",
    };
  },
};
</script>
<style lang=""></style>
