<template lang="">
    <div id="retours">



      <v-row no-gutters>

      <v-col cols="12" lg="12" xl="12">
        <v-toolbar color="#55ABCF" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            HISTORIQUE DES RETOURS
          </v-col>

        </v-toolbar>
      </v-col>
      
      </v-row>
      
                    <Popup :dialog="dialogDelete" text="Supprimer ce retour ?" actions="" heightProp="" widthProp="" showClose="false"> 
                <v-card-actions class="mb-10">
                  <v-btn class="actions" color="#0B87BA" width="100px"  large dark rounded @click="deleteItemConfirm">Oui</v-btn>
                  <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialogDelete = false">Non</v-btn>
                </v-card-actions> 
              </Popup>
      <v-row align="center">
        <v-col cols="12" lg="12" xl="12">
          <resultList
            @emitDialogRetour="dialogDelete = true, id_retour = $event"
            :header="header"
            :items="retours"
            :nbResult=displayItem
            :type="'contact'"
            @sort="sort"
            :totalItems="totalItems"
            :page="page"
            id="encours"
            :canModif="canModif"
            :closed="closed"
            
          />
        </v-col>
        <v-col v-if="nbPage>0" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-if="nbPage > 1"
              v-model="page"
              :length="nbPage"
              circle
            ></v-pagination>
          </div>

        </v-col>  
      </v-row>


     <!-- <Popup :dialog="dialog" :text="messageDelete" :actions="actions" @delete="deleteContact" @nothing="dialog=false" /> -->
    </div>
</template>
<script>
import Popup from "@/components/shared/Popup";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/entreprise";
import apiRetour from "@/api/retours";

// import Popup from "@/components/shared/Popup";

export default {
  components: { ResultList, Popup },
  data() {
    return {
      id: this.$route.params.id_entreprise,
      displayItem: process.env.VUE_APP_NB_DISPLAY,
      retours: [],
      dialogDelete: false,
      page: 1,
      column: "date_retour",
      order: "DESC",
      query: "",
      nbPage: 0,
      totalItems: 0,
      usersEntreprise:[],
      canModif:0,
      closed: true,
      //   dialog: false,
      //   messageDelete: "Souhaitez vous supprimer ce contact ?",
      actions: {
        // route: [
        //   {
        //     btn: "OUI",
        //     routeName: "client",
        //     params: { id_entreprise: this.$route.params.id_entreprise },
        //   },
        //   {
        //     btn: "NON",
        //     routeName: "client",
        //     params: { id_entreprise: this.$route.params.id_entreprise },
        //   },
        // ],
        // actions: [
        //   {
        //     btn: "OUI",
        //     event: "delete",
        //   },
        //   {
        //     btn: "NON",
        //     event: "nothing",
        //   },
        // ],
      },
      header: [
        {
          text: "DATE",
          align: "center",
          value: "date_retour",
          name: "date_retour",
        },
        {
          text: "NUMERO DE RETOUR",
          align: "center",
          sortable: false,
          value: "numero_retour",
          name: "numero_retour",
        },
        {
          text: "EMAIL DU CLIENT",
          align: "center",
                    sortable: false,
          value: "email_client",
          name: "email_client",
        },
        {
          text: "VALIDATION COMMERCIALE",
          align: "center",
          sortable: false,
          value: "validation_commercial",
          name: "validation_commercial",
        },
        {
          text: "DESIGNATION / CODE PRODUIT",
          align: "center",
          sortable: false,
          value: "articles",
          name: "articles",
        },
        {
          text: "TYPE DE RETOUR",
          align: "center",
          sortable: false,
          value: "type_retour",
          name: "type_retour",
        },
        {
          text: "DATE D'ENVOI AU PLUS TARD",
          align: "center",
          sortable: false,
          value: "date_envoi_limite",
          name: "date_envoi_limite",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_retour",
          name: "actions_retour",
        },
      ],
    };
  },
  methods: {
    getRetourEntreprise(query, id_client, page, column, order) {
      api
        .getEntrepriseRetoursClosed(query, id_client, page, column, order)
        .then((res) => {
                    //   console.log(res.data.retours.rows[0].Retours,res.data.retours.rows[0].Retours.TypeRetour,res.data.retours.rows[0].Retours.id_retour);

          res.data.retours.rows.forEach(retour => {
              retour['type_retour'] = retour.TypeRetour.type_retour
          });
          console.log(res.data.retours.rows);
            this.retours = res.data.retours.rows;
            this.nbPage = res.data.retours.totalPages;
            this.totalItems = res.data.retours.totalItems;
             if(this.usersEntreprise.length == 0  ){
              console.log('aiiiii')
            res.data.entreprise.Users.forEach(user => {
              this.usersEntreprise.push(user.id_utilisateur)
            })
              this.CanModified()
          }
        });
    },
    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getRetourEntreprise(
        this.query,
        this.id,
        this.page - 1,
        this.column,
        this.order
      );
    },
    deleteContact() {
      console.log("contact à supprimer", this.idDeleteContact);
      api.archivePersonne(this.idDeleteContact).then((res) => {
        if (res.status == 200) {
          this.dialog = false;
          this.getRetourEntreprise(
            this.query,
            this.id,
            this.page - 1,
            this.column,
            this.order
          );
        }
      });
    },
    CanModified(){
      if(
      (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 1 ||  (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 4 || this.usersEntreprise.includes(this.$store.state.AsUser.id_utilisateur == '' ?this.$store.state.user.id_utilisateur : this.$store.state.AsUser.id_utilisateur) )
      {
        this.canModif = 1
      }
      console.log(this.canModif,'can modif');
    },
        deleteItemConfirm() {
      apiRetour.deleteRetour(this.id_retour).then((res) => {
        console.log(res)
          this.getRetourEntreprise(
            this.query,
            this.id,
            this.page - 1,
            this.column,
            this.order
          );
        this.dialogDelete = false
      });
    }
  },
  created() {
    this.getRetourEntreprise(
      this.query,
      this.id,
      this.page - 1,
      this.column,
      this.order
    );
  },
};
</script>
<style lang="scss" scoped>
.btnBold {
  font-weight: bold;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}
</style>