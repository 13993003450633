var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"resultList",attrs:{"headers":_vm.header,"items":_vm.items,"items-per-page":_vm.nbResults,"options":_vm.options,"server-items-length":_vm.totalItems,"page":_vm.page,"hide-default-footer":"","no-data-text":"Aucune donnée disponible"},on:{"update:options":function($event){_vm.options=$event},"click:row":function($event){return _vm.$emit('row', { type: _vm.type, data: $event })}},scopedSlots:_vm._u([{key:"header.civilite",fn:function(){return [_c('span',{},[_vm._v("CIVILITÉ ")])]},proxy:true},{key:"header.prenom",fn:function(){return [_c('span',{},[_vm._v("PRÉNOM ")])]},proxy:true},{key:"header.actionsRetour",fn:function(){return [_c('th',{staticClass:"actionsRetour",staticStyle:{"width":"100px"}},[_c('span',[_vm._v("ACTIONS ")])])]},proxy:true},(_vm.type == 'devis')?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"test template",class:_vm.customBackground(item)},[_c('td',[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.$emit('emitPopupDevis', item.numero_devis)}}},[_vm._v(_vm._s(item.raison_sociale))])]),_c('td',[_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.numero_devis))])]),_c('td',[_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.montant))])]),_c('td',[_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.date_devis ? _vm.$moment(item.date_devis).format("DD/MM/YYYY") : ""))])]),_c('td',[_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.typologie))])]),_c('td',[_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.statut))])])])]}}:null,{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.createdAt ? _vm.$moment(item.createdAt).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_projet",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(_vm._s(item.date_projet ? _vm.$moment(item.date_projet).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_retour",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_retour ? _vm.$moment(item.date_retour).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_envoi_limite",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_envoi_limite ? _vm.$moment(item.date_envoi_limite).format("DD/MM/YYYY") : "")+" "),(_vm.$moment(item.date_envoi_limite).isBefore(_vm.$moment().toString()) && _vm.closed !== true)?_c('span',[_c('v-icon',{staticClass:"fa_color_warning"},[_vm._v(" fa fa-exclamation-triangle ")])],1):_vm._e()])]}},{key:"item.date_rapport",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_rapport ? _vm.$moment(item.date_rapport).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.description.substring(0,100)+"...")+" ")])]}},{key:"item.date_devis",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_devis ? _vm.$moment(item.date_devis).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_comptabilisee",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_comptabilisee ? _vm.$moment(item.date_comptabilisee).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_cloture",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_cloture ? _vm.$moment(item.date_cloture).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_document",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_document ? _vm.$moment(item.date_document).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_livraison_demandee",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_livraison_demandee ? _vm.$moment(item.date_livraison_demandee).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.date_livraison_confirmee",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sort"},[_vm._v(" "+_vm._s(item.date_livraison_confirmee ? _vm.$moment(item.date_livraison_confirmee).format("DD/MM/YYYY") : "")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'view_projet',
        params: {
          id_entreprise: item.id_entreprise,
          id_projet: item.id_projet,
        },
      }}},[(item.canModif != 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")]):_vm._e()],1),_c('router-link',{attrs:{"to":{
        name: 'update_projet',
        params: {
          id_entreprise: item.id_entreprise,
          id_projet: item.id_projet,
        },
      }}},[(item.canModif == 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")]):_vm._e()],1)]}},{key:"item.actions_bloc_fores",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: 'view_projet',
        params: {
          id_entreprise: item.id_entreprise,
          id_projet: item.id_projet,
        },
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1),_c('router-link',{attrs:{"to":{
        name: 'update_projet',
        params: {
          id_entreprise: item.id_entreprise,
          id_projet: item.id_projet,
        },
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1)]}},{key:"item.raison_sociale",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"bold"},[_vm._v(_vm._s(item.raison_sociale.toUpperCase()))])]}},{key:"item.Entreprise.raison_sociale",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"bold"},[_vm._v(_vm._s(item.Entreprise.raison_sociale.toUpperCase()))])]}},{key:"item.nom_projet",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"bold"},[_vm._v(_vm._s(item.nom_projet.toUpperCase()))])]}},{key:"item.bloc_fore",fn:function(ref){
      var item = ref.item;
return [_c('span',[(item.bloc_fore)?_c('img',{staticStyle:{"width":"25px","height":"25px"},attrs:{"src":require("@/assets/bloc_fore.png")}}):_vm._e()])]}},{key:"item.client",fn:function(ref){
      var item = ref.item;
return _vm._l((item.client),function(c,i){return _c('div',{key:i},[_c('span',{},[_vm._v(_vm._s(c ? c.toUpperCase() : ""))])])})}},{key:"item.validation_commercial",fn:function(ref){
      var item = ref.item;
return [(item.validation_commercial)?_c('div',[_c('v-icon',{staticClass:"fa_color_check"},[_vm._v(" fas fa-check ")])],1):_vm._e()]}},{key:"item.entierement_expedie",fn:function(ref){
      var item = ref.item;
return [(item.entierement_expedie)?_c('div',[_c('v-icon',{staticClass:"fa_color_check"},[_vm._v(" fas fa-check ")])],1):_vm._e()]}},{key:"item.appel",fn:function(ref){
      var item = ref.item;
return [(item.appel)?_c('div',[_c('v-icon',{staticClass:"fa_color_check"},[_vm._v(" fas fa-check ")])],1):_vm._e()]}},{key:"item.visite",fn:function(ref){
      var item = ref.item;
return [(item.visite)?_c('div',[_c('v-icon',{staticClass:"fa_color_check"},[_vm._v(" fas fa-check ")])],1):_vm._e()]}},{key:"item.action_a_realiser",fn:function(ref){
      var item = ref.item;
return [(item.action_a_realiser)?_c('div',[_c('v-icon',{staticClass:"fa_color_check"},[_vm._v(" fas fa-check ")])],1):_vm._e()]}},{key:"item.personnes",fn:function(ref){
      var item = ref.item;
return [(item.personnes)?_c('div',_vm._l((item.Personnes),function(personne){return _c('div',{key:personne.id_personne},[_vm._v(" "+_vm._s(personne.prenom + " " + personne.nom.toUpperCase())+" ")])}),0):_vm._e()]}},{key:"item.articles",fn:function(ref){
      var item = ref.item;
return [(item.articles)?_c('div',_vm._l((item.articles),function(article){return _c('div',{key:article.id_article_retour},[_vm._v(" "+_vm._s(article.article + " " + article.reference)+" ")])}),0):_vm._e()]}},{key:"item.personnes_rapport",fn:function(ref){
      var item = ref.item;
return [(item.Personnes)?_c('div',_vm._l((item.Personnes),function(personne){return _c('div',{key:personne.id_personne},[_vm._v(" "+_vm._s(personne.prenom + " " + personne.nom.toUpperCase())+" ")])}),0):_vm._e()]}},{key:"item.projet_obtenu",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.projet_obtenu == true ? "OUI" : "NON")+" ")])]}},{key:"item.documents",fn:function(ref){
      var item = ref.item;
return [(_vm.type !== 'projets')?_c('div',{staticClass:"doc"},_vm._l((item.Documents),function(doc,i){return _c('span',{key:doc.id_document},[_c('a',{class:{ 'mr-1': i < item.Documents.length - 1 },attrs:{"href":doc.chemin,"target":"_blanck"}},[(doc.chemin.split('.').pop() == 'pdf')?_c('img',{attrs:{"src":require("@/assets/pdf.png"),"alt":""}}):(
              doc.chemin.split('.').pop().substring(0, 3) == 'doc' ||
              doc.chemin.split('.').pop().substring(0, 3) == 'txt'
            )?_c('img',{attrs:{"src":require("@/assets/doc.png"),"alt":""}}):(
              doc.chemin.split('.').pop().substring(0, 3) == 'xls' ||
              doc.chemin.split('.').pop().substring(0, 3) == 'csv'
            )?_c('img',{attrs:{"src":require("@/assets/excel.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img.png"),"alt":""}})])])}),0):_vm._e(),(_vm.type == 'projets')?_c('span',[_vm._v(" "+_vm._s(item.Documents.length > 0 ? "OUI" : "NON")+" ")]):_vm._e()]}},{key:"item.dev",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.dev),callback:function ($$v) {_vm.$set(item, "dev", $$v)},expression:"item.dev"}})]}},{key:"item.cahier_des_charges",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.cahier_des_charges),callback:function ($$v) {_vm.$set(item, "cahier_des_charges", $$v)},expression:"item.cahier_des_charges"}})]}},{key:"item.conception",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.conception),callback:function ($$v) {_vm.$set(item, "conception", $$v)},expression:"item.conception"}})]}},{key:"item.chiffrage",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.chiffrage),callback:function ($$v) {_vm.$set(item, "chiffrage", $$v)},expression:"item.chiffrage"}})]}},{key:"item.fao",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.fao),callback:function ($$v) {_vm.$set(item, "fao", $$v)},expression:"item.fao"}})]}},{key:"item.production_proto",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.production_proto),callback:function ($$v) {_vm.$set(item, "production_proto", $$v)},expression:"item.production_proto"}})]}},{key:"item.production_pre_serie",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"info","disabled":""},model:{value:(item.production_pre_serie),callback:function ($$v) {_vm.$set(item, "production_pre_serie", $$v)},expression:"item.production_pre_serie"}})]}},(
      this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
    )?{key:"item.produit_a_developper",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{attrs:{"color":"primary"},on:{"change":function($event){return _vm.$emit('dataChange', {
            produit: item.produit,
            value: $event,
            field: 'developer',
            id: item.id_achat_hydraulique,
            item: item,
          })}},model:{value:(item.produit_a_developper),callback:function ($$v) {_vm.$set(item, "produit_a_developper", $$v)},expression:"item.produit_a_developper"}}),_c('div',[_vm._v(_vm._s(item.produit_a_developper ? "OUI" : "NON"))])],1)]}}:null,(
      this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
    )?{key:"item.volume",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex row"},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"pl-0 pb-0 pt-6 pr-0",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Nombre","outlined":""},on:{"change":function($event){return _vm.$emit('dataChange', {
              produit: item.produit,
              value: $event,
              field: 'volume',
              id: item.id_achat_hydraulique,
              item: item,
            })}},model:{value:(item.volume),callback:function ($$v) {_vm.$set(item, "volume", $$v)},expression:"item.volume"}})],1),_c('v-col',{attrs:{"cols":"2"}})],1)]}}:null,(
      this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
    )?{key:"item.marque_concurrence",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex row"},[_c('v-col',{staticClass:"pl-0 pb-0 pt-6 pr-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"placeholder":"Marque","outlined":""},on:{"change":function($event){return _vm.$emit('dataChange', {
              produit: item.produit,
              value: $event,
              field: 'marque_concurrence',
              id: item.id_achat_hydraulique,
              item: item,
            })}},model:{value:(item.marque_concurrence),callback:function ($$v) {_vm.$set(item, "marque_concurrence", $$v)},expression:"item.marque_concurrence"}})],1)],1)]}}:null,(
      this.$route.name == 'prospect' || this.$route.name == 'modifier_client'
    )?{key:"item.fta",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{attrs:{"color":"primary"},on:{"change":function($event){return _vm.$emit('dataChange', {
            produit: item.produit,
            value: $event,
            field: 'fta',
            id: item.id_achat_hydraulique,
            item: item,
          })}},model:{value:(item.fta),callback:function ($$v) {_vm.$set(item, "fta", $$v)},expression:"item.fta"}}),_c('div',[_vm._v(_vm._s(item.fta ? "OUI" : "NON"))])],1)]}}:null,{key:"item.actions_contact",fn:function(ref){
          var item = ref.item;
return _vm._l((item.actions_contact),function(action,i){return _c('div',{key:i,staticClass:"inline"},[(action.type == 'update')?_c('router-link',{attrs:{"to":{ name: action.params.name, params: action.params.params }}},[(_vm.canModif == 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.$emit('delete', action.id_contact)}}},[(action.type == 'delete')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" fa fa-trash ")]):_vm._e()],1)],1)})}},{key:"item.actions_projet",fn:function(ref){
          var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showProjet(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.editProjet(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1):_vm._e()]}},{key:"item.actions_projet_closed",fn:function(ref){
          var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showProjet(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.editProjet(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1):_vm._e(),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.SetProjetEncours(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"orange darken-2","eye":""}},[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}},{key:"item.actionsRapportEntreprise",fn:function(ref){
          var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showRapport(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.editRapport(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1):_vm._e(),_c('span',{on:{"click":function($event){return _vm.$emit('emitDialogRapport', item.id_rapport)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","trash":""}},[_vm._v(" fa-trash ")])],1)]}},{key:"item.actionsRetour",fn:function(ref){
          var item = ref.item;
return [(_vm.canModif != 1)?_c('span',{on:{"click":function($event){return _vm.showRetour(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1):_vm._e(),(_vm.canModif == 1)?_c('router-link',{attrs:{"to":{ name: 'update_retour',
      params: {
        id_entreprise: item.Entreprise.id_entreprise,
        id_retour: item.id_retour }
        }}},[(_vm.canModif == 1)?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit "+_vm._s(item.Entreprise.id_entreprise)+" "+_vm._s(item.id_retour)+" ")])],1):_vm._e()]):_vm._e(),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.$emit('emitDialogRetour', item.id_retour)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","trash":""}},[_vm._v(" fa-trash ")])],1):_vm._e()]}},{key:"item.actions_retour",fn:function(ref){
        var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showRetour(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.editRetour(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1):_vm._e(),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.$emit('emitDialogRetour', item.id_retour)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","trash":""}},[_vm._v(" fa-trash ")])],1):_vm._e()]}},{key:"item.actions_litige",fn:function(ref){
        var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.editLitige(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1)]}},{key:"item.actions_pret",fn:function(ref){
        var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showPret(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.editPret(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa fa-edit ")])],1):_vm._e(),(_vm.canModif == 1)?_c('span',{on:{"click":function($event){return _vm.$emit('emitDialogPret', item.numero_commande)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","trash":""}},[_vm._v(" fa-trash ")])],1):_vm._e()]}},{key:"item.actions_pret_closed",fn:function(ref){
        var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.showPret(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","eye":""}},[_vm._v(" fa-eye ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }