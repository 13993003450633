<template>
  <div id="prets_clients">
    <Title :titlePage="titlePage" />
    <!-- PRETS EN COURS -->
    <v-container fluid class="mt-8" style="width: 97%">
      <v-row no-gutters>
        <v-col cols="12" lg="12" xl="12">
          <v-toolbar color="#0B87BA" flat dense>
            <v-col cols="6" lg="6" xl="2" class="pa-0 font_color text-left">
                PRETS EN COURS
              </v-col>
          </v-toolbar>
        </v-col>
      </v-row>

              <Popup :dialog="dialogDelete" text="Supprimer ce prêt ?" actions="" heightProp="" widthProp="" showClose="false"> 
                <v-card-actions class="mb-10">
                  <v-btn class="actions" color="#0B87BA" width="100px"  large dark rounded @click="deleteItemConfirm">Oui</v-btn>
                  <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialogDelete = false">Non</v-btn>
                </v-card-actions> 
              </Popup>

      <v-row align="center mt-10">
        <v-col cols="12" lg="12" xl="10">
          <resultList
            @emitDialogPret="dialogDelete = true, numero_commande = $event"
            :header="headerPretsClients"
            :items="prets_clients"
            :nbResult="5"
            :type="'prets_clients'"
            @sort="sort"
            :totalItems="totalItems"
            :canModif="canModif"
            :page="page"
          />
        </v-col>
        <v-col v-if="totalPages > 1" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="5"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- HISTORIQUE DES PRETS -->
        <v-container fluid class="mt-8" style="width: 97%">
      <v-row no-gutters>
        <v-col cols="12" lg="12" xl="12">
          <v-toolbar color="#55ABCF" flat dense>
            <v-col cols="6" lg="6" xl="2" class="pa-0 font_color text-left">
                HISTORIQUE DES PRETS
              </v-col>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row align="center mt-10">
        <v-col cols="12" lg="12" xl="10">
          <resultList
            :header="headerPretsClientsClosed"
            :items="prets_clients_closed"
            :nbResult="5"
            :type="'prets_clients'"
            @sort="sortClosed"
            :totalItems="totalItemsClosed"
            :page="pageClosed"
          />
        </v-col>
        <v-col v-if="totalPagesClosed > 1" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-model="pageClosed"
              :length="totalPagesClosed"
              :total-visible="5"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import Title from "@/components/shared/Title";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/litiges.js";
import Popup from "@/components/shared/Popup";


export default {
  name: "prets_clients",
  components: { Title, ResultList, Popup },
  data() {
    return {
      titlePage: "prets clients",
      prets_clients: [],
      prets_clients_closed: [],
      totalItems: 0,
      totalPages: 0,
      page: 1,
      totalItemsClosed: 0,
      totalPagesClosed: 0,
      pageClosed: 1,
      canModif:0,
      dialogDelete: false,
      numero_commande: '',
      headerPretsClients: [
        {
          text: "RAISON SOCIALE",
          align: "center",
          sortable: true,
          value: "raison_sociale",
        },
        {
          text: "NUMERO DE COMMANDE",
          align: "center",
          sortable: true,
          value: "numero_commande",
        },
        {
          text: "CODE VENDEUR",
          align: "center",
          sortable: true,
          value: "code_itinerant",
          name: "code_itinerant",
        },
        {
          text: "DATE DE PRET",
          align: "center",
          sortable: true,
          value: "date_comptabilisee",
          name: "date comptabilisee",
        },
        {
          text: "DATE DE CLOTURE",
          align: "center",
          sortable: true,
          value: "date_cloture",
          name: "date_cloture"
        },
        {
          text: "STATUT",
          align: "center",
          sortable: true,
          value: "statut_pret_client",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_pret",
        },
      ],
      headerPretsClientsClosed: [
        {
          text: "RAISON SOCIALE",
          align: "center",
          sortable: true,
          value: "raison_sociale",
        },
        {
          text: "NUMERO DE COMMANDE",
          align: "center",
          sortable: true,
          value: "numero_commande",
        },
        {
          text: "CODE VENDEUR",
          align: "center",
          sortable: true,
          value: "code_itinerant",
          name: "code_itinerant",
        },
        {
          text: "DATE DE PRET",
          align: "center",
          sortable: true,
          value: "date_comptabilisee",
          name: "date comptabilisee",
        },
        {
          text: "DATE DE CLOTURE",
          align: "center",
          sortable: true,
          value: "date_cloture",
          name: "date_cloture"
        },
        {
          text: "STATUT",
          align: "center",
          sortable: true,
          value: "statut_pret_client",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_pret_closed",
        },
      ],
    };
  },
  methods: {
    CanModified(){
      if(
      (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 1 ||  (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role) == 3 || this.usersEntreprise.includes(this.$store.state.AsUser.id_utilisateur == '' ?this.$store.state.user.id_utilisateur : this.$store.state.AsUser.id_utilisateur) )
      {
        this.canModif = 1
      }
      console.log(this.canModif,'can modif');
    },

    getPretsClients(column, order, page) {
      api.getPretsClients(column, order, page).then((res) => {
        this.prets_clients = res.data.prets.rows;
        this.prets_clients.forEach((element) => {
          if ( element.Litige != null) {
            element.Litige.createdAt = this.$moment(element.Litige.createdAt).format("DD/MM/YYYY") 
          }
      })
        this.totalItems = res.data.prets.totalItems;
        this.totalPages = res.data.prets.totalPages;
        this.CanModified()

      });
    },

    sort($event) {
      if ($event.column != undefined) {
        console.log($event.column);
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getPretsClients(this.column, this.order, this.page - 1);
      // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },

    deleteItemConfirm() {
      // alert(this.numero_commande)
      // this.dialogDelete = false;
      api.deletePretClient(this.numero_commande).then((res) => {
        console.log(res, "res delete pret")
        this.getPretsClients();
        this.dialogDelete = false
      });
    },

    // CLOSED

    getPretsClientsClosed(column, order, page) {
      api.getPretsClients(column, order, page, 1).then((res) => {
        this.prets_clients_closed = res.data.prets.rows;
        this.prets_clients_closed.forEach((element) => {
          if ( element.Litige != null) {
            element.Litige.createdAt = this.$moment(element.Litige.createdAt).format("DD/MM/YYYY") 
          }
      })
        this.totalItemsClosed = res.data.prets.totalItems;
        this.totalPagesClosed = res.data.prets.totalPages;
      });
    },
    sortClosed($event) {
      if ($event.column != undefined) {
        console.log($event.column);
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.pageClosed = $event.page;
      this.getPretsClientsClosed(this.column, this.order, this.pageClosed - 1);
      // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },
  },
  created() {
    this.getPretsClients();
    this.getPretsClientsClosed();
  },
};
</script>

<style lang="scss" scoped>

.font_color {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

</style>
