<template lang="">

      <!-------------------------------- DEMANDE D'AVOIR -------------------------------->
      <div v-if="retour.id_type_retour == 2">
            <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left "
          >
            DEMANDE D'AVOIR
          </v-col>
        </v-toolbar>
          </v-col>

                  <v-col cols="12" sm="4" class="pb-0  my-5">
              <div class="text-left">AVOIR<span v-if="mode !='creation'">*</span>
              </div>

        <v-select
           v-if="mode !== 'visualisation'"
          :items="valeurSelect"
          :item-text="valeurSelect.text"
          :item-value="valeurSelect.value"
          v-model="demandeDavoir.avoir.valeur"
          item-color='primary'
          class="text-color mt-3"
          hide-details
          solo
        ></v-select>
          <div v-if="mode == 'visualisation'" :class="trueOrFalse(demandeDavoir.avoir.valeur)">{{demandeDavoir.avoir.valeur == "true" ? "accepté" : "refusé"}}</div>
          <div class="errorMsg" v-if="$v.demandeDavoir.avoir.valeur.$error">
            Renseignez produit complet
          </div>
            </v-col>

   <!-------------------------------------- AVOIR ACCEPTE ------------------------------------->
  <div  v-if="demandeDavoir.avoir.valeur == 'true'">
    <v-col cols="12" sm="4" class="pb-0 ">
      <div class="text-left">DECOTE<span v-if="mode !='creation'">*</span>
      </div>
      <v-text-field v-if="mode !== 'visualisation'"  class="mt-3" maxLength=100 single-line outlined v-model="demandeDavoir.decote_a_ajouter.valeur" dense hide-details  ></v-text-field>
      <div  v-if="mode == 'visualisation'" class="text-left font-weight-bold">{{demandeDavoir.decote_a_ajouter.valeur}} </div>

          <div class="errorMsg" v-if="$v.demandeDavoir.decote_a_ajouter.valeur.$error && ($v.demandeDavoir.decote_a_ajouter.valeur.required == false)">
            Renseignez une valeur
          </div>

          <div class="errorMsg" v-if="$v.demandeDavoir.decote_a_ajouter.valeur.$error && ($v.demandeDavoir.decote_a_ajouter.valeur.required == true && $v.demandeDavoir.decote_a_ajouter.valeur.maxLength == false) ">
            20 caractères au maximum.
          </div>
    </v-col>


    <v-col cols="12" sm="6" class="pb-0  mt-5">
      <div class="text-left mt-2">PRODUIT NEUF ET COMPLET<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="demandeDavoir.produit_neuf_et_complet.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="produitneufetcomplet(demandeDavoir.produit_neuf_et_complet.valeur)">{{demandeDavoir.produit_neuf_et_complet.text}}</div>
                <div class="errorMsg" v-if="$v.demandeDavoir.produit_neuf_et_complet.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>

        <v-col cols="12" sm="6" class="pb-0  mt-5">
      <div class="text-left mt-2">PRODUIT REMIS EN STOCK<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="demandeDavoir.produit_remis_en_stock.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
        <div v-if="mode == 'visualisation'" :class="produitremisenstock(demandeDavoir.produit_remis_en_stock.valeur)">{{demandeDavoir.produit_remis_en_stock.text}}</div>
          <div class="errorMsg" v-if="$v.demandeDavoir.produit_remis_en_stock.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>
  </div>
   <!-------------------------------------- AVOIR ACCEPTE ------------------------------------->
      <!-------------------------------------- AVOIR REFUSE ------------------------------------->

  <div  v-if="demandeDavoir.avoir.valeur == 'false'">
    <v-col cols="12" sm="6" class="pb-0 ">
      <div class="text-left mt-2">PRODUIT USAGE<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'" 
          v-model="demandeDavoir.produit_usage.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="produitusage(demandeDavoir.produit_usage.valeur)">{{demandeDavoir.produit_usage.valeur == "true" ? "OUI" : "NON"}}</div>
                <div class="errorMsg" v-if="$v.demandeDavoir.produit_usage.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>

        <v-col cols="12" sm="6" class="pb-0 ">
      <div class="text-left mt-2">PRODUIT INCOMPLET<span v-if="mode !='creation'">*</span></div>
        <v-radio-group
          v-if="mode !== 'visualisation'"
          v-model="demandeDavoir.produit_incomplet.valeur"
          row
          hide-details
          class="mt-2"
        >
          <v-radio
            value="true"
          >
            <template v-slot:label>
              <div><strong class="success--text">OUI</strong></div>
            </template>
          </v-radio>
          <v-radio value="false"
          >        
            <template v-slot:label>
              <div><strong class="error--text">NON</strong></div>
            </template>
          </v-radio>
        </v-radio-group>
          <div v-if="mode == 'visualisation'" :class="produitincomplet(demandeDavoir.produit_incomplet.valeur)">{{demandeDavoir.produit_incomplet.text}}</div>
          <div class="errorMsg" v-if="$v.demandeDavoir.produit_incomplet.valeur.$error">
            Renseignez une valeur
          </div>
    </v-col>
  </div>
   <!-------------------------------------- AVOIR REFUSE ------------------------------------->


      </div>
      <!-------------------------------- ERREUR FTA -------------------------------->
</template>
<script>
import { required, requiredIf, maxLength} from 'vuelidate/lib/validators'
import moment from "moment";



export default {
props: {
  retour: Object,
  demandeDavoir: Object,
  mode: [String]
},
  components: {
  },
  data() {
    return {
      valeurSelect: [
        {
          text: "accepté",
          value: "true"
        },
        {
          text: "refusé",
          value: "false"
        },
      ]      
    };
  },
    validations: {
      demandeDavoir: {
      avoir: {
        valeur: {
          required
        }
      },
      decote_a_ajouter: {
        valeur: {
         required: requiredIf(function(){ return this.conditionValidation("true") && this.mode != "creation" }),
         maxLength: maxLength(20)
        }
      },
      produit_neuf_et_complet: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("true") && this.mode != "creation" })
        }
      },
      produit_remis_en_stock: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("true") && this.mode != "creation" })
        }
      },
      produit_usage: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("false") && this.mode != "creation" })
        }
      },
      produit_incomplet: {
        valeur: {
          required: requiredIf(function(){ return this.conditionValidation("false") && this.mode != "creation" })
        }
      }
    }
  },
  computed: {
    formatDate() {
      return this.demandeDavoir.date_retour
        ? moment(this.demandeDavoir.date_retour).format("L")
        : "";

    }
  },
  methods: {
    conditionValidation(valeur){
      if ( this.demandeDavoir.avoir.valeur == valeur) {
        return true
      }

    },
    trueOrFalse(value) {
      if ( value == "true" ) {
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        return "text-left mb-2 font-weight-bold error--text"
      }

    },
    produitneufetcomplet(value) {
      if ( value == "true" ) {
        this.demandeDavoir.produit_neuf_et_complet.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.demandeDavoir.produit_neuf_et_complet.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.demandeDavoir.produit_neuf_et_complet.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },
    produitremisenstock(value) {
      if ( value == "true" ) {
        this.demandeDavoir.produit_remis_en_stock.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.demandeDavoir.produit_remis_en_stock.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.demandeDavoir.produit_remis_en_stock.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },
    produitusage(value) {
      if ( value == "true" ) {
        this.demandeDavoir.produit_usage.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.demandeDavoir.produit_usage.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.demandeDavoir.produit_usage.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },
    produitincomplet(value) {
      if ( value == "true" ) {
        this.demandeDavoir.produit_incomplet.text = "OUI"
        return "text-left mb-2 font-weight-bold success--text"
      }

      if ( value == "false" ) {
        this.demandeDavoir.produit_incomplet.text = "NON"
        return "text-left mb-2 font-weight-bold error--text"
      }

      if ( value == null ) {
        this.demandeDavoir.produit_incomplet.text = "non renseigné"
        return "text-left mb-2 font-weight-bold"
      }
    },


  },
  mounted() {
  }
};
</script>
<style scoped>
.calendar-field{
      width: 20%;
}

.date-letter {
  font-size: 15px !important
}

.text-modal{
    color: black;
    font-weight: normal;
    font-size: large;
}

::v-deep .v-select__selection.v-select__selection--comma {
	color: #0B87BA;
  font-style: italic;
}

::v-deep .v-icon.notranslate.mdi.mdi-menu-down {
	color: #0B87BA !important;
	font-size: 2.5em;
}



.card_border.v-card.v-sheet.theme--light {
	border-radius: 15px;
}

.btn_choice {
  min-width: 110px !important;
  background-color: #0b87ba;
  padding: 1.2em 3em;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

img {
  width:29px;
  height: 28px
}

.font_color {
  font-weight: bold;
  color: #FFFFFF 
}

.width-50 {
  width: 30%
}

.addPersonne {
  border-style: dashed ;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}




</style>
