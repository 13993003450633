import axios from 'axios';

const RESSOURCE_NAME = 'entreprises';

export default {
    getUserEntreprise(idEntreprise){
        return axios.get(`${RESSOURCE_NAME}`+(idEntreprise?'/'+idEntreprise:''))
    },
    archiverEntreprise(idEntreprise){
        console.log("console archiver entreprise", idEntreprise)
        return axios.get(`${RESSOURCE_NAME}/active`+(idEntreprise?'/'+idEntreprise:''))
    },
    getEntreprises(size = process.env.VUE_APP_NB_DISPLAY, query = "", clients = true, prospects = true, visite = true, noVisite = true, projets = true, noProjet = true, page = 0, column = "raison_sociale", order = "ASC") {
     
        console.log(query,'query in api')
        return axios.post(`${RESSOURCE_NAME}/search?page=${page}&size=${size}&orderBy=${column}&sortBy=${ order }`, { query: query, adresse: '', prospects: prospects, clients: clients, sansProjets: noProjet, avecProjets: projets, avecVisites: visite, sansVisites: noVisite })
    },
    createProspect(e){
        console.log("creation d'un prospect", e)
        return axios.post(`${RESSOURCE_NAME}/create`,{prospect:e})
    },
    updateProspect(id,e){
        console.log("modification d'un prospect", e)
        return axios.put(`${RESSOURCE_NAME}/update/`+id,{client:e})
    },
    criteresAchat(){
        console.log("critères achat")
        return axios.get(`/criteresachat`)
    },
    achatsHydraulique(){
        console.log("achat hydraulique")
        return axios.get(`/achatshydraulique`)
    },
    getEntrepriseProjetsActive(idEntreprise,query="",column="date_projet",order="DESC",page=0){
        console.log("console projet_active", idEntreprise,query)
        return axios.post(`${RESSOURCE_NAME}/`+idEntreprise+`/projets/active?page=`+page+`&size=`+process.env.VUE_APP_NB_DISPLAY+`&orderBy=`+column+`&sortBy=`+order+``,{query:query})
    },
    getEntrepriseProjetsClosed(idEntreprise,query="",column="date_projet",order="DESC",page=0){
        console.log("console projet_closed", idEntreprise,query)
        return axios.post(`${RESSOURCE_NAME}/`+idEntreprise+`/projets/?page=`+page+`&size=`+process.env.VUE_APP_NB_DISPLAY+`&orderBy=`+column+`&sortBy=`+order+``,{query:query})
    },
    getEntrepriseRetours(query = "", idEntreprise, page = 0, column = 'date_retour', order = 'DESC') {
        return axios.post(`${RESSOURCE_NAME}/${idEntreprise}/retours/active?page=` + page + `&size=` + process.env.VUE_APP_NB_DISPLAY + `&orderBy=` + column + `&sortBy=` + order + ``, { query: query })
    },
    getEntrepriseRetoursClosed(query = "", idEntreprise, page = 0, column = 'date_retour', order = 'DESC') {
        return axios.post(`${RESSOURCE_NAME}/${idEntreprise}/retours?page=` + page + `&size=` + process.env.VUE_APP_NB_DISPLAY + `&orderBy=` + column + `&sortBy=` + order + ``, { query: query })
    },
    getProspectToClient(idEntreprise,data){
        return axios.post(`${RESSOURCE_NAME}/status/${idEntreprise}`,{code_client_fta: data})
    },
    getEntrepriseRapports(query = "",idEntreprise, page = 0, column = 'date_rapport', order = 'DESC') {
        console.log("ceci est le retour",idEntreprise)
        return axios.post(`${RESSOURCE_NAME}/${idEntreprise}/rapports?page=`+page+`&size=`+process.env.VUE_APP_NB_DISPLAY+`&orderBy=`+column+`&sortBy=`+order + ``, { query: query })
    },
    getEntrepriseDocumentsAndDirectory(path,id,mode,init,id_projet){
        console.log(mode)
        return axios.post(`${RESSOURCE_NAME}/directories/${id}`,{directory:path,mode:mode,init,id_projet})
    }
}