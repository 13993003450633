import axios from 'axios';

const RESSOURCE_NAME = 'rappels';

export default {
    closeRappel(idUser=null){
        return axios.post(`${RESSOURCE_NAME}/close`+(idUser?'/'+idUser:''))
    },
    createRappel(rappel){
        // console.log(rappel,'rappel api')
        return axios.post(`${RESSOURCE_NAME}/create`, rappel)
    },
    getRappelRapport(id_rapport){
        return axios.get(`${RESSOURCE_NAME}/rapport/${id_rapport}`)
       }
  
}