<template lang="">
  <div>
    <v-row class="mb-4 mt-0" align="center" align-self="center">
      <v-col cols="6" lg="7" xl="5" sm="7" align-self="center">
        <v-row class="" align="center" no-gutters>
          <v-col class="d-flex" cols="2" lg="4" align-self="center">
            <img class="logo_rappel" src="@/assets/rappel.png" />
          </v-col>
          <v-col cols="10" lg="8" align="left" align-self="center">
            <h1 class="color_white">RAPPELS</h1>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" lg="7" xl="7" sm="6" align="right" align-self="center">
        <v-btn
          class="btnBold"
          color="#FF9900"
          dark
          rounded
          @click="overlay = true"
        >
          <v-icon left>
            fa fa-plus
          </v-icon>
          Nouveau rappel
        </v-btn>
      </v-col>
    </v-row>

    <div
      class="card_rappel_contain "
      v-for="rappel in rappels"
      :key="rappel.id"
      max-width="100%"
    >
      <v-card
        rounded
        class="color_card_rappel container d-flex flex-row justify-space-between align-center pa-2 mb-3"
      >
        <v-container fluid class="header">
          <v-row no-gutters class="headerRow">
            <v-col cols="10" align-self="center" align="left" class="rappel-title">
              <img
                class="py-2 pr-4"
                v-if="rappel.urgent"
                src="@/assets/triangle_rouge.png"
              />
              <span class="color_white text_align">
                {{ $moment(rappel.date_rappel).format("DD MMM YYYY") }}

                    <v-tooltip  v-if="rappel.Entreprise !== null" top>
              <template v-slot:activator="{ on, attrs }">

                <v-icon dark v-bind="attrs" v-on="on" medium>info</v-icon> 
              </template>
              <div> {{rappel.Entreprise.raison_sociale}}</div>
              <div v-if="rappel.Projet !== null" > Suite au projet : {{rappel.Projet.nom_projet}}</div>
              <div v-if="rappel.Rapport !== null" > Suite au rapport du {{ $moment(rappel.Rapport.date_rapport).format("DD MMM YYYY") }}</div>
            </v-tooltip>

              </span>

              <!-- <div v-if="rappel.Entreprise !== null" class="color_white text_align">
                {{rappel.Entreprise.raison_sociale}}
              </div> -->
            </v-col>
            <v-col cols="2" class="actions" align-self="center" align="right">
              <img
                class="pl-2 cross"
                @click="openDialog(rappel.id_rappel)"
                src="@/assets/croix.png"
              />
            </v-col>
          </v-row>
          <v-card-text class="pa-0">
            <div
              class="color_white text_align description"
              :class="{ 'all-description': rappel.viewall }"
            >
              {{ rappel.description.substring(0, 58)
              }}<span v-if="rappel.description.length > 58 && !rappel.viewall">
                [...]</span
              >
              <div
                v-if="rappel.description.length > 58 && !rappel.viewall"
                @click="rappel.viewall = true"
                class="color_white text_align btnActions"
              >
                <a href="#">VOIR PLUS</a>
              </div>
              <!-- <v-expand-transition> -->

              <span
                v-show="rappel.viewall"
                class="pa-0 text_align description"
                >{{ rappel.description.substring(58) }}</span
              >
              <!-- </v-expand-transition> -->
            </div>
            <div
              v-if="rappel.viewall"
              @click="rappel.viewall = false"
              class="color_white text_align btnActions"
            >
              <a href="#">VOIR MOINS</a>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </div>

    <v-overlay
      id="overlay"
      @click="overlay = true"
      :class="{ 'overlay-active': overlay, none: !overlay }"
    >
      <v-row align="center" align-self="center" id="rowoverlay" no-gutters>
        <v-col cols="3" offset="4" id="form">
          <v-form
            ref="form"
            v-model="valid"
            align="center"
            align-self="center"
            cols="8"
            elevation="3"
          >
            <img @click="closeModal" id="close" src="@/assets/close.png" />

            <v-col cols="12">
              <h1>Créer un rappel</h1>
            </v-col>
            <v-col cols="12" class="mb-6">
              <v-checkbox
                v-model="rappel.urgent"
                align="center"
                align-self="center"
                light
              >
                <template v-slot:prepend>
                  <div class="labelCheckbox">
                    <img src="@/assets/triangle_rouge.png" /> URGENT
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <p class="left">CLIENT</p>
            </v-col>
            <v-col cols="10" class="mb-6">
              <v-autocomplete
                :items="clients"
                item-value="id_entreprise"
                item-text="raison_sociale"
                v-model="rappel.id_entreprise"
                light
                outlined
                dense
                color="#CFCFCF"
                :search-input.sync="search"
                no-data-text="Aucune entreprise de trouver"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              v-if="
                ( $store.state.user.id_role == 1 || $store.state.user.id_role == 3 ) && this.$store.state.AsUser.id_role !== 2
              "
              cols="12"
            >
              <p class="left">COMMERCIAUX</p>
            </v-col>
            <v-col
              v-if="
                ( $store.state.user.id_role == 1   ||  $store.state.user.id_role == 3 ) && this.$store.state.AsUser.id_role !== 2
              "
              cols="10"
              class="mb-6"
            >
              <v-autocomplete
                :items="commerciaux"
                item-value="id_utilisateur"
                item-text="nom"
                v-model="rappel.commerciaux"
                light
                outlined
                multiple
                chips
                dense
                color="#CFCFCF"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <p class="left">DATE</p>
            </v-col>
            <div id="rowSpecial" align="center">
              <v-col cols="10" class="mb-6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  light
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      light
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="rappel.date_rappel"
                    light
                    locale="fr-fr"
                    
                    @change="save"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="left">
                <img src="@/assets/calendar.png"
              /></v-col>
            </div>
            <v-col cols="12">
              <p class="left">COMMENTAIRE*</p>
            </v-col>
            <v-col cols="10" class="mb-6">
              <v-textarea
                v-model="$v.rappel.description.$model"
                counter
                light
                outlined
                rows="2"
                no-resize
              >
              </v-textarea>
              <div class="errorMsg" v-if="$v.rappel.description.$error">
                Merci de remplir le commentaire
              </div>
            </v-col>

            <v-btn
              :disabled="!valid"
              color="#FF9900"
              class="mr-4"
              @click="AddRappel"
              rounded
            >
              CREER
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-overlay>
     <Popup :dialog="dialogCloseRappel" :text="messageCreation" :actions="actions" @delete="delete_card" @nothing="dialogCloseRappel=false" />
  </div>
</template>
<script>
import api from "../../api/user";
import apiRappel from "../../api/rappel";
import apiEntreprises from "../../api/entreprise";
import { required } from 'vuelidate/lib/validators'
import moment from "moment";
import Popup from "@/components/shared/Popup";

export default {
  components:{Popup},
  data() {
    return {
      rappels: [],
      date_now: "",
      date_rappel: "",
      dialog: false,

      valid: true,
      rappel: {
        urgent: false,
        id_entreprise: null,
        date_rappel: new Date().toISOString().substr(0, 10),
        description: "",
        commerciaux: [],
        // Entreprise: {
        //   raison_sociale: null
        // }
      },
      checkbox: false,
      clients: [],
      menu: false,
      zIndex: 0,
      overlay: false,
      commerciaux: [],
      nbResult : process.env.VUE_APP_NB_DISPLAY,
      search:null,
      dialogCloseRappel:false,
      messageCreation:"Souhaitez-vous clore le rappel ?",
      closeRappelId:null,
      actions: {
        actions: [
          {
            btn: "OUI",
            event: "delete",
          },
          {
            btn: "NON",
            event: "nothing",
          },
        ]
      }
    };
  },
   computed: {
    formatDate() {
      moment.locale()
      return this.rappel.date_rappel
        ? moment(this.rappel.date_rappel).format("L")
        : "";
    }
  },
  validations: {
    rappel: {
      description: {
        required,
      },
    },
    
  },
  methods: {
    delete_card(id) {
      console.log(id);
      apiRappel.closeRappel(this.closeRappelId).then(() => {
        this.$nextTick(() => {
          this.getUserRappels(this.$store.state.id_utilisateur);
          this.dialogCloseRappel = false;
        });
        // return res.data;
      });
    },
    getUserRappels(id) {
      api.getUserRappels(id).then((res) => {
        // this.$moment.locale("fr");
        res.data.rappels.forEach((e) => {
          e.viewall = false;
        });
        this.rappels = res.data.rappels;
      });
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    searchEntreprise(query,nbResult){
      apiEntreprises.getEntreprises(nbResult,query).then((res) => {
      this.clients = res.data.entreprise.rows
    });
    },
    AddRappel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
         console.log(this.$v);
      }
      if (!this.$v.$invalid) {
        console.log(this.$v);

        if ( ( this.$store.state.user.id_role == 1 || this.$store.state.user.id_role == 3) && this.$store.state.AsUser.id_role !== 2) {
          this.rappel["id_utilisateur"] = this.rappel.commerciaux
        }else {
          this.rappel["id_utilisateur"] = this.$store.state.AsUser.id_utilisateur
          ? this.$store.state.AsUser.id_utilisateur
          : this.$store.state.user.id_utilisateur;

        }

        apiRappel.createRappel(this.rappel).then((res) => {
          if (res.status == 200) {
            console.log("creation du rappel");
            this.closeModal();
            this.getUserRappels(this.$store.state.AsUser.id_utilisateur?this.$store.state.AsUser.id_utilisateur:'');
          }
        });
      }
    },
    closeModal() {
      this.overlay = false;
      this.rappel = {
        urgent: false,
        client: {},
        date_rappel: new Date().toISOString().substr(0, 10),
        description: "",
      };
      this.$v.$reset();
    },
    openDialog(id_rappel){
      this.dialogCloseRappel = true
      this.closeRappelId = id_rappel
      console.log(this.dialogCloseRappel,'dialog rappel')
    }
  },
  watch:{
    search(newValue,oldValue){
      if(newValue != oldValue){
        this.searchEntreprise(newValue,this.nbResult)
      }
    }
  },

  mounted() {
    this.getUserRappels(this.$store.state.AsUser.id_utilisateur?this.$store.state.AsUser.id_utilisateur:'');
    // apiEntreprises.getEntreprises(this.seartch,process.env.VUE_APP_NB_DISPLAY).then((res) => {
    //   this.clients = res.data.entreprise.rows
    // });
    this.searchEntreprise(null,this.nbResult);
    api
      .getCommerciaux()
      .then((res) => {
        this.commerciaux = res.data.users;
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.logo_rappel {
  padding-right: 7%;
  padding-left: 6%;
}

.rappel-title {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cross {
  width: 30px;
}

.color_white {
  color: white;
}
.box_rappel {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.color_card_rappel {
  background-color: rgba(255, 255, 255, 0.1);
}
.button_cross {
  background-color: #238fbb !important;
  box-shadow: none;
}
.card_rappel_contain {
  padding-bottom: 0;
}
.text_align {
  text-align: initial;
  font-weight: bold;
}
.fa-exclamation-triangle {
  color: #f50e02;
  font-size: 182%;
}
.font {
  font-family: "Open Sans", serif;
}
.padd_none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
::v-deep .bouton_rappel {
  padding: 0px 10px 1px 3px !important;
}
::v-deep .color_title {
  font-size: 1.2em;
}

/* .description{
  height:45px;
  overflow:hidden;

} */
.all-description {
  height: 100%;
}

.labelCheckbox {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  height: 24px;
}
.btnBold {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 0.68rem;
}
.card_rappel_contain {
  .header {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    .headerRow {
      border-bottom: 1px solid rgb(255, 255, 255, 0.3);
      margin-bottom: 0.5em;
    }
  }
  .text_align.description {
    color: white;
    font-size: 1em;
    font-style: italic;
  }
  .btnActions {
    margin-top: 1em;
    a {
      color: white;
    }
  }
}
.actions {
  text-align: right;
}
::v-deep .subtext {
  color: #fff;
  text-align: left;
}

::v-deep .v-list-item__content {
  text-align: left;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}
#overlay.overlay-active {
  display: block;
  ::v-deep .v-overlay__scrim {
    background-color: inherit !important;
  }
  #rowoverlay {
    height: 100vh;

    #form {
      font-family: "Open Sans";
      background-color: #fff;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
      color: $font-color;
      border-bottom: 0.7em solid $border-dialog-color;
      border-radius: 30px 30px 0 0;
      position: relative;
      padding-top: 2em;

      img#close {
        position: absolute;
        top: -10px;
        right: -10px;
      }
      .col {
        padding-top: 0;
        padding-bottom: 0;
      }

      h1 {
        background-color: $background-color-blue;
        color: #fff;
        text-transform: uppercase;
        text-align: left;
        font-size: 1.2em;
        padding: 0.5em 0em 0.5em 1.5em;
        margin-bottom: 1.5em;
      }
      .labelCheckbox > img {
        padding-right: 0.4em;
      }
      ::v-deep .mdi-checkbox-blank-outline::before,
      ::v-deep .v-text-field--outlined .v-input__slot fieldset {
        color: $border-color-input !important;
      }
      .left {
        text-align: left;
      }
      p.left {
        margin-bottom: 0.2em;
      }
      ::v-deep
        .v-input--selection-controls__input:hover
        .v-input--selection-controls__ripple::before {
        background: transparent;
      }
      ::v-deep .primary--text > div {
        color: $font-color !important;
      }
      ::v-deep .mdi-menu-down {
        color: $background-color-blue !important;
        font-size: 2.5em;
      }
      #rowSpecial {
        display: flex;
        flex-wrap: wrap;
      }
      ::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
        display: none;
      }
      .v-btn {
        padding: 1.2em 3em;
        color: #fff;
        font-size: 1.2em;
        margin-bottom: 1em;
        ::v-deep span {
          letter-spacing: 0;
        }
      }
      .errorMsg {
        text-align: left;
        color: red;
        margin-bottom: 0.7em;
        margin-top: 0.7em;
      }
      ::v-deep .v-input__slot {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .btnBold {
    font-size: 0.8em;
  }
}
</style>
