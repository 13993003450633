<template>
  <!-- <v-row> -->
  <l-map
    :class="mode"
    :center="center"
    :zoom="zoom"
    class="map"
    ref="map"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
  >
<ButtonItineraire :waypoints="waypoints" :depart="depart" :arrivee="arrivee" @resetWaypoints="waypoints = $event" @resetArrivee="arrivee = $event"/> 
    <l-tile-layer :url="url"> </l-tile-layer>
    <v-marker-cluster
      ref="cluster"
      :averageCenter="true"
      :ignoreHidden="true"
      :options="clusterOptions"
    >
      <marker-client
       @emitStartCoordinates="setStartWaypoint($event)"
       @emitEndCoordinates="setEndWaypoint($event)"
        @markerOnLoad="isloading = false"
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
      ></marker-client>
    </v-marker-cluster>
    <v-btn
      class="add"
      absolute
      right
      bottom
      color="#FFF"
      dark
      icon
      rounded
      @mouseover="isHover = true"
      v-if="!isHover && mode == 'result'"
    >
      <v-icon>fa fa-plus</v-icon>
    </v-btn>
    <v-btn
      class="add hover"
      absolute
      right
      bottom
      color="#2F7BB4"
      index="99"
      @mouseleave="isHover = false"
      v-if="isHover && mode == 'result'"
      dark
      @click="toProspect()"
    >
      <v-icon left>fa fa-plus</v-icon> Ajouter un prospect
    </v-btn>

    <v-progress-circular
      id="progressCicular"
      v-if="isloading"
      indeterminate
      color="primary"
    ></v-progress-circular> 
     <l-routing-machine :waypoints="waypoints" :showItineraire="showItineraire"/>
     
  </l-map>

  <!-- </v-row> -->
</template>

<script>
import { LMap, LTileLayer } from "vue2-leaflet";
import LRoutingMachine from '@/components/Home/LRoutingMachine.vue'


import "leaflet/dist/leaflet.css";
import markerClient from "@/components/Home/MarkerClient";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import { divIcon } from "leaflet";
import ClusterIcon from "@/components/Home/ClusterIcon";
import Vue from "vue";
import ButtonItineraire from "@/components/Home/ButtonItineraire";

import apiEntreprises from "../../api/entreprise";

const EnhancedClusterIcon = Vue.extend(ClusterIcon);

export default {
  components: {
    LMap,
    LTileLayer,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    markerClient,
    LRoutingMachine,
    ButtonItineraire
  },
  props: ["entreprises", "mode", "query", "filter"],
  data() {
    return {
      isloading: true,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      center: [46.90296, 1.909251],
      zoom: 6,
      markers: [],
      clusterOptions: {
        chunkedLoading: true,
        spiderfyDistanceMultiplier: 3,
        iconCreateFunction: (cluster) => {
          let clusterUsers = cluster
            .getAllChildMarkers()
            .map((marker) => marker.id);
          let clusterIconEl = new EnhancedClusterIcon({
            propsData: { clusterUsers },
          }).$mount().$el;
          return divIcon({
            html: clusterIconEl.outerHTML,
            className: "mycluster",
            iconSize: null,
          });
        },
      },
      entreprisesArray: this.entreprises,
      isHover: false,
      showItineraire: false,
      waypoints: [
        { lat: null, lng: null }
      ],
      depart: {
        id_entreprise: null,
        raison_sociale: '',
        longitude: null,
        latitude:  null,   
      
      },
      arrivee: [
        {
          id_entreprise: null,
          raison_sociale: null,
          longitude: null,
          latitude:  null,   
        }
      ],
      language: "fr"
    };
  },
  watch: {
    entreprises(new_value, old_value) {
      // alert("watch entreprise")
      console.log(new_value, "show résult", this.entreprises);
      if (new_value != old_value) {
        this.getMarkers(this.entreprises);
      }
    },
    query(new_value, old_value) {
      if (new_value != old_value) {
              // alert("watch query")

        apiEntreprises
          .getEntreprises(
            process.env.VUE_APP_NB_DISPLAY_ALL,
            this.query,
            this.filter.clients,
            this.filter.prospects,
            this.filter.visite,
            this.filter.no_visite,
            this.filter.projet,
            this.filter.no_projet
          )
          .then((res) => {
            // this.entreprises = res.data.entreprise;
                  // console.log("markers query", res.data.markers)
            this.getMarkers(res.data.markers);
          });
      }
    },
    filter(new_value, old_value) {
      let flag = false;
      for( let params in new_value){
        for (let par in old_value){
          if (par == params && old_value[par] != new_value[params]){
            flag = true;
          }
        }
      }
      if (flag == true) {
        console.log("changement de filter",new_value,old_value);
              // alert("watch filter")

        apiEntreprises
          .getEntreprises(
            process.env.VUE_APP_NB_DISPLAY_ALL,
            this.query,
            this.filter.clients,
            this.filter.prospects,
            this.filter.visite,
            this.filter.no_visite,
            this.filter.projet,
            this.filter.no_projet
          )
          .then((res) => {
            // this.entreprises = res.data.entreprise;
            this.markers=[];
            this.getMarkers(res.data.markers);

            
          });
      }
    },
  },
  methods: {
    toProspect() {
      this.$router.push({ name: "prospect" });
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    async getMarkers(data) {
      // alert("get markers");
      this.markers = [];

      const n = 50 //tweak this to add more items per line

      // console.log("data", data)
      var result = new Array(Math.ceil(data.length / n))
        .fill()
        .map(function() {
          return data.splice(0, n);
        });

      // console.log("result", result)
 
        result.forEach(element => {
          setTimeout(() => { 
            this.markers = this.markers.concat(element);
            // console.log("for each result", this.markers,result)
            }, 100);
        });
        


      if (this.markers.length == 0) {
        this.isloading = false;
      }
      console.log("this markers", this.markers);
    },
    setStartWaypoint(coordinates) {
      console.log("event", coordinates)
      this.depart = {
        id_entreprise: coordinates[1],
        raison_sociale: coordinates[2],
        longitude: coordinates[0][1],
        latitude:  coordinates[0][0],   
      }
      console.log("waypoint state", this.depart)
    },
    setEndWaypoint(coordinates) {
      console.log("event end waypoint", coordinates)
      
      console.log(this.arrivee.length, "arrivee length")
      var destinationObj = {
        id_entreprise: coordinates[1],
        raison_sociale: coordinates[2],
        longitude: coordinates[0][1],
        latitude:  coordinates[0][0], 
      }

      this.arrivee.push(destinationObj)
      this.showItineraire = true
      console.log("waypoint state", this.arrivee[0])
    }
  },
  created() {
    console.log(this.mode, "mode", this.entreprises);
    // if (this.mode != "result") {
    //   apiEntreprises
    //     .getEntreprises(process.env.VUE_APP_NB_DISPLAY_ALL)
    //     .then((res) => {
    //       // this.entreprises = res.data.entreprise;
    //       this.getMarkers(res.data.entreprise.rows);
    //     });
    // } else {
    //   this.getMarkers(this.entreprises);
    // }
    apiEntreprises
      .getEntreprises(process.env.VUE_APP_NB_DISPLAY_ALL, this.query)
      .then((res) => {
        this.getMarkers(res.data.markers);
      });

   
  },

};
</script>

<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
.map {
  position: relative;
  width: 100%;
  height: 700px !important;
  overflow: hidden;
  z-index: 1;
}
.map.home {
  height: 767px !important;
}
/* .vue2leaflet-map {
  height: 100% !important;
} */
.add {
  z-index: 999;
  background-color: #2f7bb4;
}
.add span .fa-plus {
  margin: auto;
}

.add.hover span .fa-plus {
  margin: auto;
  margin-right: 0.5em;
}
#progressCicular {
  position: absolute;
  top: 50%;
  z-index: 99999;
}
</style>