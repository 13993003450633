<template>
  <v-container fluid class="pt-0" id="search">
    <v-row dense>
      <v-col cols="4">
        <v-row id="bc">
          <v-col cols="12">
            <v-text-field
              id="searchresultbar"
              v-model="search.query"
              placeholder="Rechercher un Client / Prospect / Projet"
              outlined
              class="rounded-lg pr-5"
              color="#FFF"
              background-color="#fff"
              hide-details
              @keyup.enter="searchEntreprise"
              dense
            >
              <template slot="append">
                <img
                  @click="searchEntreprise"
                  src="@/assets/Rech Avancée.png"
                />
              </template>
              <template slot="prepend">
                <img src="@/assets/loupe.png" />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="pl-2">
          <v-col cols="12 mt-8" class="text-left"
            ><h2>AFFICHER SEULEMENT</h2></v-col
          >
          <v-col cols="12" class="text-left pr-4">
            <v-row>
              <v-col cols="4">
                <v-checkbox
                  @change="searchEntreprise"
                  v-model="search.clients"
                  label="Clients"
                  light
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  @change="searchEntreprise"
                  v-model="search.visite"
                  label="Visité"
                  light
                  hide-details
                ></v-checkbox
              ></v-col>
              <v-col cols="4">
                <v-checkbox
                  @change="searchEntreprise"
                  v-model="search.projets"
                  label="Projets"
                  light
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  @change="searchEntreprise"
                  v-model="search.prospects"
                  label="Prospects"
                  light
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  @change="searchEntreprise"
                  v-model="search.no_visite"
                  label="Non visité"
                  light
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  @change="searchEntreprise"
                  v-model="search.no_projet"
                  label="Sans projet"
                  light
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12"><hr class="hr" /></v-col>
            </v-row>

            <v-row>
              <v-col cols="12"
                ><h2>R&Eacute;SULTATS ({{ search.nb_result }})</h2></v-col
              >
              <v-col cols="12">
              
                <div
                  v-for="(entreprise, index) in entreprises"
                  :key="entreprise.id_entreprise"
                >
                  <SearchResult
                    :i="index"
                    :max="search.nb_result"
                    :entreprise="entreprise"
                  />
                </div>
              </v-col>
              <v-col v-if="search.nbPage > 1" cols="12" lg="12" xl="10">
                <div class="text-center">
                  <v-pagination
                    v-model="page"
                    :length="search.nbPage"
                    circle
                  ></v-pagination>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-row style="height: 700px">
          <Carte :query="search.query" :filter="{clients: search.clients,
        visite: search.visite,
        projets: search.projets,
        prospects: search.prospects,
        no_visite: search.no_visite,
        no_projet: search.no_projet}" :mode="'result'" />
        </v-row>
        <v-row>
          <v-col cols="12"><h3 class="text-left ml-2">Legende :</h3></v-col>
          <v-col cols="12"
            ><ul class="text-left pl-0 ml-2" id="legende">
              <li class="mr-5" v-for="(l, i) in legende" :key="i">
                <span
                  ><i class="fa fa-map-marker-alt" :class="l.class"></i>
                  {{ l.text }}</span
                >
              </li>
            </ul></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Carte from "@/components/Home/Carte";
import apiEntreprises from "@/api/entreprise";
import SearchResult from "../components/Home/SearchResult.vue";

export default {
  data() {
    return {
      search: {
        query: this.$route.query.recherche,
        clients: true,
        visite: true,
        projets: true,
        prospects: true,
        no_visite: true,
        no_projet: true,
        nb_result: 0,
        
        nbPage: 1,
      },
      page: 1,
      entreprises: [], // entreprise paginée
      CarteEntreprise: [], //toutes les entreprises de la recherche
      legende: [
        { text: "Client avec projet", class: "client_projet" },
        { text: "Client sans projet", class: "client_no_projet" },
        {
          text: "Prospect non visité sans projet",
          class: "prospect_no_visite_no_projet",
        },
        {
          text: "Prospect non visité avec projet",
          class: "prospect_no_visite_projet",
        },
        {
          text: "Prospect visité sans projet",
          class: "prospect_visite_no_projet",
        },
        {
          text: "Prospect visité avec projet",
          class: "prospect_visite_projet",
        },
      ],
    };
  },
  watch: {
    page: function (newVal, oldval) {
      // watch it
      console.log('watch page',newVal,oldval)
      if (newVal != oldval) {
        this.page = newVal;
        this.getEntreprises(
          process.env.VUE_APP_NB_DISPLAY,
          this.search.query,
          this.search.clients,
          this.search.prospects,
          this.search.visite,
          this.search.no_visite,
          this.search.prospects,
          this.search.no_projet,
          this.page - 1
        );
      }
    },
    'search.query': function (newVal, oldval) {
        console.log(newVal,oldval,this.search)
        this.searchEntreprise();
    }
  },
  components: { Carte, SearchResult },
  methods: {
    // recherche paginée
    getEntreprises(
      size,
      query,
      clients,
      prospects,
      visite,
      noVisite,
      projets,
      noProjet,
      page
    ) {
      apiEntreprises
        .getEntreprises(
          size,
          query,
          clients,
          prospects,
          visite,
          noVisite,
          projets,
          noProjet,
          page
        )
        .then((res) => {
          this.entreprises = res.data.entreprise.rows;
          this.entreprises.forEach(entreprise => {
            entreprise.email_entreprise ? entreprise.email_entreprise : ""
          });
          this.search.nbPage = res.data.entreprise.totalPages;
          
          // this.search.nb_result = res.data.entreprise.totalItems;
        });
    },
    getEntreprisesFull(
      size,
      query,
      clients,
      prospects,
      visite,
      noVisite,
      projets,
      noProjet
    ) {
      apiEntreprises
        .getEntreprises(
          size,
          query,
          clients,
          prospects,
          visite,
          noVisite,
          projets,
          noProjet
        )
        .then((res) => {
          this.CarteEntreprise = res.data.entreprise.rows;
          this.search.nb_result = res.data.entreprise.totalItems;
          console.log(this.CarteEntreprise,'ccccccccarte entreprise')
        });
    },

    searchEntreprise() {
      console.log('search entreprise',this.search,this.search.no_projet);
      this.getEntreprises(
        process.env.VUE_APP_NB_DISPLAY,
        this.search.query,
        this.search.clients,
        this.search.prospects,
        this.search.visite,
        this.search.no_visite,
        this.search.prospects,
        this.search.no_projet,
      );

      this.getEntreprisesFull(
        process.env.VUE_APP_NB_DISPLAY_ALL,
        this.search.query,
        this.search.clients,
        this.search.prospects,
        this.search.visite,
        this.search.no_visite,
        this.search.prospects,
        this.search.no_projet,
      );
    },
  },
  created() {
    this.getEntreprises(process.env.VUE_APP_NB_DISPLAY, this.search.query); // recherche paginée

    this.getEntreprisesFull(
      process.env.VUE_APP_NB_DISPLAY_ALL,
      this.search.query
    ); // recherche carte
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
::v-deep #searchresultbar::placeholder {
    font-size: 14px;
  }
#bc {
  background-color: $background-color-blue;
}
::v-deep .v-input input,
.v-input input:focus,
input:focus,
input,
#input-38 {
  caret-color: black;
}
::v-deep
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 0;
  margin: 1% auto;
  cursor: pointer;
}
#search {
  h2 {
    font-size: 1.2em;
    font-weight: bold;
    color: $background-color-blue;
  }
  ::v-deep .v-input.v-input--checkbox .v-label {
    color: $background-color-blue;
    font-family: "Open Sans", sans-serif;
  }
  .hr {
    color: $table-background-secondary-color;
  }
}
#legende {
  li {
    list-style-type: none; /* Remove bullets */
    display: inline-block;
  }
  .fa-map-marker-alt.client_projet {
    color: chartreuse;
    background-color: transparent;
  }
  .fa-map-marker-alt.client_no_projet {
    color: blue;
    background-color: transparent;
  }
  .fa-map-marker-alt.prospect_visite_projet {
    color: orange;
    background-color: transparent;
  }
  .fa-map-marker-alt.prospect_visite_no_projet {
    color: black;
    background-color: transparent;
  }
  .fa-map-marker-alt.prospect_no_visite_projet {
    color: rgb(235, 57, 235);
    background-color: transparent;
  }
  .fa-map-marker-alt.prospect_no_visite_no_projet {
    color: red;
    background-color: transparent;
  }
  .fa-map-marker-alt {
    margin-right: 1em;
  }
}
</style>