<template lang="">
  <div>
    <div class="pad_contain text-justify">
      <v-row class="fond_gris">
        <v-col cols="12" sm="6" md="3">TYPOLOGIE</v-col>
        <v-col
          v-bind:class="{
            vert:
              entreprise.StatutEntreprise &&
              entreprise.StatutEntreprise.statut_entreprise == 'client',
            rouge:
              entreprise.StatutEntreprise &&
              entreprise.StatutEntreprise.statut_entreprise == 'prospect',
          }"
          class="typologie"
          cols="12"
          sm="6"
          md="9"
          >{{
            entreprise.StatutEntreprise
              ? entreprise.StatutEntreprise.statut_entreprise
              : ""
          }}</v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">RAISON SOCIALE</v-col>
        <v-col cols="12" sm="6" md="9"> {{ entreprise.raison_sociale }}</v-col>
      </v-row>
      <v-row class="fond_gris">
        <v-col cols="12" sm="6" md="3">TYPE DE CLIENT</v-col>
        <v-col
          cols="12"
          sm="6"
          md="9"
          class="d-flex row justify-space-around align-center pl-9"
        >
          <div class="d-flex row align-center pl-4">
            <!-- <v-radio-group v-model="entreprise.type_client">
              <v-col cols="12" sm="6" md="4"
                >
                <v-radio
                  class="margin_right_radio"
                  label="Distributeur"
                  value="DISTRIBUTEUR"
                  v-if="entreprise.type_client == 'DISTRIBUTEUR'"
                  disabled
                ></v-radio
              >
                <v-radio
                  class="margin_right_radio"
                  label="Constructeur"
                  value="CONSTRUCTEUR"
                  v-else-if="entreprise.type_client == 'CONSTRUCTEUR'"
                  disabled
                ></v-radio
              >
                <v-radio
                  label="Utilisateur"
                  value="UTILISATEUR"
                  v-else-if="entreprise.type_client == 'UTILISATEUR'"
                  disabled
                  class="margin_bottom_radio"
                ></v-radio
              >
              <div v-else>Pas de type défini</div>
              </v-col>
            </v-radio-group> -->
            {{
              entreprise.type_client
                ? entreprise.type_client
                : "Pas de type défini"
            }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">DESCRIPTION</v-col>
        <v-col cols="12" sm="6" md="9">
          {{ entreprise.description_activite }}
        </v-col>
      </v-row>
      <v-row class="fond_gris">
        <v-col cols="12" sm="6" md="3">POTENTIEL D'ACHAT</v-col>
        <v-col
          cols="12"
          sm="6"
          md="9"
          class="d-flex row justify-space-around align-center pl-9"
        >
          <div class="d-flex row align-center pl-4">
            <!-- <v-radio-group v-model="entreprise.potentiel_achat">
              <v-col cols="12" sm="6" md="5">
                <v-radio
                  class="margin_right_radio"
                  label="< 10 0000€"
                  value="10K"
                  v-if="entreprise.potentiel_achat == '10K'"

                  disabled
                ></v-radio>
              </v-col>
              <v-col cols="12" sm="6" md="7">
                <v-radio
                  class="margin_right_radio"
                  label='10 0000€ <  30 0000€'
                  value="10-30K"
                  v-if="entreprise.potentiel_achat == '20K'"
                  disabled
                ></v-radio>
              </v-col>
              <v-col cols="12" sm="12" md="5">
                <v-radio
                  class="margin_bottom_radio"
                  label="> 30 0000€"
                  value="30K"
                  v-if="entreprise.potentiel_achat == '30K'"
                  disabled
                ></v-radio>
              </v-col>
            </v-radio-group> -->
            {{
              entreprise.potentiel_achat
                ? entreprise.potentiel_achat
                : "Non communiqué"
            }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">ADRESSE</v-col>
        <v-col cols="12" sm="6" md="9"> {{ entreprise.adresse }} </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">CODE POSTAL</v-col>
        <v-col cols="12" sm="6" md="2"> {{ entreprise.code_postal }} </v-col>
        <v-col cols="12" sm="6" md="1">
          VILLE
        </v-col>
        <v-col cols="12" sm="6" md="4"> {{ entreprise.ville }} </v-col>
      </v-row>
      <v-row class="fond_gris">
        <v-col cols="12" sm="6" md="3">EMAIL GÉNÉRAL</v-col>
        <v-col cols="12" sm="6" md="9">
          {{ entreprise.email_entreprise }}</v-col
        >
      </v-row>
      <v-row class="fond_gris">
        <v-col cols="12" sm="6" md="3">TÉLÉPHONE GÉNÉRAL</v-col>
        <v-col cols="12" sm="6" md="9"> {{ entreprise.telephone_fixe }}</v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">PROSPECT VISITE</v-col>
        <v-col
          cols="12"
          sm="6"
          md="9"
          class="d-flex row justify-space-around align-center pl-9"
        >
          <div class="d-flex row align-center pl-4">
            {{ entreprise.prospect_visite ? "OUI" : "NON" }}
          </div>
        </v-col>
      </v-row>
            <v-row>
        <v-col cols="12" sm="6" md="3">NOMBRE DE VISITES PREVUES</v-col>
        <v-col cols="12" sm="6" md="9"> {{ entreprise.nb_visites_prevues }}</v-col>
      </v-row>

      <v-row>
        <v-col class="px-0" cols="12" sm="12" md="12"
          ><div class="barre_bleu"></div
        ></v-col>
      </v-row>

      <v-row class="fond_bi_color padd_right">
        <v-col cols="12" sm="6" md="3" class="crit_achat"
          >CRITÈRE D'ACHAT</v-col
        >
        <v-col cols="12" sm="6" md="9" class="commentaires">
          COMMENTAIRES
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-0" cols="12" sm="11" md="11">
          <div class="d-flex flex-column">
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >PRIX :</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Prix"] ? critereAchat["Prix"].value : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >DÉLAIS :</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Délais"] ? critereAchat["Délais"].value : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >NOTORIÉTÉ :</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Notoriété"] ? critereAchat["Notoriété"].value : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >HABITUDE :</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Habitude"] ? critereAchat["Habitude"].value : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >QUALITÉ DU PRODUIT :</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Qualité du produit"]
                  ? critereAchat["Qualité du produit"].value
                  : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >PROXIMIT&Eacute; :</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Proximité"] ? critereAchat["Proximité"].value : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
            <div class="d-flex row">
              <v-col class="margin_left_crit" cols="12" sm="11" md="3"
                >RELATIONNEL:</v-col
              >
              <v-col class="margin_left_crit_c" cols="12" sm="11" md="9">{{
                critereAchat["Relationnel"]
                  ? critereAchat["Relationnel"].value
                  : ""
              }}</v-col>
            </div>
            <div class="grey_line"></div>
          </div>
        </v-col>
      </v-row>
      <v-row class="fond_bi_color padd_bottom_2">
        <v-col cols="12" sm="9" md="11" class="crit_achat"
          >FREINS ET ATOUTS DE FTA POUR FIDÉLISER OU ACQUÉRIR CE CLIENT</v-col
        >
      </v-row>
      <v-row>
        <v-col class="rectangle_box" cols="12" sm="9" md="11"
          >{{ entreprise.freins_et_atouts }}
        </v-col>
      </v-row>
      <v-row class="fond_bi_color margin_negative">
        <v-col cols="12" sm="9" md="11" class="crit_achat"
          >ACHATS COMPOSANTS HYDRAULIQUES</v-col
        >
      </v-row>
      <v-row>
        <v-col cols="12" sm="9" md="11" class="pa-0">
          <resultList
            :header="headerAchats"
            :items="produits"
            :nbResult="1"
            :type="'achats'"
          />
        </v-col>
      </v-row>
      <v-row class="fond_bi_color margin_negative">
        <v-col cols="12" sm="9" md="11" class="crit_achat">COMMERCIAUX</v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span
            v-for="(commercial, i) in entreprise.Users"
            :key="commercial.id_user"
          >
            <span>{{ commercial.prenom }} {{ commercial.nom }}</span>
            <span v-if="i + 1 < entreprise.Users.length">,</span>
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import ResultList from "@/components/shared/ResultList";
import api from "@/api/entreprise";

export default {
  components: { ResultList },
  data() {
    return {
      commerciaux: [],
      idClientEnCours: "",
      disableClient: "",
      entreprise: [],
      id: this.$route.params.id_entreprise,
      critereAchat: {},
      produits: [],

      headerAchats: [
        { text: "PRODUIT", align: "left", sortable: false, value: "produit" },
        { text: "FTA", align: "center", sortable: false, value: "fta" },
        {
          text: "CONCURRENCE",
          align: "center",
          sortable: false,
          value: "marque_concurrence",
        },
        {
          text: "VOLUME ANNUEL",
          align: "center",
          sortable: false,
          value: "volume",
        },
        {
          text: "PRODUIT À DÉVELOPPER",
          align: "center",
          sortable: false,
          value: "dev",
        },
      ],
    };
  },
  async created() {
    await this.criteresAchat();
    await this.achatsHydraulique();
    this.getUserEntreprise(this.$route.params.id_entreprise);
  },
  methods: {
    getUserEntreprise(id) {
      api.getUserEntreprise(id).then((res) => {
        this.$emit("title", res.data.entreprise.raison_sociale + " (" + res.data.entreprise.code_client_fta + ") ");
        this.$emit(
          "id_statut_entreprise",
          res.data.entreprise.id_statut_entreprise
        );
        // this.$emit('actif',res.data.entreprise.actif)
        this.$store.commit("upadateActifEntreprise", res.data.entreprise.actif);
        this.$store.commit(
          "upadateCodeFtaEntreprise",
          res.data.entreprise.code_client_fta
        );
        this.entreprise = res.data.entreprise;
        console.log(
          this.critereAchat,
          this.produits,
          "les datas doivent être remplis"
        );
        this.entreprise.CritereAchats.forEach((critere) => {
          for (let c in this.critereAchat) {
            console.log("liste");
            if (this.critereAchat[c].id == critere.id_critere_achat) {
              this.critereAchat[c] = {};
              this.critereAchat[c].value =
                critere.EntrepriseCritereAchat.commentaire;
            }
          }
        });
        console.log(this.entreprise.CritereAchats, "jhhhhhh");

        // this.entreprise.AchatHydrauliques.forEach((ac) => {
        //   console.log("ach", ac);
        // });

        this.entreprise.AchatHydrauliques.forEach((achat) => {
          // console.log("les achats", achat, this.produits);
          this.produits.forEach((ac) => {
            if (achat.id_achat_hydraulique == ac.id_achat_hydraulique) {
              ac.fta =
                achat.EntrepriseAchatHydraulique.fta == true ? "Oui" : "Non";
              ac.marque_concurrence =
                achat.EntrepriseAchatHydraulique.marque_concurrence;
              ac.volume = achat.EntrepriseAchatHydraulique.volume;
              ac.dev = achat.EntrepriseAchatHydraulique.produit_a_developper;
            }
          });
        });

        this.$emit(
          "userentreprise",
          this.entreprise.Users.map((user) => {
            return user.id_utilisateur;
          })
        );
      });
    },
    async criteresAchat() {
      await api.criteresAchat().then((res) => {
        let c = {};
        res.data.criteresAchat.map((e) => {
          c[e.critere] = { txt: e.critere, id: e.id_critere_achat, value: "" };
        });
        this.critereAchat = c;
      });
    },
    async achatsHydraulique() {
      await api.achatsHydraulique().then((res) => {
        res.data.achatsHydraulique.map((e) =>
          this.produits.push({
            produit: e.libelle,
            fta: "Non",
            marque_concurrence: "",
            volume: "",
            dev: false,
            id_achat_hydraulique: e.id_achat_hydraulique,
          })
        );
      });
    },
  },
};
</script>
<style scoped>
.active {
  display: none;
}

.vert {
  color: green;
}

.rouge {
  color: red;
}

.typologie {
  text-transform: uppercase;
  font-weight: bold;
}

::v-deep .v-input--radio-group__input {
  flex-direction: row;
}
.fond_gris {
  background-color: #f5f5f5;
}
.pad_contain {
  padding-left: 10%;
  padding-right: 20%;
  padding-top: 60px;
}
.barre_bleu {
  border: 3px solid #0b87ba;
  background-color: #0b87ba;
  margin-top: 30px;
}
.crit_achat {
  color: white;
  background-color: #0b87ba;
  font-weight: bold;
}
.commentaires {
  color: white;
  background-color: #085a7c;
}
.fond_bi_color {
  padding-top: 10px;
  padding-bottom: 5px;
}
.padd_right {
  padding-right: 83px;
}
.rectangle_box {
  border: 1px solid rgba(112, 112, 112, 0.5);
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 40px;
}
.margin_bottom_radio {
  margin-bottom: 8px;
}
.grey_line {
  padding-top: 23px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
  margin-bottom: 23px;
}
.margin_left_crit {
  padding-left: 40px;
}
.margin_left_crit_c {
  padding-left: 20px;
}
.col-sm-6.col-md-9.col-12,
.col-sm-6.col-md-2.col-12,
.col-sm-6.col-md-5.col-12,
.col-md-6,
.col-sm-6.col-md-4.col-12,
.col-sm-6.col-md-7.col-12,
.col-sm-12.col-md-5.col-12,
.col-sm-6.col-md-9.col-12 {
  font-weight: bold;
}
.col-sm-6.col-md-3.col-12 {
  display: flex;
  align-items: center;
}
.margin_negative {
  margin-bottom: -17px;
}
::v-deep .theme--light.v-radio--is-disabled .v-icon,
::v-deep .v-icon--disabled.mdi.mdi-checkbox-marked.theme--light.info--text {
  color: #1976d2 !important;
}
::v-deep .theme--light.v-radio--is-disabled label {
  color: rgba(0, 0, 0, 0.6);
}
::v-deep thead.v-data-table-header {
  background-color: #e6e6e6;
}
input#scales {
  width: 50%;
  height: 44%;
}
.padd_bottom_2 {
  padding-bottom: 20px;
}
.d-flex.row.justify-space-around.align-center.pl-9.col-sm-6.col-md-9.col-12 {
  margin-left: -27px;
}
v ::v-deep .v-input__slot {
  display: flex;
  justify-content: center;
}
::v-deep .v-data-table-header::after {
  height: 0;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  height: 45px;
  border-right: none;
}
@media (max-width: 1366px) {
  .pad_contain {
    padding-right: 10%;
  }
  .padd_right {
    padding-right: 71px;
  }
}
</style>
