import axios from 'axios';

const RESSOURCE_NAME = 'personnes';

export default {

    getEntrepriseContacts(query = "", idEntreprise, page = 0, column = 'nom', order = 'ASC') {
        return axios.post(`${RESSOURCE_NAME}/search/` + idEntreprise + `?page=` + page + `&size=` + process.env.VUE_APP_NB_DISPLAY + `&orderBy=` + column + `&sortBy=` + order + ``, { query: query })

    },

    getAllEntrepriseContacts(query = "", idEntreprise, page = 0, column = 'nom', order = 'ASC') {
        return axios.post(`${RESSOURCE_NAME}/search/` + idEntreprise + `?page=` + page + `&size=` + 9999 + `&orderBy=` + column + `&sortBy=` + order + ``, { query: query })

    },
    createContact(contact) {
        return axios.post(`${RESSOURCE_NAME}/create/` , { contact })

    },
    getPersonne(id_personne) {
        return axios.get(`${RESSOURCE_NAME}/${id_personne}`)

    },
    updateContact(contact,id_personne) {
        return axios.post(`${RESSOURCE_NAME}/update/${id_personne}`, { contact })
    },
    archivePersonne(id_personne) {
        return axios.post(`${RESSOURCE_NAME}/archive/${id_personne}`)

    },
}