<template lang="">

<div>
    <!-------------------------------- Bloc Personnes rencontrées -------------------------------->
      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            PERSONNES RENCONTREES
          </v-col>

    <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0 text-right">
            <v-btn v-if="mode !== 'visualisation'" :disabled="disabledExisting" class="btnBold mr-2"  color="#FF9900" dark rounded @click="dialogExisting=true" >
        <v-icon left> fa fa-plus </v-icon>
            <div class="font-small">
              Contact(s) existant(s)
            </div>
      </v-btn>
      <v-btn v-if="mode !== 'visualisation'" :disabled="disabled" class="btnBold"  color="#FF9900" dark rounded @click="dialog=true" >
        <v-icon left> fa fa-plus </v-icon>
            <div class="font-small">
              Nouveau contact
            </div>
      </v-btn>
     </v-col>
        </v-toolbar>
      </v-col>

              <Popup :dialog="dialogExisting" text="" actions="" heightProp="auto" widthProp="50vW" @emitDialog="dialogExisting = $event" showClose="true">
                <!-- Formulaire personnes -->
      <form  @submit.prevent="submitExistingContact">
        <v-col cols="12" sm="12" class="pb-0 pl-16 pr-16 ">

        
        <v-toolbar color="#0B87BA" width="800" flat dense>
          <v-col
            cols="12"
            lg="12"
            xl="12"
            class="pa-0 font_color text-left"
          >
            AJOUTER UNE PERSONNE
          </v-col>

        </v-toolbar>
  

    
            <v-row>
            <v-col v-for="i in contacts" :key="i.contacts" class="pl-5 mr-5" cols="2">
              <!-- <v-col cols="2" class="pa-0" > -->
                <v-checkbox @change="nbcontacts" color="info" v-model="i.value" :label="i.nom_prenom" hide-details dense />
                <!-- <div>{{ i.nom}}</div> -->
              <!-- </v-col> -->
            </v-col>
           
            <!-- <div v-if="nbcontactsSelected == 0" class="text-left red--text mt-5 mb-3 pl-6 pt-5">Merci d'indiquer au moins un commercial</div> -->
          </v-row>
<!-- 
            <v-col cols="10" class="mb-6">
              <v-autocomplete
                :items="contacts"
                item-value="id_personne"
                item-text="nom"
                light
                outlined
                multiple
                chips
                dense
                color="#CFCFCF"
              >
              </v-autocomplete>
            </v-col> -->



      <v-col cols="12" sm="12"  class="">
        <v-btn :disabled="disabledCheckbox" class="btnBold"  color="#FF9900" dark rounded type="submit" >
          <v-icon left> fa fa-plus </v-icon>
              <div >
                Ajouter cette personne ( 4 maximum )
              </div>
        </v-btn>
      </v-col>


        </v-col>
    </form>
      </Popup>
      <!-- contact existant -->


        <Popup :dialog="dialog" text="" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="true">
                <!-- Formulaire personnes -->
      <form  @submit.prevent="submit">
        <v-col cols="12" sm="12" class="pb-0 pl-16 pr-16 ">

        
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="6"
            class="pa-0 font_color text-left"
          >
            AJOUTER UNE PERSONNE
          </v-col>

        </v-toolbar>
  

    
           <div class="d-flex">
            <div class="text-left mt-5">CIVILITE

            </div>
              <v-radio-group class="ml-10" row v-model="personne.civilite">
                <v-radio label="M." value="M."></v-radio>
                <v-radio label="MME" value="MME." ></v-radio>
              </v-radio-group>
              
            </div>
                            <!-- message d'erreur -->
          <div class="errorMsg text-left pb-6" v-if="$v.personne.civilite.$error && $v.personne.civilite.required == false">
            Renseignez la civilité de la personne.
          </div>
      
          <v-row class="">
            <v-col cols="12" sm="6" class="pt-0">
              <div class="text-left">NOM

              </div>

              <v-text-field label="" single-line outlined v-model="personne.nom"  hide-details ></v-text-field>

          <!-- message d'erreur -->
          <div class="errorMsg" v-if="$v.personne.nom.$error && $v.personne.nom.required == false">
            Renseigner le nom de la personne.
          </div>

          <div class="errorMsg" v-if="$v.personne.nom.$error && ($v.personne.nom.required == true && $v.personne.nom.minLength == false) ">
            2 caractères au minimum.
          </div>

          <div class="errorMsg" v-if="$v.personne.nom.$error && ($v.personne.nom.required == true && $v.personne.nom.maxLength == false) ">
            50 caractères au maximum.
          </div>
            <!-- message d'erreur -->

            </v-col>



            <v-col cols="12" sm="6" class="pt-0">
              <div class="text-left">
                  PRÉNOM

             </div>
              <v-text-field label="" single-line outlined v-model="personne.prenom" hide-details   ></v-text-field>
                  <!-- message d'erreur -->
                <div class="errorMsg" v-if="$v.personne.prenom.$error && $v.personne.prenom.required == false">
                    Renseigner le prenom de la personne.
                </div>

                <div class="errorMsg" v-if="$v.personne.prenom.$error && ($v.personne.prenom.required == true && $v.personne.prenom.minLength == false) ">
                    2 caractères au minimum.
                </div>

                <div class="errorMsg" v-if="$v.personne.prenom.$error && ($v.personne.prenom.required == true && $v.personne.prenom.maxLength == false) ">
                    50 caractères au maximum.
                </div>
                    <!-- message d'erreur -->
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
              <div class="text-left">FONCTION</div>
              <v-text-field label="" single-line outlined v-model="personne.fonction" hide-details  ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0">
              <div class="text-left">EMAIL
              </div>
              <v-text-field label="" maxLength=100 single-line outlined v-model="personne.email" hide-details  ></v-text-field>
                <div class="errorMsg" v-if="$v.personne.email.$error && $v.personne.email.required == false">
                    Renseignez l'email de la personne.
                </div>

                <div class="errorMsg" v-if="$v.personne.email.$error && ($v.personne.email.required == true && $v.personne.email.email == false) ">
                    Renseignez une adresse email valide.
                </div>
            </v-col>
            
            <v-col cols="12" sm="6" class="pt-0">
              <div class="text-left">TELEPHONE

              </div>
              <v-text-field label="" maxLength=10 single-line outlined v-model="personne.telephone" hide-details  ></v-text-field>
                              <!-- message d'erreur -->
                <div class="errorMsg" v-if="$v.personne.telephone.$error && $v.personne.telephone.required == false">
                    Renseigner le telephone de la personne.
                </div>

                <div class="errorMsg" v-if="$v.personne.telephone.$error && $v.personne.telephone.numeric == false">
                    Renseigner seulement des chiffres.
                </div>

                <div class="errorMsg" v-if="$v.personne.telephone.$error && ($v.personne.telephone.required == true && $v.personne.telephone.minLength == false) ">
                    renseignez 10 caractères.
                </div>

                <div class="errorMsg" v-if="$v.personne.telephone.$error && ($v.personne.telephone.required == true && $v.personne.telephone.maxLength == false) ">
                    renseignez 10 caractères.
                </div>
                <!-- message d'erreur -->
            </v-col>
          </v-row>
            <!-- <v-col cols="12" sm="6" class="pt-0">
                <button class="addPersonne" block type="submit">Ajouter une autre personne</button >
            </v-col> -->

                  <v-col cols="12" sm="12"  class="">
              <v-btn :disabled="disabled" class="btnBold"  color="#FF9900" dark rounded type="submit" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              Ajouter cette personne ( 4 maximum )
            </div>
      </v-btn>
      </v-col>

    <!-- <v-list-item two-line>
      <v-list-item-content             v-for="(personne, index) in personnes"
            v-bind:key="personne.email"
            v-bind:nom="personne.nom"
            v-on:remove="personnes.splice(index, 1)">
        <v-list-item-title>{{personne.prenom}} {{personne.nom.toUpperCase()}}</v-list-item-title>
        <v-list-item-subtitle>
         {{personne.fonction}} {{personne.email}} {{personne.telephone}}
        </v-list-item-subtitle>
        <v-list-item-subtitle><button  v-on:click="personnes.splice(index, 1), disableButton()"><strong>Supprimer</strong></button></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->



        </v-col>
    </form>
      </Popup>

      <!-- Popup  -->
              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Supprimer cette personne ?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Non</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Oui</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <Popup :dialog="dialogDelete" text="Supprimer cette personne ??" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="false"> 
                     <v-card-actions class="mb-10">
              <v-btn class="actions" color="#0B87BA" width="100px" large dark rounded @click="deleteItemConfirm">Oui</v-btn>
              <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="closeDelete">Non</v-btn>              
            </v-card-actions> 
        </Popup>

      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-data-table v-if="personnes.length > 0"
    :headers="headers"
    :items="personnes"
    :items-per-page="5"
     hide-default-footer
    class="elevation-1"
  >
      <template v-if="mode !== 'visualisation'" v-slot:item.actions="{ item }">
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  
  
  </v-data-table>
  </v-col>

<!--       
      <template>
    <div class="card">

        <slot name="test">blabla</slot>
    </div>
    </template> -->



      <!-------------------------------- BLOC PERSONNES RENCONTREES -------------------------------->
    </div>

</template>
<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
 import Popup from "@/components/shared/Popup";
 import api from "../../api/personne";
export default {
  props: {
    personnes: {
      type: Array,
      default: () => []
    },
    mode: [String],
    // disabled: {
    //   type: Boolean,
    //   default: false
    // }
  },
  components: {
    Popup
  },
  data() {
    return {
        message: "Supprimer cette personne ??",
        headers: [
        { text: 'Civilité', align: 'start', value: 'civilite', sortable: false },
        { text: 'Nom', value: 'nom', sortable: false },
        { text: 'Prenom', value: 'prenom', sortable: false },
        { text: 'Fonction', value: 'fonction', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Telephone', value: 'telephone', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      dialogDelete: false,
      dialogExisting: false,
      disabled: false,
      disabledCheckbox: false,
      dialog: false,
      height: "",
      width: "",
      personne: {
        civilite: "",
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        fonction: "",
        menu: false,
      },
      contacts: [],
      nbcontactsSelected: 0,
      personneToAdd: []
    };
  },
  validations: {
    personne: {
      nom: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      prenom: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      civilite: {
        required,
      },
      email: {
        required,
        email,
      },
      telephone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  computed: {

  },
  watch: {
    async personnes(new_value, old_value) {
      await this.getContactEntreprise(
        "",
        this.$route.params.id_entreprise,
        0,
        "nom",
        "ASC"
      );
      this.disableButton()
      console.log(old_value, "show résult", new_value);
      
    }
  },
  methods: {
    async getContactEntreprise(query, id_client, page, column, order) {
    let contacts = await api.getAllEntrepriseContacts(query, id_client, page, column, order)

               contacts.data.personne.rows.forEach((personne) => {
            personne["nom_prenom"] = personne["prenom"] + ' ' + personne["nom"];
          });
          this.contacts = contacts.data.personne.rows;
          if ( this.contacts.length == 0) {
            this.disabledExisting = true
          }

            this.contacts.forEach((contact) => {
              this.personnes.forEach((personne) => {
                if ( personne.id_personne == contact.id_personne ) {
                    contact["value"] = true;
                }
              })
            });
          

    },
    submitExistingContact(){
      let personne

      // Tous les contacts cochés
      let valueContacts = this.contacts.filter(
        (contact) => contact.value === true
      );

      // Pour chaque contact coché
      valueContacts.forEach((p) => {
        personne = {
          id_personne: p.id_personne,
          nom: p.nom,
          prenom: p.prenom,
          civilite: p.civilite,
          email: p.email,
          telephone: p.telephone
        }

        this.personneToAdd.push(personne)
        // console.log(valueContacts)
        // console.log(personne)
        // console.log(this.personnes)
      })

      // console.log("personneToAdd", this.personneToAdd)
      // Suppression des contacts déjà existant
      var prop_personnes = this.personnes

        prop_personnes = prop_personnes.filter(
            (person) => person.id_personne == undefined
        );

        // console.log("prop_personnes", prop_personnes)

      prop_personnes.forEach((pers) => {
        this.personneToAdd.push(pers)
      });

      console.log("submit existing contat",  this.personneToAdd)
      this.$emit('emitPersonnes', this.personneToAdd)
      this.disableButton(this.personneToAdd.length)
      this.personneToAdd = []
      this.dialogExisting = false


    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dialog = false
        this.personnes.push({
          civilite: this.personne.civilite,
          prenom: this.personne.prenom,
          nom: this.personne.nom,
          email: this.personne.email,
          telephone: this.personne.telephone,
          fonction: this.personne.fonction,
        });
        console.log(this.personnes);

        this.personne = {
          civilite: "",
          nom: "",
          prenom: "",
          email: "",
          telephone: "",
          fonction: "",
          menu: false,
        };
        this.$v.$reset();
      }

      // disable button
      if (this.personnes.length >= 4) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    nbcontacts() {
      let c = this.contacts.filter((res) => {
        return res.value == true;
      });
      // console.log("au chage", c);

      // Tous les contacts cochés
      let newPersonnes = this.personnes.filter(
        (perso) => perso.id_personne == undefined
      );


      this.nbcontactsSelected = c.length;
      let length = (parseInt(newPersonnes.length) + parseInt(c.length))
      let checkboxes = true
      this.disableButton(length, checkboxes)
    },
    disableButton(length, checkboxes) {


      if( checkboxes ) {
          if (length > 4) {
          this.disabledCheckbox = true;
        } else {
          this.disabledCheckbox = false;
        }
      } else {

          this.personnes.length = length !== undefined ? length : this.personnes.length
          if (this.personnes.length >= 4) {
          this.disabled = true;
          this.disabledExisting = true;
        } else {
          this.disabledExisting = false;
          this.disabled = false;
        }

      }


    },
    deleteItem (item) {
      this.editedIndex = this.personnes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.contacts.find((c) => {
          if( (c.id_personne == this.personnes[this.editedIndex].id_personne)  ) {
            c.value = false
          } 
      });

      this.personnes.splice(this.editedIndex, 1)
      this.$emit('emitPersonnes', this.personnes)
      this.disableButton()
      this.closeDelete()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  async created() {

    if ( this.mode == "visualisation") {
      this.headers = [
        { text: 'Civilité', align: 'start', value: 'civilite', sortable: false },
        { text: 'Nom', value: 'nom', sortable: false },
        { text: 'Prenom', value: 'prenom', sortable: false },
        { text: 'Fonction', value: 'fonction', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Telephone', value: 'telephone', sortable: false },
      ]
    }

          await this.getContactEntreprise(
        "",
        this.$route.params.id_entreprise,
        0,
        "nom",
        "ASC"
      );

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.calendar-field {
  width: 20%;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.font-small {
  font-size: 0.75rem;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

.actions{
    padding: 2% 5%;
    font-weight: bold;
    font-size: 1.2em;
}

::v-deep .menu-chips.theme--dark.v-list,
.v-chip.v-chip--clickable.v-chip--no-color.v-chip--pill.v-chip--removable.theme--light.v-size--default,
::v-deep .v-icon.notranslate.v-chip__close.v-icon--link.v-icon--right.mdi.mdi-delete.theme--light {
  background: $background-color-blue;
  color: #fff;
}
</style>
