var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"documents"}},[_c('v-col',{attrs:{"cols":"12","lg":"12","xl":"12"}},[_c('v-container',{staticClass:"padd_top",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{attrs:{"color":"#0B87BA","flat":"","dense":""}},[_c('v-col',{staticClass:"pa-0 white--text font-weight-bold text-left",attrs:{"cols":"6","lg":"6","xl":"2"}},[_vm._v(" VOS DOCUMENTS / DOSSIERS ")]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"6","lg":"6","xl":"4","offset-xl":"6"}},[_c('v-btn',{staticClass:"btnBold mr-5 font-weight-bold ",attrs:{"color":"#FF9900","dark":"","rounded":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-plus ")]),_vm._v(" AJOUTER UN DOSSIER ")],1),_c('v-btn',{staticClass:"btnBold font-weight-bold ",attrs:{"color":"#FF9900","dark":"","rounded":""},on:{"click":_vm.onButtonClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-plus ")]),_vm._v(" AJOUTER UN DOCUMENT ")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"*"},on:{"change":_vm.onFileChanged}})],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"12","xl":"12"}},[_c('v-toolbar',{staticClass:"mb-12",attrs:{"color":"#55ABCF","flat":"","dense":""}},[_c('v-col',{staticClass:"pa-0 font_color text-left",attrs:{"cols":"12"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":">","active-class":"active-item"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-breadcrumbs-item',{attrs:{"href":item.href},on:{"click":function($event){return _vm.updateDirectory(item)}}},[(!item.home)?_c('span',{staticClass:"white--text font-weight-bold ",class:{ disable: item.disable,active:!item.disable }},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(item.home)?_c('v-icon',[_vm._v("fa fa-home")]):_vm._e()],1)]}}])})],1)],1),_c('v-card',{ref:"dropzone",staticClass:"pa-2",attrs:{"tabindex":"0","title":"Glisser/déposer un ou plusieurs fichiers","width":"100%","height":"500"}},[(_vm.mode =='entreprises')?_c('v-row',[_vm._l((_vm.directories),function(directory){return _c('v-col',{key:directory.name,attrs:{"cols":"2"}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/folder.png"),"height":"100"},on:{"click":function($event){return _vm.updateCurrentDirectory(directory.value,directory.name)}}})]),_c('div',[_vm._v(" "+_vm._s(directory.name)+" ")]),_c('div',[(directory.name != 'projets')?_c('img',{staticClass:"pointer",attrs:{"src":require("@/assets/poubelle.png")},on:{"click":function($event){_vm.directoryToDelete = directory;_vm.dialogDeleteDirectory = true}}}):_vm._e()])])}),_vm._l((_vm.files),function(file){return _c('v-col',{key:file.id_document,attrs:{"cols":"2"}},[_c('div',[(file.chemin.split('.').pop() == 'pdf')?_c('img',{attrs:{"src":require("@/assets/img/pdf.png"),"alt":""}}):(
                    file.chemin.split('.').pop().substring(0, 3) == 'doc' ||
                    file.chemin.split('.').pop().substring(0, 3) == 'txt'
                  )?_c('img',{attrs:{"src":require("@/assets/img/word.png"),"alt":""}}):(
                    file.chemin.split('.').pop().substring(0, 3) == 'xls' ||
                    file.chemin.split('.').pop().substring(0, 3) == 'csv'
                  )?_c('img',{attrs:{"src":require("@/assets/img/xls.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img/type_image.png"),"alt":""}})]),_c('div',[_vm._v(_vm._s(file.document.substring(file.document.indexOf('-')+1)))]),_c('div',[_c('a',{attrs:{"target":"-blanck"},on:{"click":function($event){return _vm.download(file)}}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('img',{staticClass:"pointer",attrs:{"src":require("@/assets/poubelle.png")},on:{"click":function($event){_vm.fileToDelete = file;_vm.dialogDeleteFile = true}}})])])})],2):_vm._e()],1)],1),(_vm.mode == 'projets')?_vm._l((_vm.directories),function(directory){return _c('v-col',{key:directory.name,attrs:{"cols":"2"}},[((_vm.$store.state.AsUser.id_utilisateur == ''
                ? _vm.$store.state.user.id_role
                : _vm.$store.state.AsUser.id_role) == 1 ||
                  _vm.commerciaux.includes(
                    _vm.$store.state.AsUser.id_utilisateur == ''
                      ? _vm.$store.state.user.id_utilisateur
                      : _vm.$store.state.AsUser.id_utilisateur
                  ))?_c('router-link',{staticClass:"linkProjet",attrs:{"to":{ name: 'update_projet', params: { id_entreprise: _vm.$route.params.id_entreprise,id_projet:directory.value }}}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/folder.png"),"height":"100"}})]),_c('div',[_vm._v(" "+_vm._s(directory.name)+" ")])]):_c('router-link',{staticClass:"linkProjet",attrs:{"to":{ name: 'view_projet', params: { id_entreprise: _vm.$route.params.id_entreprise,id_projet:directory.value }}}},[_c('div',[_c('img',{attrs:{"src":require("@/assets/folder.png"),"height":"100"}})]),_c('div',[_vm._v(" "+_vm._s(directory.name)+" ")])])],1)}):_vm._e()],2)],1)],1),_c('Popup',{attrs:{"dialog":_vm.dialog,"text":_vm.messageDelete,"actions":_vm.actions},on:{"delete":_vm.createDirectory,"nothing":function($event){_vm.dialog=false}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3"}},[_c('v-text-field',{staticClass:"mt-5",attrs:{"label":"Dossier","placeholder":"Dossier","outlined":""},model:{value:(_vm.directory),callback:function ($$v) {_vm.directory=$$v},expression:"directory"}})],1)],1)]},proxy:true}])}),_c('Popup',{attrs:{"dialog":_vm.dialogDeleteDirectory,"text":_vm.messageDeleteDirectory,"actions":_vm.actionsDeleteDirectory},on:{"delete":_vm.deleteDirectory,"nothing":function($event){_vm.dialogDeleteDirectory=false}}}),_c('Popup',{attrs:{"dialog":_vm.dialogDeleteFile,"text":_vm.messageDeleteFile,"actions":_vm.actionsDeleteFile},on:{"delete":_vm.deleteFile,"nothing":function($event){_vm.dialogDeleteFile=false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }