import axios from 'axios';

const RESSOURCE_NAME = 'retours';

export default {

    getEntrepriseRetours(query = "", idEntreprise, page = 0, column = 'nom', order = 'ASC') {
        return axios.post(`${RESSOURCE_NAME}/${idEntreprise}/retours?page=` + page + `&size=` + process.env.VUE_APP_NB_DISPLAY + `&orderBy=` + column + `&sortBy=` + order + ``, { query: query })
    },
    createRetour(retour, retour_types_champs, retour_a_articles){
        return axios.post(`${RESSOURCE_NAME}/add`,{
            retour: retour,
            retour_a_articles: retour_a_articles,
            retour_types_champs: retour_types_champs

        })
    },
    updateRetour(retour, retour_types_champs, retour_a_articles, print){
        let link
        if ( print == 'true') {
            link = `${RESSOURCE_NAME}/update/`+retour.retour.id_retour+`/print`
        } else {
            link = `${RESSOURCE_NAME}/update/`+retour.retour.id_retour
        }

        return axios.post(link,{
            retour: retour,
            retour_a_articles: retour_a_articles,
            retour_types_champs: retour_types_champs

        })
    },
    deleteRetour(id_retour){
        console.log('retour api', id_retour)
        return axios.delete(`${RESSOURCE_NAME}/delete/${id_retour}`)
    },

    downloadRetour(numero_retour){
        return axios.post(`${RESSOURCE_NAME}/download/retour`, {numero_retour: numero_retour}, {responseType: 'blob'})
    },
    
    getRetour(id_retour) {
        return axios.get(`${RESSOURCE_NAME}/${id_retour}`)

    },
    // getPersonne(id_personne) {
    //     return axios.get(`${RESSOURCE_NAME}/${id_personne}`)

    // },
    // updateContact(contact, id_personne) {
    //     return axios.post(`${RESSOURCE_NAME}/update/${id_personne}`, { contact })
    // },
    // archivePersonne(id_personne) {
    //     return axios.post(`${RESSOURCE_NAME}/archive/${id_personne}`)

    // },
}