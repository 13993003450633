<template>
  <v-row>
    <v-col cols="12">
      <Title :titlePage="titlePage" />
    </v-col>

    <v-container>
      <ContactForm :contact="contact" @contact="createOrUpdadateContact" />
      <Popup :dialog="dialog" :text="messageCreation" :actions="actions" />
    </v-container>
  </v-row>
</template>
<script>
import Title from "@/components/shared/Title";
import ContactForm from "@/components/Clients/ContactForm";
import Popup from "@/components/shared/Popup";

import api from "@/api/personne";

export default {
  components: {
    Title,
    ContactForm,
    Popup,
  },
  data() {
    return {
      titlePage: "NOUVEAU CONTACT",
      contact: {
        civilite: "",
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        fonction: "",
      },
      dialog: false,
      messageCreation: "",
      actions: {
        route: [
        {
          btn: "OK",
          routeName: "client",
          params: { id_entreprise: this.$route.params.id_entreprise },
        },
      ]
      },
      mode: "creation",
    };
  },

  methods: {
    createOrUpdadateContact(contact) {
      contact.id_entreprise = this.$route.params.id_entreprise;
      this.contact = contact;
      if (this.mode == "creation") {
         this.messageCreation =
        contact.civilite +
        " " +
        contact.prenom +
        " " +
        contact.nom +
        " a été ajouté à l'entreprise";
        api.createContact(contact).then(() => {
          this.dialog = true;
        });
      }else{
        console.log(contact,'dans la modification');
         this.messageCreation = "Les informations de "+
        contact.civilite +
        " " +
        contact.prenom +
        " " +
        contact.nom +
        " ont été mises a jour";
         api.updateContact(contact,this.$route.params.id_contact).then((res) => {
          console.log(res);
          this.dialog = true;
        });
      }

    },
    getPersonne(id_contact) {
      api.getPersonne(id_contact).then((res) => {
        this.contact = res.data.contact;
      });
    },
    
  },
  created() {
    if (this.$route.name == "update_contact") {
      this.titlePage = "MODIFIER UN CONTACT";
      this.mode = "modification";
      this.getPersonne(this.$route.params.id_contact);
    }
  },
};
</script>
<style>
</style>