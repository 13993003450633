<template>
  <div>
    <v-btn
      class="add hover"
      absolute
      right
      top
      color="#2F7BB4"
      index="99"
      dark
      @click="showForm"
    >
      Ajouter un itinéraire
    </v-btn>

  <div     v-if="show == true" class="formItineraire "> 
      <v-card

    class="mx-auto add hover scroll"
    max-width="344"
    max-height="700"

  >
    <v-card-text>
                    <img
                class="pl-2 cross"
                @click="closeForm()"
                src="@/assets/cross-close.png"
              />
      <!-- <div v-if="depart.raison_sociale !== null">
  Start Loaded!
</div> -->
       <p class="left">Point de départ : <b>{{departForm.raison_sociale}}</b> </p>
              <v-autocomplete
                :items="entreprises_start"
                item-value="id_entreprise"
                item-text="raison_sociale"
                v-model="departForm"
                light
                outlined
                dense
                color="#CFCFCF"
                cache-items
                :search-input.sync="searchStart"
                placeholder="Entrez un point de départ"
                no-data-text="Aucune entreprise de trouver"
                return-object
              >
                <!-- <template  v-slot:placeholder >
               <span class="red--text">blabla</span>
              </template> -->
              </v-autocomplete>


            <!-- {{arriveeForm}} -->
            <div v-for="(destination, index) in arriveeForm" :key="destination.arriveeForm">
                <p v-if="index == 0 && arriveeForm.length > 1" class="left"> <b>Etapes:</b>  </p>
                <p v-if="index == arriveeForm.length - 1" class="left">Point d'arrivée : <b>{{destination.raison_sociale}}</b>  </p>
                <!-- {{index}} -->
              <v-autocomplete
                :items="entreprises_end"
                item-value="id_entreprise"
                item-text="raison_sociale"
                v-model="arriveeForm[index]"
                light
                outlined
                dense
                color="#CFCFCF"
                cache-items
                :search-input.sync="searchEnd[index]"
                no-data-text="Aucune entreprise de trouver"
                placeholder="Entrez un point d'arrivée"
                return-object
              >
              </v-autocomplete>
            </div>

              <div v-if="showDetails !== '' && emptyValues == false">
                <h3> Trajet </h3>
                <p class=" font-weight-black"> {{ showDetails}} </p>
              </div>

              <div v-if="emptyValues">
                <p class=" font-weight-black" style="color: red;"> Veuillez compléter le trajet. </p>
              </div>
              <v-btn class="mr-2" @click="addDestination()"  color="secondary" elevation="2" raised small>Ajouter une destination</v-btn>
              <v-btn class="mt-2 mr-2" @click="resetWaypoint()"  color="secondary" elevation="2" raised small>Réinitialiser</v-btn>
              <v-btn class="mt-2 mr-2" @click="showWaypoint()"  color="primary" elevation="2" raised small >Calculer</v-btn>
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn
        text
        color="teal accent-4"
        @click="reveal = true"
      >
        Learn More
      </v-btn> -->
    </v-card-actions>

  </v-card>
  </div>


 </div>
</template>

<script>
import apiEntreprises from "../../api/entreprise";

export default {
  components: { },
  props: {
    depart: {
      type: Object,
      required: false,
      default: function () {
        
        return {
        id_entreprise: null,
        raison_sociale: null,
        longitude: null,
        latitude:  null,   
      }
      }
    },
    arrivee: {
      type: Array,
      default: () => [],
    },
    waypoints: {
    type: Array,
    required: true
    },
  },
  data() {
    return {
      departForm: {
        id_entreprise: null,
        raison_sociale: null,
        longitude: null,
        latitude:  null,   
      },
      arriveeForm: [{
        id_entreprise: null,
        raison_sociale: null,
        longitude: null,
        latitude:  null,   
      }],
      show: false,
      emptyValues: false,
      showDetails: '',
      entreprises_start: [],
      entreprises_end: [],
      nbResult : 3,
      searchStart: null,
      searchEnd: [],
    }
  },
  methods:{
    showForm() {
      this.show = true
    },
    closeForm() {
      this.show = false
    },
    addDestination() {
      var destinationObj = {
        id_entreprise: null,
        raison_sociale: null,
        longitude: null,
        latitude:  null, 
      }
      this.arriveeForm.push(destinationObj)
    },
    showWaypoint() {
      // this.$emit('resetWaypoints', [{lat: null, lng: null}])
      this.emptyValues = false;
      console.log("departForm", this.departForm)
      this.waypoints[0].lat = this.departForm.latitude
      this.waypoints[0].lng = this.departForm.longitude

      console.log(this.arriveeForm, "arriveeForm calcul")

      
      for (let destination of this.arriveeForm) {
        console.log(destination, "destination calcul")
        var obj = {
          lat: destination.latitude,
          lng: destination.longitude
        }
        console.log(obj, "obj calcul")
        this.waypoints.push(obj);
      }
      this.waypoints.forEach(waypoint => {
        if (waypoint.lat == null) {
          this.emptyValues = true
        }
      });

      if ( this.emptyValues) return false;
      console.log(this.waypoints, "waypoints calcul")
      // this.waypoints[1].lat = this.arriveeForm[0].latitude
      // this.waypoints[1].lng = this.arriveeForm[0].longitude

      // this.waypoints[2].lat = this.arriveeForm[1].latitude
      // this.waypoints[2].lng = this.arriveeForm[1].longitude

      setTimeout(function () { this.showItineraire() }.bind(this), 1000)
      
      console.log("show details", this.showDetails)
      
      console.log("coordinates", this.waypoints)
    },
    showItineraire() {
      this.showDetails = document.querySelector('.leaflet-routing-alt h3').firstChild.data
      // this.$emit('resetWaypoints', [{lat: null, lng: null}])

    },
    resetWaypoint() {
      // this.arriveeForm = []
      this.empty = false;
      this.arriveeForm = [
        {
          id_entreprise: null,
          raison_sociale: '',
          longitude: null,
          latitude:  null,   
        }
      ]
      this.$emit('resetArrivee', this.arriveeForm)
      this.searchEnd = []
      this.searchEndClone = []
      this.$emit('resetWaypoints', [{lat: null, lng: null}])

      this.departForm = Object.assign({}, this.departForm, {
        id_entreprise: null,
        raison_sociale: null,
        longitude: null,
        latitude:  null,   
      })

      // this.arriveeForm = Object.assign({}, this.arriveeForm, {
      //   id_entreprise: null,
      //   raison_sociale: null,
      //   longitude: null,
      //   latitude:  null,   
      // })

      this.showDetails = ''

      // this.departForm = null
      // this.arriveeForm = null
    },
    searchEntrepriseStart(query,nbResult){
        apiEntreprises.getEntreprises(nbResult,query).then((res) => {
        this.entreprises_start = res.data.entreprise.rows
        for ( var i = 0; i < this.entreprises_start.length; i++) {
          this.entreprises_start[i]= {
            raison_sociale: this.entreprises_start[i].raison_sociale,
            id_entreprise: this.entreprises_start[i].id_entreprise,
            longitude: this.entreprises_start[i].longitude,
            latitude: this.entreprises_start[i].latitude,
          }
        }
        // console.log("entreprises start", this.entreprises_start)
      });
    },
    searchEntrepriseEnd(query,nbResult){
      apiEntreprises.getEntreprises(nbResult,query).then((res) => {
      this.entreprises_end = res.data.entreprise.rows
      for ( var i = 0; i < this.entreprises_end.length; i++) {
        this.entreprises_end[i].coordinates = {
          id_entreprise: this.entreprises_end[i].id_entreprise,
          longitude: this.entreprises_end[i].longitude,
          latitude: this.entreprises_end[i].latitude,
        }
      }
    });
    },
    setSearchEndLoop: function(index, destination){
      this.searchEnd[index] = destination.raison_sociale
      this.searchEntrepriseEnd(this.searchEnd[index], this.nbResult)
      return this.searchEnd[index];
    },
  },
  watch:{
    searchStart(newValue,oldValue){
      console.log("newValue :", newValue, "oldValue : ", oldValue)
      if(newValue != oldValue){
        this.searchEntrepriseStart(newValue,this.nbResult)
        console.log("depart", this.depart)
      }
    },
    // searchEnd(newValue,oldValue){
    //   console.log("newValue :", newValue, "oldValue : ", oldValue)
    //   if(newValue != oldValue){
    //     this.searchEntrepriseEnd(newValue,this.nbResult)
    //   }
    // },
    searchEndClone: {
      handler: function (newValue,oldValue) {
        console.log("newValue :", newValue, "oldValue : ", oldValue, "search end watch")
        console.log(this.searchEnd, "search end de base", this.searchEnd.length)
          for ( let newVal of newValue) {
            for ( let oldVal of oldValue) {
              if ( newVal !== oldVal) {
                console.log("newVal :", newVal, "oldVal : ", oldVal, "search end comparison")
                this.searchEntrepriseEnd(newVal,this.nbResult)
              }
            }
          }

        // this.searchEntrepriseEnd(newValue[0].raison_sociale,this.nbResult)
      },
      deep: true
    },
    depart: {
      handler: function () {
        this.departForm = this.depart
        /* Faire apparaitre la popup */
        if ( this.departForm != null) {
          // this.searchStart = this.departForm.raison_sociale
          this.show = true
        }

        console.log('depart watcher ', this.depart)
        console.log('raison sociale depart: ', this.depart.raison_sociale)

      },
      deep: true
    },
    departForm: {
      handler: function () {
        this.searchStart = this.departForm.raison_sociale
      },
      deep: true
    },
    arrivee: {
      handler: function () {
        this.arriveeForm = this.arrivee
        // let lastIndex = this.arrivee.length - 1
        if( ( this.arriveeForm[0].raison_sociale == null || this.arriveeForm[0].raison_sociale == "" ) && (this.arriveeForm[1] !== undefined && this.arriveeForm[1].raison_sociale !== null) ) {
          this.arriveeForm[0] = this.arriveeForm[1];
          this.arriveeForm.pop();
        }

        // if(   this.arriveeForm[lastIndex - 1] !== undefined && 
        //     ( this.arriveeForm[lastIndex - 1].raison_sociale == null || this.arriveeForm[lastIndex - 1].raison_sociale == "" ) &&
        //     ( this.arriveeForm[lastIndex] !== undefined && this.arriveeForm[lastIndex].raison_sociale !== null) 
        //   ) 
        // {
        //   this.arriveeForm[lastIndex - 1] = this.arriveeForm[lastIndex];
        //   this.arriveeForm.pop();
        // }
        // if ( this.arriveeForm.length > 1 && this.arriveeForm[lastIndex].raison_sociale !== null && this.arriveeForm[lastIndex - 1].raison_sociale == null) {
        //   this.arriveeForm[lastIndex - 1] = this.arriveeForm[lastIndex];
        //   this.arriveeForm.pop();          
        // }
        console.log(this.arriveeForm, "arriveeform")
        console.log(this.arriveeForm.length - 1);
        let index = this.arriveeForm.length - 1
        this.setSearchEndLoop(index, this.arriveeForm[index]);
        // this.searchEnd[index] = this.arriveeForm[index].raison_sociale
        if ( this.arriveeForm != null) {
          this.show = true
        }
        // this.searchEnd = this.arriveeForm.raison_sociale
      },
      deep: true
    },
  },
  computed:{  //<-- Added
    searchEndClone: function(){
       return JSON.parse(JSON.stringify(this.searchEnd))
    },
  },
 mounted() {
    this.departForm = this.depart
    this.arriveeForm = this.arrivee
    this.searchEntrepriseStart(null,this.nbResult);
    this.searchEntrepriseEnd(null,this.nbResult)
 }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";


.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  left: 30rem;
  width: 100%;
}

.formItineraire {
	position: absolute;
	right: 10px;
	top: 10px;
}

.pl-2.cross {
	position: absolute;
	top: -5px;
	right: -4px;
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 85vh;
}

// .formItineraire ::placeholder {
//   color: black!important;
//   opacity: 1;
// }



</style>

   

