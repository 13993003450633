<template>
  <div style="display: none">
    <slot v-if="ready"></slot>
  </div>
</template>

<script>
import L from 'leaflet'
import {  IGeocoder, LineOptions } from 'leaflet-routing-machine'
import { findRealParent } from 'vue2-leaflet';
const props = {
  visible: {
    type: Boolean,
    default: true
  },
  waypoints: {
    type: Array,
    required: true
  },
  // router: {
  //   router: L.Routing.mapbox('pk.eyJ1Ijoib2dhdWNoZXIiLCJhIjoiY2txOWtldnBzMDJpZTJ2bXJ2MjM5MmJ4eiJ9.GNVaKdg6Ae-PPv_DIMrHmg')
  // },
  plan: {
    type: L.Routing.Plan
  },
  geocoder: {
    type: IGeocoder
  },
  fitSelectedRoutes: {
    type: [String, Boolean],
    default: 'smart'
  },
  lineOptions: {
    type: LineOptions
  },
  routeLine: {
    type: Function
  },
  autoRoute: {
    type: Boolean,
    default: true
  },
  routeWhileDragging: {
    type: Boolean,
    default: false
  },
  routeDragInterval: {
    type: Number,
    default: 500
  },
  waypointMode: {
    type: String,
    default: 'connect'
  },
  language: {
    type: String,
    default: 'fr'
  },
  useZoomParameter: {
    type: Boolean,
    default: false
  },
  showAlternatives: {
    type: Boolean,
    default: false
  },
  showItineraire: {
    type: Boolean,
    required: false
  },
  altLineOptions: {
    type: LineOptions
  }
}
// const optionTestNames = [
//   'router',
//   'plan',
//   'geocoder',
//   'lineOptions',
//   'routeLine',
//   'altLineOptions'
// ]
export default {
  props,
  name: 'LRoutingMachine',
  data() {
    return {
      parentContainer: null,
      ready: false,
      layer: null,
      w : this.waypoints
    }
  },
  mounted() {
    this.parentContainer = findRealParent(this.$parent)
    this.ready = true
  },
  beforeDestroy() {
    return this.layer ? this.layer.remove() : null
  },
  watch: {
    // showItineraire(newval) {
    //   console.log('Prop changed: ', newval)
    //   this.add()
    // },
    waypoints: {
      handler: function (val, oldVal) {
        console.log('a waypoint changed: ', oldVal, val)
        // console.log('waypoint 0 lat: ', this.waypoints[0].lat, 'waypoint 1 lat: ', this.waypoints[1].lat)
        if ( this.layer !== null) {
          this.layer.remove()
        }
        if ( this.waypoints[0].lat !== null ) {
          this.add()
        }
      },
      deep: true
    }
    // waypoints(newval) {
    //   console.log('waypoint changed: ', newval)
    //   this.add()
    // },
  },
  methods: {
    add () {
      if (this.parentContainer._isMounted) {
        const {
          language,
          waypoints,
          fitSelectedRoutes,
          autoRoute,
          routeWhileDragging,
          routeDragInterval,
          waypointMode,
          useZoomParameter,
          showAlternatives,
        } = this
        const options = {
          language,
          waypoints,
          fitSelectedRoutes,
          autoRoute,
          routeWhileDragging,
          routeDragInterval,
          waypointMode,
          useZoomParameter,
          showAlternatives,
          router: L.Routing.mapbox(process.env.VUE_APP_APIKEY_MAPBOX)
        }
        var { mapObject } = this.parentContainer
        const routingLayer = L.Routing.control(options)
        routingLayer.addTo(mapObject)
        this.layer = routingLayer
      }
    }
  }
}
</script>

<style>
  @import '../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css';

  .leaflet-routing-container.leaflet-bar.leaflet-control {
	position: relative;
	top: 20rem;
  display: none;
}

.leaflet-routing-container.leaflet-bar.leaflet-control :nth-child(3n) {
    display: none;
}
</style>