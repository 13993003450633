<template lang="">
  <div>
     <v-row>
      <v-col cols="12">
         <v-text-field
          v-model="query"
          placeholder="Rechercher un Client / Prospect / Projet"
          outlined
          class="rounded-lg"
          color="#FFF"
          background-color="#fff"
          hide-details
          @keyup.enter="search"
          dense
        
          >
          <template slot="append">
            <img  @click="search" src="@/assets/Rech Avancée.png" />
          </template>
          
          </v-text-field>
        
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data(){
    return {
      query:""
    }
  },
  
  methods:{
    search(){
      console.log(this.query)
      this.$router.push({name:'search',query:{recherche:this.query}})
    },
   
    
  }
};
</script>
<style scoped>
.rectangle_blanc {
  background-color: white;
  width: 100%;
  border-radius: 4px 0px 0px 4px;
}
.back_white {
  background-color: white;
  border-radius: 0 4px 4px 0;
  height: 30px !important;
}
.logo_recherche{
  height: 30px;
}
button.v-app-bar__nav-icon.back_white.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--default {
    margin-right: 1px;
}
::v-deep .v-input input,.v-input input:focus,input:focus,input,#input-38{
   caret-color: black;
}
::v-deep .v-input__append-inner{
  margin: 1% auto;
}
</style>
