<template lang="">

   
 


<div>
      <!-------------------------------- BLOC CREER UN RAPPEL-------------------------------->
      <v-col cols="12" sm="10" class="pb-0 mt-10">
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            RAPPELS
          </v-col>

           <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0 text-right">
            <v-btn v-if="mode !== 'visualisation'"
                class="btnBold"
                color="#FF9900"
                dark
                rounded
                @click="dialog=true"
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>
                Ajouter rappel
            </v-btn>
          </v-col>

        </v-toolbar>
      </v-col>
    <Popup :dialog="dialog" text="" actions="" heightProp="" widthProp="70vW" @emitDialog="dialog = $event" showClose="true">
  <v-col cols="12" sm="12" class="pb-0 px-10 pr-16 ">
    <form  @submit.prevent="submit">

       
        <v-toolbar color="#0B87BA" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            AJOUTER UN RAPPEL
          </v-col>

        </v-toolbar>
    
      <v-col cols="12" sm="10" class="pb-0  mt-0">
        <v-checkbox
          v-model="rappel.urgent"
          align="center"
          align-self="center"
          light
        >
          <template v-slot:prepend>
            <div class="labelCheckbox">
              <img src="@/assets/triangle_rouge.png" /> URGENT
            </div>
          </template>
        </v-checkbox>
      </v-col>

      <v-col cols="12" sm="10" class="pb-0">
        <div class="text-left">DATE DU RAPPEL*</div>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          light
        >
          <template v-slot:activator="{ on, attrs }">

              <span
              class="d-flex calendar-field">
                  <v-text-field
                      :value="formatDate"
                      class="mr-3"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      single-line
                      light
                      hide-details
                      outlined
    
                    ></v-text-field>
                               
                <img src="@/assets/calendar.png"/>
              </span>

          </template>

          <v-date-picker
            v-model="rappel.date_rappel"
            no-title
            locale="fr-fr"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
                  <!-- message d'erreur -->
          <div class="errorMsg" v-if="$v.rappel.date_rappel.$error"> Renseignez la date du rappel</div>
      </v-col>

      <v-col cols="12" sm="10"  class="alignement_input">
       <div class="text-left">COMMENTAIRE*

       </div>
        <v-textarea outlined auto-grow rows="1"  v-model="rappel.commentaire" hide-details> </v-textarea>
                           <!-- message d'erreur -->
          <div class="errorMsg" v-if="$v.rappel.commentaire.$error">
            Renseignez un commentaire pour le rappel
          </div>

      </v-col>

                                      <v-col cols="12" sm="12"  class="">
              <v-btn class="btnBold"  color="#FF9900" dark rounded type="submit" >
        <v-icon left> fa fa-plus </v-icon>
            <div >
              Ajouter ce rappel
            </div>
      </v-btn>
      </v-col>

   
    </form>
       </v-col> 
            </Popup>

      <!-- Popup  -->
      <Popup :dialog="dialogDelete" text="Supprimer ce rappel ?" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="false">
            <v-card-actions class="mb-10">
             
              <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="closeDelete">Non</v-btn>
              <v-btn class="actions" color="#0B87BA" width="100px"  large dark rounded @click="deleteItemConfirm">Oui</v-btn>
              
            </v-card-actions> 
      </Popup>


        <Popup :dialog="dialogCloseRappel" text="Êtes vous sûr de vouloir clore ce rappel ?" actions="" heightProp="" widthProp="" @emitDialog="dialog = $event" showClose="false">
            <v-card-actions class="mb-10">
         
              
              <v-btn class="actions mr-10" color="#0B87BA" width="100px"  large dark rounded @click="dialogCloseRappel = false">Non</v-btn>
              <v-btn class="actions" color="#0B87BA" width="100px" large dark rounded @click="CloseRappelConfirm">Oui</v-btn>
           
            </v-card-actions> 
      </Popup>

      <v-col cols="12" sm="10" class="pb-0  mt-10">
        <v-data-table v-if="rappels.length > 0"
    :headers="headers"
    :items="rappels"
    :items-per-page="50"
     hide-default-footer
    class="elevation-1"
  >
      <template v-slot:item.actions="{ item }">
      <v-icon v-if="item.id_rappel == undefined && item.actif == 1"
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
      <v-icon v-else-if="item.id_rappel != undefined && item.actif == 1"
        small
        @click="closeRappel(item)"
      >
        mdi-close
      </v-icon>
    </template>

    <template v-slot:item.date_rappel="{ item }">
          {{
          item.date_rappel ? $moment(item.date_rappel).format("DD/MM/YYYY") : ""
        }}
    </template>

        <template v-slot:item.urgent="{ item }">
          {{
          item.urgent == true ? "Oui" : "Non"
        }}
    </template>

    <template v-slot:item.actif="{ item }">
          {{
          item.actif==1 && item.actif?"Oui":"Non"
        }} 
    </template>
  
  
  </v-data-table>
  </v-col>
</div>

      <!-------------------------------- BLOC CREER UN RAPPEL-------------------------------->
   

</template>
<script>
import { required } from "vuelidate/lib/validators";
import Popup from "@/components/shared/Popup";
import moment from "moment";
// import api from "@/api/rappel";

export default {
    props: {
    rappels: {
      type: Array,
      default: () => []
    },
    mode: [String]
  },
  components: {
    Popup
  },
  data() {
    return {
      headers: [
        { text: 'Date', align: 'start', value: 'date_rappel', sortable: false },
        { text: 'Commentaire', value: 'description', sortable: false },
        { text: 'Urgent', value: 'urgent', sortable: false },
        { text: 'Actif', value: 'actif', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      dialogDelete: false,
      dialogCloseRappel:false,
      cloreRappel:null,
      dialog: false,
      menu1: false,
      rappel: {
        date_rappel: "",
        commentaire: "",
        menu: false,
      },
    };
  },
  validations: {
    rappel: {
      date_rappel: {
        required,
      },
      commentaire: {
        required,
      },
    },
  },
    computed: {
    formatDate() {
      return this.rappel.date_rappel
        ? moment(this.rappel.date_rappel).format("L")
        : "";
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.dialog = false
        this.rappels.push({
          date_rappel: this.rappel.date_rappel,
          description: this.rappel.commentaire,
          urgent: this.rappel.urgent,
          actif:1
        });
        console.log(this.rappels);
        this.rappel = {
          urgent: false,
          date_rappel: "",
          description: "",
          menu: false,
        };
        this.$v.$reset();
      }
    },
    deleteItem (item) {
      this.editedIndex = this.rappels.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.rappels.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeRappel(item){
      console.log(item,this.rappels.indexOf(item))
        this.dialogCloseRappel = true
        this.cloreRappel = item
    },
    CloseRappelConfirm(){
      this.dialogCloseRappel = false
      this.$emit("closeRappel",{id_rappel:this.cloreRappel.id_rappel})
    }
  },
  mounted() {
        if ( this.mode == "visualisation") {
      this.headers = [
        { text: 'Date', align: 'start', value: 'date_rappel', sortable: false },
        { text: 'Commentaire', value: 'description', sortable: false },
        { text: 'Urgent', value: 'urgent', sortable: false },
        { text: 'Actif', value: 'actif', sortable: false },
      ]
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables";
.calendar-field {
  width: 20%;
}

img {
  width: 29px;
  height: 28px;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
}

.width-50 {
  width: 30%;
}

.addPersonne {
  border-style: dashed;
  border-color: blue;
  color: blue;
}

.btnBold {
  font-weight: bold;
}

.errorMsg {
  text-align: left;
  color: red;
  /* margin-bottom: 0.7em;
  margin-top: 0.7em; */
}

.actions{
    padding: 2% 5%;
    font-weight: bold;
    font-size: 1.2em;
}

.labelCheckbox {
  align-items: center;
  display: inline-flex;
  flex: 1 1 auto;
  height: 24px;
}

::v-deep .menu-chips.theme--dark.v-list,
.v-chip.v-chip--clickable.v-chip--no-color.v-chip--pill.v-chip--removable.theme--light.v-size--default,
::v-deep .v-icon.notranslate.v-chip__close.v-icon--link.v-icon--right.mdi.mdi-delete.theme--light {

  background: $background-color-blue;
  color: #fff;
}
</style>
