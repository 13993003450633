var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('v-col',{staticClass:"pb-0 mt-10",attrs:{"cols":"12","sm":"10"}},[_c('v-toolbar',{attrs:{"color":"#0B87BA","flat":"","dense":""}},[_c('v-col',{staticClass:"pa-0 font_color text-left",attrs:{"cols":"6","lg":"6","xl":"2"}},[_vm._v(" PIECES JOINTES ")]),_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"6","lg":"6","xl":"4","offset-xl":"6"}},[(_vm.mode !== 'visualisation')?_c('v-btn',{staticClass:"btnBold",attrs:{"color":"#FF9900","dark":"","rounded":""},on:{"click":_vm.onButtonClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" fa fa-plus ")]),_vm._v(" Ajouter une piece jointe ")],1):_vm._e(),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"*"},on:{"change":_vm.onFileChanged}})],1)],1),_c('div',{staticClass:"text-left pt-12"},[_c('v-sheet',{ref:"dropzone",staticClass:"pa-2",attrs:{"tabindex":"0","width":"100%"}},[(_vm.documents.length == 0)?_c('p',[_vm._v(" Aucune pièce jointe n'a été ajoutée")]):_vm._e(),(_vm.documents.length>0)?_c('v-list-item',{attrs:{"two-line":""}},_vm._l((_vm.documents),function(document,index){return _c('v-list-item-content',{key:document.files.lastModified,attrs:{"name":document.files.name},on:{"remove":function($event){return document.splice(index, 1)}}},[_c('v-list-item-title',{staticClass:"d-flex justify-center"},[_c('a',{class:{ 'mr-1': index < _vm.documents.length - 1 },attrs:{"href":document.chemin,"target":"_blank"}},[(document.files.name.split('.').pop() == 'pdf')?_c('img',{attrs:{"src":require("@/assets/pdf.png"),"alt":""}}):(
                 document.files.name
                   .split('.')
                   .pop()
                   .substring(0, 3) == 'doc' ||
                   document.files.name
                     .split('.')
                     .pop()
                     .substring(0, 3) == 'txt'
               )?_c('img',{attrs:{"src":require("@/assets/doc.png"),"alt":""}}):(
                 document.files.name
                   .split('.')
                   .pop()
                   .substring(0, 3) == 'xls' ||
                   document.files.name
                     .split('.')
                     .pop()
                     .substring(0, 3) == 'csv'
               )?_c('img',{attrs:{"src":require("@/assets/excel.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/img.png"),"alt":""}})])]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(document.files.name.substring(document.files.name.indexOf('-') + 1))+" ")]),_c('p',{staticClass:"d-flex justify-center"},[_vm._v("ajouté le : "+_vm._s(_vm.$moment(document.createdAt).format('DD/MM/YYYY')))]),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center"},[(_vm.mode !== 'visualisation')?_c('v-btn',{on:{"click":function($event){return _vm.deleteDocument(document, index, true)}}},[_c('strong',[_vm._v("Supprimer")])]):_vm._e()],1)],1)}),1):_vm._e()],1)],1)],1),_c('Popup',{attrs:{"dialog":_vm.dialog,"text":"Supprimer ce document ?","actions":"","heightProp":"","widthProp":"","showClose":"false"},on:{"emitDialog":function($event){_vm.dialog = $event}}},[_c('v-card-actions',{staticClass:"mb-10"},[_c('v-btn',{staticClass:"actions mr-10",attrs:{"color":"#0B87BA","width":"100px","large":"","dark":"","rounded":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Non")]),_c('v-btn',{staticClass:"actions",attrs:{"color":"#0B87BA","width":"100px","large":"","dark":"","rounded":""},on:{"click":function($event){return _vm.deleteDocument(_vm.document, _vm.index, false)}}},[_vm._v("Oui")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }