<template>
  <div class="d-flex justify-end">
    <div class="client flex-grow-1">
      <Title :titlePage="titlePage" :id_statut_entreprise="id_statut_entreprise" :idClientEnCours="idClientEnCours" :currentTab="currentTab" :users="users" @transformClient="id_statut_entreprise = $event.id_statut_entreprise" />
      <v-tabs
        v-model="currentTab"
        background-color="#0b87ba"
        class="gradient_color"
        dark
      >
        <v-col class="margin-left" cols="12" sm="6" md="1"></v-col>
        <v-tab v-for="item in items" :key="item.id" @change="updateCurrentTab(item.id)" >
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" class="mb-16" :eager="true">
        <v-tab-item v-for="item in items" :key="item.id" >
          <v-card flat>
            <v-card-text>
              <component v-bind:is="item.content" v-on:title="updateTitle($event)" @userentreprise="UsersEntreprise" v-on:id_statut_entreprise="statutEntreprise($event)" ></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import Client from "./../components/Clients/FicheClient";
import Projets from "./../components/Clients/Projets";
import Rapports from "./../components/Clients/Rapports";
import Retours from "./../components/Clients/Retours";
import Reporting from "./../components/Clients/Reporting";
import Contacts from "./../components/Clients/Contacts";
import Documents from "./../components/Clients/Documents";

import Title from "@/components/shared/Title";

export default {
  components: {
    Title,
    Client,
    Projets,
    Rapports,
    Retours,
    Reporting,
    Contacts,
    Documents,
  },
  data() {
    return {
      tab: null,
      id_statut_entreprise:"",
      titlePage: "",
      items: [
        { tab: "FICHE CLIENT", content: "Client",id:0 },
        { tab: "PROJETS", content: "Projets" ,id:1},
        { tab: "RAPPORTS", content: "Rapports" ,id:2},
        { tab: "RETOURS", content: "Retours" ,id:3},
        { tab: "REPORTING", content: "Reporting" ,id:4},
        { tab: "CONTACTS", content: "Contacts",id:5 },
        { tab: "DOCUMENTS", content: "Documents",id:6 },
      ],
      idClientEnCours: 0,
      currentTab:5,
      users:[]
    };
  },
  methods: {
    statutEntreprise : function(e){
      this.id_statut_entreprise=e
    },
    updateTitle : function(e){
      this.titlePage = e
      // this.titlePage += ` (${this.$store.state.entreprise.code_fta})`;
      // console.log('tabbb',this.tab);
    },
    updateCurrentTab($event){
      this.currentTab = $event;
      console.log(this.currentTab,"current tab");
      this.$store.commit("changeOnglet",$event)
    },
    UsersEntreprise(users){
      console.log('users entreprises',users)
      this.users=users;
    }
  },

  created() {
    this.idClientEnCours = this.$route.params.id_entreprise;
    this.currentTab =  this.$store.state.currentTab;
    console.log(this.tab,'tab current in store')
  },
};
</script>
<style scoped>
::v-deep .v-item-group.theme--dark.v-slide-group.v-tabs-bar.white--text {
  background: linear-gradient(
    270deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(11, 135, 176, 1) 80%,
    rgba(11, 135, 186, 1) 100%
  ) !important;
}
.v-tab.v-tab--active {
    background-color: white;
    color: #0B87BA;
    font-weight: bold;
    margin: 7px;
}
::v-deep .v-tabs-slider {
    display: none ;
}
.margin-left.col-sm-6.col-md-1.col-12{
  margin-left: 32px;
}
.v-tab {
    font-weight: bold;
}
</style>
