<template>
  <v-container fluid>
    <v-row>
      <v-col cols="8"
        ><h2>{{ entreprise.raison_sociale }} ({{ entreprise.code_client_fta }})</h2> <span v-if="entreprise.actif == 0" style="line-height: 1.2em; font-weight: bold; color: red; font-size: 24px;">(désactivé) </span></v-col
      >
      <v-col cols="4">
        <router-link
          class="bold btn"
          :to="{
            name: 'client',
            params: { id_entreprise: entreprise.id_entreprise },
          }"
         
          > <span  @click="$store.state.currentTab = 0"> VOIR LA FICHE </span></router-link
        >
        <!-- <v-btn
          class="bold"
          color="#FF9900"
          dark
          rounded
          block
          text
          :to="{ name: 'client', params: { id: entreprise.id_entreprise } }"
          >VOIR LA FICHE</v-btn
        > -->
      </v-col>
    </v-row>
    <v-row no-gutters align-self="center">
      <v-col>
        <p class="bold details mt-2">
          {{ entreprise.adresse }}<br />
          {{ entreprise.code_postal }} {{ entreprise.ville
          }}<br
            v-if="
              (entreprise.email_entreprise &&
                entreprise.email_entreprise.length > 0) ||
                entreprise.telephone_fixe.length
            "
          />
          <a
            v-if="entreprise.email_entreprise"
            class="mt-1"
            :href="
              `mailto:${
                entreprise.email_entreprise ? entreprise.email_entreprise : ''
              }`
            "
            >{{
              entreprise.email_entreprise ? entreprise.email_entreprise : ""
            }}</a
          >
          <br v-if="entreprise.telephone_fixe.length" />
          <a :href="`tel:${entreprise.telephone_fixe}`">{{
            entreprise.telephone_fixe.length == 10
              ? entreprise.telephone_fixe
                  .split(
                    /([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})/
                  )
                  .join(" ")
              : entreprise.telephone_fixe
          }}</a>
        </p>
      </v-col>
      <v-col v-if="i + 1 < max" cols="12"><hr /></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["entreprise", "i", "max"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
h2 {
  color: $background-color-blue;
  font-weight: bold;
  line-height: 1.2em;
}
a {
  color: $font-color-link-search-result;
  text-decoration: none;
  display: inline-block;
}
.bold {
  font-weight: bold;
}
.details {
  font-size: 1em;
  color: $font-color-search-result;
}
a.bold.btn {
  color: $background-color-btn;
}
hr {
  color: $table-background-secondary-color;
}
</style>
