<template lang="">
    <div id="contacts">

  <v-col cols="12" lg="12" xl="12">
  
    <v-container class="padd_top" fluid>
      <v-row no-gutters>

      <v-col cols="12" lg="12" xl="12">
        <v-toolbar color="#55ABCF" flat dense>
          <v-col
            cols="6"
            lg="6"
            xl="2"
            class="pa-0 font_color text-left"
          >
            CONTACTS
          </v-col>
          <v-col cols="6" lg="6" xl="4" offset-xl="6"  class="pa-0 text-right">


            <!-- <v-toolbar-title class="rectangle_blanc"> -->
              <router-link v-if="canModif == 1" id="addContact" :to="{ name: 'new_contact', params: { id_entreprise: $route.params.id_entreprise }}">
            <v-btn
                class="btnBold"
                color="#FF9900"
                dark
                rounded  
                tag="button"             
                >
                <v-icon left>
                    fa fa-plus
                </v-icon>               
          AJOUTER UN CONTACT
            </v-btn>
            </router-link>
            <!-- </v-toolbar-title> -->
          </v-col>
        </v-toolbar>
      </v-col>
      
      </v-row>
      
      <v-row align="center">
        <v-col cols="12" lg="12" xl="12">
          <resultList
            :header="header"
            :items="contacts"
            :nbResult=displayItem
            :type="'contact'"
            @sort="sort"
            :totalItems="totalItems"
            :page="page"
            id="encours"
            @delete="dialog=true,idDeleteContact=$event"
            :canModif="canModif"
          />
        </v-col>
        <v-col v-if="nbPage>0" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-if="nbPage > 1"
              v-model="page"
              :length="nbPage"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>

        </v-col>  
      </v-row>
    </v-container>
    </v-col>
     <Popup :dialog="dialog" :text="messageDelete" :actions="actions" @delete="deleteContact" @nothing="dialog=false" />
    </div>
</template>
<script>
import ResultList from "@/components/shared/ResultList";
import api from "@/api/personne";
import Popup from "@/components/shared/Popup";

export default {
  components: { ResultList, Popup },
  data() {
    return {
      id: this.$route.params.id_entreprise,
      displayItem: process.env.VUE_APP_NB_DISPLAY,
      contacts: [],
      page: 1,
      column: "nom",
      order: "ASC",
      query: "",
      nbPage: 0,
      totalItems: 0,
      dialog: false,
      messageDelete: "Souhaitez vous supprimer ce contact ?",
      usersEntreprise:[],
      canModif:0,
      actions: {
        // route: [
        //   {
        //     btn: "OUI",
        //     routeName: "client",
        //     params: { id_entreprise: this.$route.params.id_entreprise },
        //   },
        //   {
        //     btn: "NON",
        //     routeName: "client",
        //     params: { id_entreprise: this.$route.params.id_entreprise },
        //   },
        // ],
        actions: [
          {
            btn: "OUI",
            event: "delete",
          },
          {
            btn: "NON",
            event: "nothing",
          },
        ],
      },
      idDeleteContact: 0,
      header: [
        {
          text: "CIVILITE",
          align: "center",
          sortable: false,
          value: "civilite",
          name: "civilite",
        },
        {
          text: "NOM",
          align: "center",
          value: "nom",
          name: "nom",
        },
        {
          text: "PR&Eacute;NOM",
          align: "center",
          value: "prenom",
        },
        {
          text: "FONCTION",
          align: "center",
          sortable: false,
          value: "fonction",
          name: "fonction",
        },
        {
          text: "EMAIL",
          align: "center",
          sortable: false,
          value: "email",
        },
        {
          text: "TELEPHONE",
          align: "center",
          sortable: false,
          value: "telephone",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_contact",
        },
      ],
    };
  },
  methods: {
    getContactEntreprise(query, id_client, page, column, order) {
      api
        .getEntrepriseContacts(query, id_client, page, column, order)
        .then((res) => {
          res.data.personne.rows.forEach((personne) => {
            personne["actions_contact"] = [];
            personne["actions_contact"].push({
              type: "update",
              params: {
                name: "update_contact",
                params: {
                  id_contact: personne.id_personne,
                  id_entreprise: this.$route.params.id_entreprise,
                },
              },
            });
            personne["actions_contact"].push({
              type: "delete",
              id_contact: personne.id_personne,
            });
          });
          this.contacts = res.data.personne.rows;
          this.nbPage = res.data.personne.totalPages;
          this.totalItems = res.data.personne.totalItems;
           if(this.usersEntreprise.length == 0  ){
              console.log('aiiiii')
            res.data.entreprise.Users.forEach(user => {
              this.usersEntreprise.push(user.id_utilisateur)
            })
              this.CanModified()
          }
        }).catch((error) => {
        if ( error.response.status == 500||error.response.status == 400 ){
                console.log(error.response.data,'error.response')
                  if(error.response.data.entreprise.Users.length > 0){
                error.response.data.entreprise.Users.forEach(user => {
                  this.usersEntreprise.push(user.id_utilisateur)
                })
            }
            console.log('hhhh')
          this.CanModified()
              }
      });
    },
    sort($event) {
      console.log("custom sort", $event);
      if ($event.column != undefined) {
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getContactEntreprise(
        this.query,
        this.id,
        this.page - 1,
        this.column,
        this.order
      );
    },
    deleteContact() {
      api.archivePersonne(this.idDeleteContact).then((res) => {
        if (res.status == 200) {
          this.dialog = false;
          this.getContactEntreprise(
            this.query,
            this.id,
            this.page - 1,
            this.column,
            this.order
          );
        }
      });
    },
     CanModified(){
      if(
      (this.$store.state.AsUser.id_utilisateur == '' ? this.$store.state.user.id_role : this.$store.state.AsUser.id_role )== 1 || this.usersEntreprise.includes(this.$store.state.AsUser.id_utilisateur == '' ?this.$store.state.user.id_utilisateur : this.$store.state.AsUser.id_utilisateur) )
      {
        this.canModif = 1
      }
      console.log(this.canModif,'can modif');
    },
  },
  created() {
    this.getContactEntreprise(
      this.query,
      this.id,
      this.page - 1,
      this.column,
      this.order
    );
  },
};
</script>
<style lang="scss" scoped>
.btnBold {
  font-weight: bold;
}

.font_color {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}
a#addContact{
  text-decoration: none;
}
</style>