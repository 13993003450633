import axios from 'axios';

const RESSOURCE_NAME = 'articles_retour';

export default {

    getArticlesRetour(nbResult = "", query = "" ) {
        // console.log("ceci est le retour",idEntreprise)
        return axios.post(`${RESSOURCE_NAME}/`, { query: query, limit: nbResult })
    },
}