<template>
     <v-overlay
      id="popupComponent"
      :value="dialog"

     
   >
        <v-card :width="width" :height="height" rounded="xl" color="#fff" light>
           <v-col v-if="showClose == 'true'">
              <img
                id="cross"
                class="pl-2"
                src="@/assets/close.png"
                @click="$emit('emitDialog', false)"
              />
          </v-col>
          <v-row align="center" justify="center" class="fill-height text-center" >
              <v-card-text class="pt-3 mb-5 modal" >
                <h1>{{text}}</h1>
                <slot name="form"></slot>
              </v-card-text>
                <v-card-actions class="text-center">
                   <v-spacer></v-spacer>
                      
                <router-link v-for="action in actions.route" :key="action.btn"  :to="{ name: action.routeName, params: action.params}">
                  <v-btn width="8vw" height="'5vh" class="actions mx-5" color="#0B87BA" dark rounded >
                    {{action.btn}}
                  </v-btn>
                </router-link>

                  
                  <v-btn type="submit" v-for="action in actions.actions" :key="action.btn"  width="8vw" height="5vh" class="actions mx-5" color="#0B87BA" dark rounded @click="$emit(action.event)" >
                    {{action.btn}} 
                  </v-btn>
              
                
                 
                <v-spacer></v-spacer>
              </v-card-actions>
               <slot></slot>
          </v-row>
            </v-card>
            <!-- <template v-slot:test> 
                  <p> dsfdf </p>
              </template> -->
             
     </v-overlay>
</template>
<script>

export default {
    props:["text","dialog","actions", "heightProp", "widthProp", "showClose"],
    data() {
      return {
        height: "",
        width: "",
      }
    },

    mounted() {
      if ( this.heightProp == undefined || this.widthProp == undefined) {
        this.height = "auto"
        this.width = "auto"
      } else {
        this.height = this.heightProp,
        this.width = this.widthProp
      }
    },
}
</script>

<style lang="scss" scoped>
#popupComponent{
    h1{
        color: rgba(0,0,0,.6);
        font-weight: normal;
        line-height: 3rem;
        font-size: 1.5em;
    }
    .actions{
        padding: 2% 5%;
        font-weight: bold;
        font-size: 1.2em;
    }
    #cross {
        position: absolute;
        top: -10px;
        right: -10px;
    }
    @media screen and (max-width:1024px){
   .v-card{
      width: 80vw!important;
    }
    .v-btn{
      width: 15vw!important;
    }
    }
 
   
}

</style>