<template lang="">
  <div>
    <v-row class="mt-0" align="center" align-items="center">
      <v-col class="col-9">
        <h2 class="mt-8 color_title">DEVIS À RELANCER</h2>
      </v-col>
      <v-col>
        <router-link to="devis">
          <div class="color_orange">VOIR PLUS</div>
        </router-link>
      </v-col>
    </v-row>
    <div class="line"></div>
    <resultList
      class="mt-8"
      :header="headerDevis"
      :items="devis"
      :nbResult="10"
      @row="goToPage"
      :type="'devis'"
    /><br />
    <div class="d-flex row justify-space-between mt-4">
      <v-col class="col-9">
        <h2 class="color_title">PROJETS EN COURS</h2>
      </v-col>

      <v-col>
        <router-link to="projet">
          <div class="color_orange voir_plus_padd_top">VOIR PLUS</div>
        </router-link>
      </v-col>
    </div>
    <div class="line"></div>
    <resultList
      class="mt-8"
      :header="headerProjets"
      :items="projets"
      :nbResult="10"
      @row="goToPage"
      :type="'projets'"
    />
  </div>
</template>
<script>
import ResultList from "@/components/shared/ResultList";
import api from "../../api/user";

export default {
  components: {
    ResultList,
  },
  data() {
    return {
      devis: [],
      projets: [],
      headerProjets: [
        {
          text: "Client",
          align: "center",
          sortable: false,
          value: "Entreprise.raison_sociale",
        },
        {
          text: "Projet",
          align: "center",
          sortable: false,
          value: "nom_projet",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "bloc_fore",
        },
        {
          text: "Date",
          align: "center",
          sortable: false,
          value: "date_projet",
        },
      ],
      headerDevis: [
        {
          text: "Client",
          align: "center",
          sortable: false,
          value: "raison_sociale",
        },
        {
          text: "N° de devis",
          align: "center",
          sortable: false,
          value: "numero_devis",
        },
        {
          text: "Montant",
          align: "center",
          sortable: false,
          value: "montant",
        },
        {
          text: "Date",
          align: "center",
          sortable: false,
          value: "date_devis",
        },
                {
          text: "Typologie",
          align: "center",
          sortable: false,
          value: "typologie",
        },
        {
          text: "Statut",
          align: "center",
          sortable: false,
          value: "statut",
        },

      ],
    };
  },
  methods: {
    getDevis(id) {
      api.getDevis(id, process.env.VUE_APP_NB_DISPLAY_HOME).then((res) => {
        this.devis = res.data.devis.rows;
      });
    },

    getProjets(id = null) {
      console.log(id, "id user");
      api.getProjets(id, 10).then((res) => {
        res.data.projets.rows.forEach((projet) => {
          projet["client"] =
            projet.Entreprise.nom_contact +
            " " +
            projet.Entreprise.prenom_contact;
        });
        this.projets = res.data.projets.rows;
        console.log(
          this.projets,
          "projets",
          process.env.VUE_APP_NB_DISPLAY_HOME
        );
      });
    },
    goToPage($event) {
      console.log(
        "quand les pages seront crées j'irai danns la bonne page",
        $event
      );





      if ($event.type == "projets") {
                let commerciaux = [];
 $event.data.Entreprise.Users.forEach(user =>{
        commerciaux.push(user.id_utilisateur);
 })
        if (
          (this.$store.state.AsUser.id_utilisateur == ""
            ? this.$store.state.user.id_role
            : this.$store.state.AsUser.id_role) == 1 ||
         commerciaux.includes(
            this.$store.state.AsUser.id_utilisateur == ""
              ? this.$store.state.user.id_utilisateur
              : this.$store.state.AsUser.id_utilisateur
          )
        ) {
           this.$router.push({
          name: "update_projet",
          params: {
            id_entreprise: $event.data.id_entreprise,
            id_projet: $event.data.id_projet,
          },
        });
        }else{
             this.$router.push({
          name: "view_projet",
          params: {
            id_entreprise: $event.data.id_entreprise,
            id_projet: $event.data.id_projet,
          },
        });
        }

       
      }
    },
  },
  created() {
    console.log("created de devis");
    this.getProjets(
      this.$store.state.AsUser.id_utilisateur
        ? this.$store.state.AsUser.id_utilisateur
        : ""
    );
    this.getDevis(
      this.$store.state.AsUser.id_utilisateur
        ? this.$store.state.AsUser.id_utilisateur
        : ""
    );
  },
};
</script>
<style scoped>
.color_title {
  color: #238ab4;
  text-align: left;
  font-size: 20px;
  margin-top: 0 !important;
}
.color_orange {
  color: #f89903;
  font-weight: bold;
  font-size: 12.5px;
}
.line {
  border-top: 1px solid #707070;
  margin-top: 4%;
}
.v-data-table.elevation-1.mt-8.theme--light {
  margin-top: 0 !important;
}
.shadow_line {
  box-shadow: inset 0px 11px 30px -16px #707070;
}
.voir_plus_padd_top {
  padding-top: 7px;
}
::v-deep tbody > tr:nth-child(even) {
  background: #fff;
}
::v-deep tbody > tr:nth-child(odd) {
  background: #ccc;
}
a {
  text-decoration: none;
}
h2 {
  margin-top: 0;
  font-weight: bold;
}
button {
  width: 100%;
}
/* @media screen and (max-width: 1366px) {
  .color_orange {
    font-size: 11px;
  }
} */
</style>
