<template>
  <div id="litiges">
    <Title :titlePage="titlePage" />
    <v-container fluid>
      <v-row align="center mt-10">
        <v-col cols="12" lg="12" xl="10">
          <resultList
            :header="headerLitiges"
            :items="litiges"
            :nbResult="10"
            :type="'litiges'"
            @sort="sort"
            :totalItems="totalItems"
            :page="page"
          />
        </v-col>
        <v-col v-if="totalPages > 1" cols="12" lg="12" xl="10">
          <div class="text-center">
            <v-pagination
              v-model="page"
              :length="totalPages"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/shared/Title";
import ResultList from "@/components/shared/ResultList";
import api from "@/api/litiges.js";

export default {
  name: "litiges",
  components: { Title, ResultList },
  data() {
    return {
      titlePage: "Litiges de Commandes",
      litiges: [],
      totalItems: 0,
      totalPages: 0,
      page: 1,
      headerLitiges: [
        {
          text: "RAISON SOCIALE",
          align: "center",
          sortable: true,
          value: "raison_sociale",
        },
        {
          text: "NUMERO DE COMMANDE",
          align: "center",
          sortable: true,
          value: "numero_commande",
        },
        {
          text: "EXPEDIÉE",
          align: "center",
          sortable: false,
          value: "entierement_expedie",
          name: "entierement expedie",
        },
        {
          text: "MONTANT",
          align: "center",
          sortable: false,
          value: "montant",
          name: "montant",
        },
        {
          text: "DATE D'ENVOI",
          align: "center",
          sortable: true,
          value: "date_document",
          name: "date comptabilisee",
        },
        {
          text: "DATE LITIGE",
          align: "center",
          sortable: true,
          value: "Litige.createdAt",
          name: "date_creation_litige"
        },
        {
          text: "DESCRIPTION",
          align: "center",
          sortable: false,
          value: "description_litige",
        },
        {
          text: "service",
          align: "center",
          sortable: false,
          value: "Litige.service",
        },
        {
          text: "ACTIONS",
          align: "center",
          sortable: false,
          value: "actions_litige",
        },
      ],
    };
  },
  methods: {
    getLitiges(column, order, page) {
      api.getLitiges(column, order, page).then((res) => {
        this.litiges = res.data.litiges.rows;
        this.litiges.forEach((element) => {
          if ( element.Litige != null) {
            element.Litige.createdAt = this.$moment(element.Litige.createdAt).format("DD/MM/YYYY") 
          }
      })
        this.totalItems = res.data.litiges.totalItems;
        this.totalPages = res.data.litiges.totalPages;
      });
    },
    sort($event) {
      if ($event.column != undefined) {
        console.log($event.column);
        this.column = $event.column;
      }
      if ($event.order != undefined) {
        this.order = $event.order ? "ASC" : "DESC";
      }
      this.page = $event.page;
      this.getLitiges(this.column, this.order, this.page - 1);
      // this.getRetours(this.$store.state.AsUser.id_utilisateur, this.search);
    },
  },
  created() {
    this.getLitiges();
  },
};
</script>
