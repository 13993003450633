<template>
  <l-marker :key="marker.id" :lat-lng="marker.coordinates" @ready="$emit('markerOnLoad')">
    <l-icon ref="icon">
      <v-icon :class="marker.type_client">fa fa-map-marker-alt</v-icon>
    </l-icon>
    <l-popup>
      <h3>{{ marker.name }}</h3>
      <p class="bold details">
        {{ marker.address
        }}<br v-if="marker.mail.length > 0 || marker.tel > 0" />
        <a v-if="marker.mail" class="mt-1" :href="`mailto:${marker.mail?marker.mail:''}`">{{ marker.mail ? marker.mail:'' }}</a
        ><br v-if="marker.tel.length > 0" />
        <a :href="`tel:${marker.tel}`">{{
          marker.tel.length == 10
            ? marker.tel
                .split(
                  /([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})([0-9]{2,2})/
                )
                .join(" ")
            : marker.tel
        }}</a>
      </p>
      <router-link
          class="bold btn"
          :to="{ name: 'client', params: { id_entreprise: marker.id } }"
          >VOIR LA FICHE</router-link
        >
        <div>
          <v-btn @click="$emit('emitStartCoordinates', [ marker.coordinates, marker.id, marker.name ])" color="primary" elevation="2" raised x-small>Point de départ</v-btn>
          <v-btn @click="$emit('emitEndCoordinates', [ marker.coordinates, marker.id, marker.name ])" class="ml-2" color="secondary" elevation="2" raised x-small>Point d'arrivée</v-btn>
        </div>
    </l-popup>
  </l-marker>
</template>

<script>
import { LIcon, LMarker, LPopup } from "vue2-leaflet";
export default {
  components: { LIcon, LMarker, LPopup },
  props: {
    marker: {
      type: Object,
      required: true,
    },
  },
  methods:{
    doSomethingOnReady(){
      console.log('marker chargé')
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.restaurant-icon {
  height: 50px;
  width: auto;
}
.fa-map-marker-alt.client_projet {
  color: chartreuse;
  background-color: transparent;
}
.fa-map-marker-alt.client_no_projet {
  color: blue;
  background-color: transparent;
}
.fa-map-marker-alt.prospect_visite_projet {
  color: orange;
  background-color: transparent;
}
.fa-map-marker-alt.prospect_visite_no_projet {
  color: black;
  background-color: transparent;
}
.fa-map-marker-alt.prospect_no_visite_projet {
  color: rgb(235, 57, 235);
  background-color: transparent;
}
.fa-map-marker-alt.prospect_no_visite_no_projet {
  color: red;
  background-color: transparent;
}
.btnBold {
  font-weight: bold;
}
a {
  color: $font-color-link-search-result;
  text-decoration: none;
  display: inline-block;
}
.bold {
  font-weight: bold;
}
.details {
  font-size: 1em;
  color: $font-color-search-result;
  margin:0.5em 0
}
a.bold.btn {
  color: $background-color-btn;
}
</style>

   

