var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"id":"title","align":"center"}},[_c('v-col',{staticClass:"retour",attrs:{"cols":"1","xl":"1"}},[_c('v-btn',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-caret-left")]),_vm._v("RETOUR ")],1)],1),_c('v-col',{attrs:{"align-self":"center","cols":"5"}},[_c('h1',[_vm._v(_vm._s(_vm.titlePage.toUpperCase()))])]),(false)?_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"5"}},[_c('v-container',{staticClass:"d-flex pt-0 pb-0"},[_c('v-btn',{staticClass:"btnBold transforClient",attrs:{"elevation":0,"color":"#FF9900","dark":"","rounded":""}},[_c('div',{},[_vm._v("TRANSFORMER EN CLIENT")])])],1)],1):_vm._e(),(this.$route.name == 'client')?_c('v-col',{staticClass:"desactiver_client",attrs:{"align-self":"center","cols":"5"}},[_c('v-row',{attrs:{"justify":"end"}},[(
            _vm.currentTab == 0 &&
            (this.$store.state.AsUser.id_utilisateur == ''
              ? this.$store.state.user.id_role
              : this.$store.state.AsUser.id_role == 1 ||
                _vm.users.includes(
                  this.$store.state.AsUser.id_utilisateur == ''
                    ? this.$store.state.user.id_utilisateur
                    : this.$store.state.AsUser.id_utilisateur
                ))
          )?_c('v-btn',{staticClass:"modifier_client",attrs:{"elevation":0},on:{"click":function($event){return _vm.toModifier()}}},[_c('i',{staticClass:"fas fa-user-edit"}),_c('div',{staticClass:"font_btn pl-2"},[_vm._v("Modifier ce client")])]):_vm._e(),(
            _vm.currentTab == 0 &&
            this.id_statut_entreprise == 2 &&
            (this.$store.state.AsUser.id_utilisateur == ''
              ? this.$store.state.user.id_role
              : this.$store.state.AsUser.id_role == 1 ||
                _vm.users.includes(
                  this.$store.state.AsUser.id_utilisateur == ''
                    ? this.$store.state.user.id_utilisateur
                    : this.$store.state.AsUser.id_utilisateur
                ))
          )?_c('v-btn',{staticClass:"transformation_client",attrs:{"elevation":0},on:{"click":function($event){_vm.dialogPopup = true}}},[_c('div',{staticClass:"font_btn pl-2"},[_vm._v("Transformer en client")])]):_vm._e(),(
            _vm.currentTab == 0 &&
            _vm.$store.state.entreprise.actif &&
            (this.$store.state.AsUser.id_utilisateur == ''
              ? this.$store.state.user.id_role
              : this.$store.state.AsUser.id_role == 1 ||
                _vm.users.includes(
                  this.$store.state.AsUser.id_utilisateur == ''
                    ? this.$store.state.user.id_utilisateur
                    : this.$store.state.AsUser.id_utilisateur
                ))
          )?_c('v-btn',{staticClass:"disable_client",attrs:{"elevation":0},on:{"click":function($event){_vm.dialog = true}}},[_c('i',{staticClass:"fas fa-times"}),_c('div',{staticClass:"font_btn"},[_vm._v("Désactiver ce client")])]):_vm._e(),(
            _vm.currentTab == 0 &&
            !_vm.$store.state.entreprise.actif &&
            (this.$store.state.AsUser.id_utilisateur == ''
              ? this.$store.state.user.id_role
              : this.$store.state.AsUser.id_role == 1 ||
                _vm.users.includes(
                  this.$store.state.AsUser.id_utilisateur == ''
                    ? this.$store.state.user.id_utilisateur
                    : this.$store.state.AsUser.id_utilisateur
                ))
          )?_c('v-btn',{staticClass:"active_client",attrs:{"elevation":0},on:{"click":function($event){_vm.dialogActive = true}}},[_c('i',{staticClass:"fas fa-times"}),_c('div',{staticClass:"font_btn"},[_vm._v("Activer ce client")])]):_vm._e()],1),_c('Popup',{staticClass:"popup",attrs:{"dialog":_vm.dialog,"text":_vm.desactiveEntreprise,"actions":_vm.actionsActive},on:{"transform":function($event){return _vm.archiver(_vm.idClientEnCours)},"nothing":function($event){_vm.dialog = false}}}),_c('Popup',{staticClass:"popup",attrs:{"dialog":_vm.dialogPopup,"text":_vm.messageCreation,"actions":_vm.actions},on:{"transform":_vm.transform,"nothing":function($event){_vm.dialogPopup = false}}},[_c('div',{staticClass:"popup-content"},[_c('div',{staticClass:"text-left"},[_vm._v("CODE FTA* :")]),_c('v-text-field',{attrs:{"type":"text","hide-details":""},model:{value:(_vm.code_client_fta),callback:function ($$v) {_vm.code_client_fta=$$v},expression:"code_client_fta"}}),(_vm.$v.code_client_fta.$error)?_c('div',{staticClass:"errorMsg text-left red--text mt-3 mb-3"},[_vm._v(" Renseignez le code client FTA ")]):_vm._e()],1)]),_c('Popup',{staticClass:"popup",attrs:{"dialog":_vm.dialogActive,"text":_vm.ActiveEntreprise,"actions":_vm.actionsActive},on:{"transform":function($event){return _vm.archiver(_vm.idClientEnCours)},"nothing":function($event){_vm.dialogActive = false}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }