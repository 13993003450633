<template>
  <v-row>
    <v-col cols="12">
      <Title :titlePage="titlePage" />
    </v-col>

    <v-container>
      <RetourForm
        :retour="retour"
        :retour_a_articles="retour_a_articles"
        :retour_types_champs="retour_types_champs"
        :documents="documents"
        :erreurFta="erreurFta"
        :demandeDavoir="demandeDavoir"
        :demandeDeGarantie="demandeDeGarantie"
        :demandeDeReparation="demandeDeReparation"
        :mode="mode"
        @emitRetour="createOrUpdateRetour"
      />
      <Popup :dialog="dialog" :text="messageCreation" :actions="actions" />
    </v-container>
  </v-row>
</template>
<script>
import Title from "@/components/shared/Title";
import RetourForm from "@/components/Forms/RetourForm";
import Popup from "@/components/shared/Popup";

import api from "@/api/retours";
import api_doc from "@/api/document";

export default {
  components: {
    Title,
    RetourForm,
    Popup,
  },
  data() {
    return {
      retour: {
        id_retour: null,
        date_retour: "",
        email_client: "",
        id_type_retour: null,
        validation_commerciale: false,
        id_entreprise: this.$route.params.id_entreprise,
      },
      documents: [],
      retour_a_articles: [],
      retour_types_champs: [],

      erreurFta: {
        produit_complet: {
          id_type_retour: 1,
          id_champ_type_retour: 1,
          valeur: null,
        },
        produit_a_demonter: {
          id_type_retour: 1,
          id_champ_type_retour: 2,
          valeur: null,
        },
        produit_remis_en_stock: {
          id_type_retour: 1,
          id_champ_type_retour: 3,
          valeur: null,
        },
        commentaire_erreur_fta: {
          id_type_retour: 1,
          id_champ_type_retour: 20,
          valeur: null,
        },
      },

      demandeDavoir: {
        avoir: {
          id_type_retour: 2,
          id_champ_type_retour: 4,
          valeur: "true",
        },
        decote_a_ajouter: {
          id_type_retour: 2,
          id_champ_type_retour: 5,
          valeur: null,
        },
        produit_neuf_et_complet: {
          id_type_retour: 2,
          id_champ_type_retour: 6,
          valeur: null,
        },
        produit_remis_en_stock: {
          id_type_retour: 2,
          id_champ_type_retour: 7,
          valeur: null,
        },
        produit_usage: {
          id_type_retour: 2,
          id_champ_type_retour: 8,
          valeur: null,
        },
        produit_incomplet: {
          id_type_retour: 2,
          id_champ_type_retour: 9,
          valeur: null,
        },
      },

      demandeDeGarantie: {
        garantie: {
          id_type_retour: 3,
          id_champ_type_retour: 10,
          valeur: "true",
        },
        produit_non_conforme: {
          id_type_retour: 3,
          id_champ_type_retour: 11,
          valeur: null,
        },
        commentaire_garantie_acceptee: {
          id_type_retour: 3,
          id_champ_type_retour: 12,
          valeur: null,
        },
        delai_depasse: {
          id_type_retour: 3,
          id_champ_type_retour: 13,
          valeur: null,
        },
        utilisation_non_conforme: {
          id_type_retour: 3,
          id_champ_type_retour: 14,
          valeur: null,
        },
        date_du_produit: {
          id_type_retour: 3,
          id_champ_type_retour: 15,
          valeur: "",
        },
        commentaire_garantie_refusee: {
          id_type_retour: 3,
          id_champ_type_retour: 18,
          valeur: null,
        },
        articles: [],
      },

      demandeDeReparation: {
        reparation_possible: {
          id_type_retour: 4,
          id_champ_type_retour: 16,
          valeur: null,
        },
        main_doeuvre_estimee: {
          id_type_retour: 4,
          id_champ_type_retour: 17,
          valeur: 1,
        },
        commentaire_reparation: {
          id_type_retour: 4,
          id_champ_type_retour: 12,
          valeur: null,
        },
        articles: [],
      },

      titlePage: "NOUVEAU RETOUR",
      dialog: false,
      messageCreation: "",
      actions: {
        route: [
          {
            btn: "OK",
            routeName: "client",
            params: { id_entreprise: this.$route.params.id_entreprise },
          },
        ],
      },
      mode: "creation",
    };
  },

  methods: {
    async createOrUpdateRetour(retour, retour_types_champs, retour_a_articles) {
      // contact.id_entreprise = this.$route.params.id_entreprise;
      this.retour = retour;
      if (this.mode == "creation") {
        console.log(retour, retour_types_champs, retour_a_articles, "retours");
        await api
          .createRetour(retour, retour_types_champs, retour_a_articles)
          .then((res) => {
            console.log(res);
            var id_retour = res.data.retour.id_retour;

            let data = new FormData();
            data.append("mode", "retours");
            data.append("directory", id_retour);
            data.append("id_element", id_retour);
            for (var j = 0; j < retour.data.length; j++) {
              // if (this.docs[j].id_document === undefined) {
              let file = retour.data[j].files;
              data.append("documents", file);
            }
            
            api_doc
              .uploadDocument(
                this.retour.retour.id_entreprise,
                data,
                retour.config
              )
              .then((res) => {
                console.log("res upload", res);
              });

            this.messageCreation = "Votre retour a bien été enregistré";
            this.dialog = true;
          });
      } else if (this.mode == "modification") {
        console.log(this.retour, this.retour.retour.id_entreprise,'retour modification');
        let retourData = await api
          .updateRetour(
            retour,
            retour_types_champs,
            retour_a_articles,
            retour.print
          );


          console.log(retourData,'retour after await')





         
            var id_retour = retourData.data.id_retour;
            var numero_retour = retourData.data.numero_retour;
            let docToSend = [];
            let data = new FormData();
            data.append("mode", "retours");
            data.append("directory", id_retour);
            data.append("id_element", id_retour);
            for (var j = 0; j < retour.data.length; j++) {
               if (retour.data[j].id_document === undefined) {
              let file = retour.data[j].files;
              data.append("documents", file);
              docToSend.push(file)
               }
            }

            if (docToSend.length > 0) {
              await api_doc.uploadDocument(
                  this.retour.retour.id_entreprise,
                  data,
                  retour.config
                )
                // .then((res) => {
                //   console.log("res upload", res);
                // });
            }

            if (retour.print == "true") {
              await api.downloadRetour(numero_retour).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", numero_retour + ".docx");
                document.body.appendChild(link);
                link.click();
              });
              this.messageCreation = "retour modifié";
            this.dialog = true;
            }else{
              this.messageCreation = "retour modifié";
            this.dialog = true;
            }

            
          // });





      }
    },

    getRetour(id_retour) {
      api.getRetour(id_retour).then((res) => {
        console.log(res);
        this.retour.id_retour = res.data.retour.id_retour;
        this.retour.id_type_retour = res.data.retour.id_type_retour.toString();
        this.retour.date_retour = res.data.retour.date_retour;
        this.retour.date_cloture = res.data.retour.date_cloture;
        this.retour.email_client = res.data.retour.email_client;
        this.retour.validation_commercial = res.data.retour.validation_commercial;
        this.documents = res.data.retour.Documents;
        this.retour_a_articles = res.data.retour.retour_a_articles;
        console.log("retour_a_articles", this.retour_a_articles);

        // Reconstruction du tableau de document
        if (this.documents.length > 0) {
          for (var i = 0; i < this.documents.length; i++) {
            this.documents[i].files = {
              name: this.documents[i].document,
              lastModified: this.documents[i].id_document,
            };
            this.documents[i].chemin =
              process.env.VUE_APP_BASE_URL + this.documents[i].chemin;
          }
        }

        // Get type_retour
        var retour_types_champs = res.data.retour.retour_types_champs;
        // console.log("log commentaire erreur fta : ", retour_types_champs)
        var articles = res.data.retour.articles;
        if (this.retour.id_type_retour == 1) {
          this.getErreurFta(retour_types_champs);
        }

        if (this.retour.id_type_retour == 2) {
          this.getDemandeDavoir(retour_types_champs);
        }

        if (this.retour.id_type_retour == 3) {
          this.getDemandeDeGarantie(retour_types_champs, articles);
        }

        if (this.retour.id_type_retour == 4) {
          this.getDemandeDeReparation(retour_types_champs, articles);
        }
      });
    },
    getErreurFta(retour_types_champs) {
      let produit_complet = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 1
      );
      let produit_a_demonter = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 2
      );
      let produit_remis_en_stock = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 3
      );
      let commentaire_erreur_fta = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 20
      );

      this.erreurFta.produit_complet.valeur =
        produit_complet == undefined ? null : produit_complet.valeur;
      this.erreurFta.produit_a_demonter.valeur =
        produit_a_demonter == undefined ? null : produit_a_demonter.valeur;
      this.erreurFta.produit_remis_en_stock.valeur =
        produit_remis_en_stock == undefined
          ? null
          : produit_remis_en_stock.valeur;
      this.erreurFta.commentaire_erreur_fta.valeur =
        commentaire_erreur_fta == undefined
          ? null
          : commentaire_erreur_fta.valeur;
    },
    getDemandeDeGarantie(retour_types_champs, articles) {
      let garantie = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 10
      );
      this.demandeDeGarantie.garantie.valeur =
        garantie == undefined ? null : garantie.valeur;

      if (this.demandeDeGarantie.garantie.valeur == "true") {
        let produit_non_conforme = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 11
        );
        let commentaire_garantie_acceptee = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 12
        );

        this.demandeDeGarantie.produit_non_conforme.valeur =
          produit_non_conforme == undefined
            ? null
            : produit_non_conforme.valeur;
        this.demandeDeGarantie.commentaire_garantie_acceptee.valeur =
          commentaire_garantie_acceptee == undefined
            ? null
            : commentaire_garantie_acceptee.valeur;
        this.demandeDeGarantie.articles = articles;
      }

      if (this.demandeDeGarantie.garantie.valeur == "false") {
        let delai_depasse = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 13
        );
        let utilisation_non_conforme = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 14
        );
        let date_du_produit = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 15
        );
        let commentaire_garantie_refusee = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 18
        );

        this.demandeDeGarantie.delai_depasse.valeur =
          delai_depasse == undefined ? null : delai_depasse.valeur;
        this.demandeDeGarantie.utilisation_non_conforme.valeur =
          utilisation_non_conforme == undefined
            ? null
            : utilisation_non_conforme.valeur;
        this.demandeDeGarantie.date_du_produit.valeur =
          date_du_produit == undefined ? null : date_du_produit.valeur;
        this.demandeDeGarantie.commentaire_garantie_refusee.valeur =
          commentaire_garantie_refusee == undefined
            ? null
            : commentaire_garantie_refusee.valeur;
      }
    },
    getDemandeDavoir(retour_types_champs) {
      let avoir = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 4
      );
      this.demandeDavoir.avoir.valeur =
        avoir == undefined ? null : avoir.valeur;

      if (this.demandeDavoir.avoir.valeur == "true") {
        let decote_a_ajouter = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 5
        );
        let produit_neuf_et_complet = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 6
        );
        let produit_remis_en_stock = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 7
        );

        this.demandeDavoir.decote_a_ajouter.valeur =
          decote_a_ajouter == undefined ? null : decote_a_ajouter.valeur;
        this.demandeDavoir.produit_neuf_et_complet.valeur =
          produit_neuf_et_complet == undefined
            ? null
            : produit_neuf_et_complet.valeur;
        this.demandeDavoir.produit_remis_en_stock.valeur =
          produit_remis_en_stock == undefined
            ? null
            : produit_remis_en_stock.valeur;
      }

      if (this.demandeDavoir.avoir.valeur == "false") {
        let produit_usage = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 8
        );
        let produit_incomplet = retour_types_champs.find(
          (element) => element.id_champ_type_retour == 9
        );

        this.demandeDavoir.produit_usage.valeur =
          produit_usage == undefined ? null : produit_usage.valeur;
        this.demandeDavoir.produit_incomplet.valeur =
          produit_incomplet == undefined ? null : produit_incomplet.valeur;
      }
    },
    getDemandeDeReparation(retour_types_champs, articles) {
      let reparation_possible = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 16
      );
      let main_doeuvre_estimee = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 17
      );
      let commentaire_reparation = retour_types_champs.find(
        (element) => element.id_champ_type_retour == 12
      );

      this.demandeDeReparation.reparation_possible.valeur =
        reparation_possible == undefined ? null : reparation_possible.valeur;
      this.demandeDeReparation.main_doeuvre_estimee.valeur =
        main_doeuvre_estimee == undefined ? null : main_doeuvre_estimee.valeur;
      this.demandeDeReparation.commentaire_reparation.valeur =
        commentaire_reparation == undefined
          ? null
          : commentaire_reparation.valeur;
      this.demandeDeReparation.articles = articles;
    },
  },
  created() {
    if (this.$route.name == "update_retour") {
      this.titlePage = "MODIFIER UN RETOUR";
      this.mode = "modification";
      this.getRetour(this.$route.params.id_retour);
    }

    if (this.$route.name == "view_retour") {
      this.titlePage = "VOIR UN RETOUR";
      this.mode = "visualisation";
      this.getRetour(this.$route.params.id_retour);
    }
  },
};
</script>
<style>
</style>