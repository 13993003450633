<template>
  <div>
    <iframe
      :src="url"
      frameborder="0"
      id="myiframe"
      width="100%"
      allowtransparency
      v-resize="{ log: true }"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url:
        process.env.VUE_APP_REPORTING_URL +
        (this.$store.state.user.id_role == 1
          ? "public/dashboard/333133a3-3b48-4c36-bd37-5f2f91fd449d"
          : "public/dashboard/03ebbcf0-786b-42b9-8786-6af99b72bc02") +
        (this.$store.state.user.id_role == 2 &&
        this.$store.state.user.type == "itinerant"
          ? "?itinerant="
          : this.$store.state.user.id_role == 2 &&
            this.$store.state.user.type == "sedentaire"
          ? "?sedentaire="
          : "") +
        (this.$store.state.user.id_role == 2
          ? this.$store.state.user.nom
          : "") +
        (this.$store.state.user.id_role == 2
          ? "#hide_parameters=itinerant,sedentaire,fournisseur"
          : ""),
    };
  },
  created() {
        if ( this.$store.state.AsUser.nom != "") {
       this.url = process.env.VUE_APP_REPORTING_URL +
        (this.$store.state.AsUser.id_role == 1
          ? "public/dashboard/333133a3-3b48-4c36-bd37-5f2f91fd449d"
          : "public/dashboard/03ebbcf0-786b-42b9-8786-6af99b72bc02") +
        (this.$store.state.AsUser.id_role == 2 &&
        this.$store.state.AsUser.type == "itinerant"
          ? "?itinerant="
          : this.$store.state.AsUser.id_role == 2 &&
            this.$store.state.AsUser.type == "sedentaire"
          ? "?sedentaire="
          : "") +
        (this.$store.state.AsUser.id_role == 2
          ? this.$store.state.AsUser.nom
          : "") +
        (this.$store.state.AsUser.id_role == 2
          ? "#hide_parameters=itinerant,sedentaire,fournisseur"
          : "")
    }
    console.log(
      this.$store.state.user.id_role,
      this.$store.state.user.type,
      this.$store.state.user.id_role == 2 &&
        this.$store.state.user.type == "itinerant"
        ? "?itinerant="
        : this.$store.state.user.id_role == 2 &&
          this.$store.state.user.type == "sedentaire"
        ? "?sedentaire="
        : "",
      "state user"
    );
  },
};
</script>
